import { FC, useEffect, useState, useContext } from "react";
import { useFormik } from "formik";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import InputTextfield from "../../../../../shared/components/textField";
import { useNavigate } from "react-router-dom";

import { KudumbaUnit, MicroFinanceUnit } from "../../../../../shared/enum";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBoxOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import FamilyMembersList from "./familyMembersList";
import { EntityContext } from "../../../../../contexts/EntityContext";
import AddressHistoryList from "./addressHistoryList ";
import { Address } from "../../../../../shared/interfaces/address";
import { Family } from "../../../../../shared/interfaces/family";
import { Member } from "../../../../../shared/interfaces/member";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { parse, isValid, format, isAfter } from "date-fns";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import { getFamilyEditFields } from "./familyEditFields";
import { dateInputFromatHelper } from "../../../../../shared/utils/date-helper";
import moment from "moment";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { getCurrentAddressFields } from "./currentAddressFields";
import {
  rationCardOptions,
  residenceTypeOptions,
} from "../../../../../shared/constants/selectOptions";

const FamilyView: FC = () => {
  const { id } = useParams();
  const { loading, data, fetchData } = useQuery(`/admin/family/${id}`, null);
  const navigate = useNavigate();
  const { modifyData } = useMutation();

  const [editStatus, setEditStatus] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [selectedMicroFinance, setSelectedMicroFinance] = useState("");
  const [selectedKudumbaUnit, setSelectedKudumbaUnit] = useState("");
  const { setEntityName } = useContext(EntityContext);
  const [editCurrentAddressStatus, setEditCurrentAddressStatus] =
    useState(false);

  useEffect(() => {
    setEntityName({
      entityName: ["Edit"],
      entityTitle: "Edit Family",
    });
  }, [setEntityName]);

  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Family-Update")
    : true;

  const memberCreatePermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Member-Create")
    : true;

  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  const formatDate = (date: any) => {
    return isoRegex.test(date);
  };

  const familyData: Family = (data as any)?.data || {};

  let currentAddress: Address | null =
    familyData?.familyAddresses?.find(
      (address: Address) => address.currentAddress
    ) || null;
  const familyHead: Member | null =
    familyData?.familyMembers?.find((member: Member) => member.isFamilyHead) ||
    null;
  const familyMembers: Member[] | null = familyData?.familyMembers || null;

  const { data: lsgdsData } = useQuery(`/admin/lsgds`);

  const lsgdsList = (lsgdsData as any)?.items.map(
    (lsgds: any, index: number) => ({
      value: lsgds?.id,
      key: index,
      label: `${lsgds?.districtEng}, ${lsgds?.talukEng},  ${lsgds?.panchayathEng},  Ward No - ${lsgds?.wardNo}`,
    })
  );

  const familyForm = useFormik({
    initialValues: {
      houseNo: currentAddress?.houseNo ?? "",
      houseNameEng: currentAddress?.houseNameEng ?? "",
      houseNameMal: currentAddress?.houseNameMal ?? "",
      streetNameEng: currentAddress?.streetNameEng ?? "",
      streetNameMal: currentAddress?.streetNameMal ?? "",
      rationCardNumber: (data as any)?.data?.rationCardNumber ?? "",
      rationCardType: (data as any)?.data?.rationCardType ?? null,
      kudumbaUnitNameEng: (data as any)?.data?.kudumbaUnitNameEng ?? null,
      kudumbaUnitNameMal: (data as any)?.data?.kudumbaUnitNameMal ?? null,
      microFinanceNameEng: (data as any)?.data?.microFinanceNameEng ?? "",
      microFinanceNameMal: (data as any)?.data?.microFinanceNameMal ?? null,
      residenceType: currentAddress?.residenceType ?? null,
      currentAddress: currentAddress?.currentAddress ?? true,
      dateOfResidence: formatDate(currentAddress?.dateOfResidence)
        ? moment(currentAddress?.dateOfResidence).format("DD/MM/YYYY")
        : moment(currentAddress?.dateOfResidence, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ) ?? null,
      joinedDate: formatDate((data as any)?.data?.joinedDate)
        ? moment((data as any)?.data?.joinedDate).format("DD/MM/YYYY")
        : moment((data as any)?.data?.joinedDate, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ) ?? null,
      annualIncome: (data as any)?.data?.annualIncome ?? "",
      addressID: (data as any)?.data?.addressID ?? null,
      memberID: (data as any)?.data?.memberID ?? null,
      wardNo: currentAddress?.lsgdID ?? null,
      ownLand: (data as any)?.data?.ownLand ?? false,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(
      editStatus
        ? [
            "joinedDate",
            "rationCardNumber",
            "kudumbaUnitNameEng",
            "annualIncome",
          ]
        : [
            "houseNo",
            "houseNameEng",
            "streetNameEng",
            "wardNo",
            "dateOfResidence",
            "residenceType",
            "houseNameMal",
            "streetNameMal",
          ]
    ),
    validate: (values: any) => {
      const errors: any = {};
      if (editStatus) {
        if (values?.joinedDate) {
          const isValidDate = validateDateFormat(
            values?.joinedDate,
            "dd/MM/yyyy"
          );
          if (!isValidDate) {
            errors.joinedDate = "Invalid Date format ( DD/MM/YYYY )";
          } else {
            const isValidAndNotFuture = validateNotFutureDate(
              values?.joinedDate,
              "dd/MM/yyyy"
            );
            if (!isValidAndNotFuture) {
              errors.joinedDate = "Joined Date cannot be a future date";
            }
          }
        }
      } else {
        if (values?.dateOfResidence) {
          const isValidDate = validateDateFormat(
            values?.dateOfResidence,
            "dd/MM/yyyy"
          );
          if (!isValidDate) {
            errors.dateOfResidence = "Invalid Date format ( DD/MM/YYYY )";
          } else {
            const isValidAndNotFuture = validateNotFutureDate(
              values?.dateOfResidence,
              "dd/MM/yyyy"
            );
            if (!isValidAndNotFuture) {
              errors.dateOfResidence =
                "Date of residance cannot be a future date";
            }
          }
        }
      }

      return errors;
    },
    onSubmit: async (formData: any, { resetForm }) => {
      if (!formData.dateOfResidence) {
        formData.dateOfResidence = null;
      }
      if (formData.ownLand) {
        formData.ownLand = formData.ownLand.toString();
      }
      let response = null;
      if (newAddress) {
        if (id) {
          response = await modifyData(
            `admin/family/add-aadress/${id}`,
            "post",
            {
              ...formData,
            },
            familyForm
          );
        }
      } else {
        if (id) {
          response = await modifyData(
            `admin/family/${id}`,
            "patch",
            {
              ...formData,
            },
            familyForm
          );
        }
      }
      if (response) {
        fetchData();
        setEditCurrentAddressStatus(false);
        setNewAddress(false);
        setEditStatus(false);
      }
    },
  });

  const onCheckChange = () => {
    familyForm.setFieldValue("ownLand", !familyForm.values.ownLand);
  };

  const showFamilyEdit = () => {
    setSelectedKudumbaUnit(
      `${(data as any)?.data?.kudumbaUnitNameEng} - ${
        (data as any)?.data?.kudumbaUnitNameMal
      }`
    );
    setSelectedMicroFinance(
      `${(data as any)?.data?.microFinanceNameEng} - ${
        (data as any)?.data?.microFinanceNameMal
      }`
    );
    setEditStatus(true);
  };

  const closeFamilyEdit = () => {
    fetchData();
    familyForm.resetForm();
    setEditCurrentAddressStatus(false);
    setNewAddress(false);
    setEditStatus(false);
  };

  const closeAddressForm = () => {
    familyForm.resetForm();
    setEditCurrentAddressStatus(false);
    setNewAddress(false);
    setEditStatus(false);
  };

  const openNewAddress = () => {
    const newValues = {
      houseNo: "",
      houseNameEng: "",
      houseNameMal: "",
      streetNameEng: "",
      streetNameMal: "",
      residenceType: null,
      currentAddress: true,
      dateOfResidence: null,
      lsgdID: null,
      wardNo: null,
    };
    familyForm.setValues(newValues, true);
    familyForm.setTouched({}, false);
    familyForm.setErrors({});
    setNewAddress(true);
  };

  const addNewMember = () => {
    navigate(`/branchMembership/members/create`, { state: { familyID: id } });
  };

  const onSelectKudumbaUnit = (value: any) => {
    setSelectedKudumbaUnit(value);
    let parts = value.split(" - ");
    let eng = parts[0];
    let mal = parts[1];
    familyForm.setFieldValue("kudumbaUnitNameEng", eng);
    familyForm.setFieldValue("kudumbaUnitNameMal", mal);
  };

  function validateDateFormat(dateString: string, formatString: string) {
    const parsedDate = parse(dateString, formatString, new Date());
    return (
      isValid(parsedDate) && dateString === format(parsedDate, formatString)
    );
  }
  function validateNotFutureDate(dateString: string, formatString: string) {
    const parsedDate = parse(dateString, formatString, new Date());
    const today = new Date();
    return !isAfter(parsedDate, today);
  }

  const onSelectMicrpFinance = (value: any) => {
    setSelectedMicroFinance(value);
    let parts = value.split(" - ");
    let eng = parts[0];
    let mal = parts[1];
    familyForm.setFieldValue("microFinanceNameEng", eng);
    familyForm.setFieldValue("microFinanceNameMal", mal);
  };

  let microFinanceList = Array.from(
    Object.entries(MicroFinanceUnit),
    ([key, value]) => ({
      label: value,
      value: value,
    })
  );

  let KudumbaUnitList = Array.from(
    Object.entries(KudumbaUnit),
    ([key, value]) => ({
      label: value,
      value: value,
    })
  );

  const viewTitleBar: TitleBarProps = {
    title: "Family Edit",
    headerTitle: "Edit",
  };

  const editTitlebar: TitleBarProps = {
    title: "Family Edit",
    headerTitle: "Family Edit",
    buttonTitle: "Save",
    onClick: () => familyForm.handleSubmit(),
    dirty: familyForm.dirty,
    buttonLoading: loading,
  };

  const editAddressTitlebar: TitleBarProps = {
    title: "New Address",
    headerTitle: "Family Edit",
    buttonTitle: "Save",
    onClick: () => familyForm.handleSubmit(),
    dirty: familyForm.dirty,
    buttonLoading: loading,
  };

  const titleAddressBarNode = (
    <>
      <IconButton
        className="TitleBarIcon"
        size="medium"
        id={`Family-edit`}
        onClick={closeAddressForm}
        aria-label={`Family-edit`}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  const requestTooltipNode = editPermission && (
    <IconButton
      className="TitleBarIcon"
      size="medium"
      id={`Family-edit`}
      onClick={() => showFamilyEdit()}
      aria-label={`Family-edit`}
    >
      <EditIcon />
    </IconButton>
  );
  const addMemberButton = memberCreatePermission && (
    <>
      <Tooltip arrow title="Add New Member">
        <IconButton
          className="TitleBarIcon"
          size="medium"
          id={`currentAddress-edit`}
          onClick={addNewMember}
          aria-label={`currentAddress-edit`}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const currentAddressTooltipNode = editPermission && (
    <>
      <Tooltip arrow title="Add New Address">
        <IconButton
          className="TitleBarIcon"
          size="medium"
          id={`currentAddress-edit`}
          onClick={openNewAddress}
          aria-label={`currentAddress-edit`}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Edit">
        <IconButton
          className="TitleBarIcon"
          size="medium"
          id={`currentAddress-edit`}
          onClick={() =>
            setEditCurrentAddressStatus(
              !editCurrentAddressStatus && !newAddress
            )
          }
          aria-label={`currentAddress-edit`}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const titleBarNode = (
    <IconButton
      className="TitleBarIcon"
      size="medium"
      id={`request-edit`}
      onClick={closeFamilyEdit}
      aria-label={`request-edit`}
    >
      <CloseIcon />
    </IconButton>
  );

  const addressHistory = (
    <>
      <Grid container sx={{ mb: 1, mt: 2 }} spacing={2}>
        <Grid item md={11} sx={{ ml: 2 }} xs={8}>
          Address History
        </Grid>
        <AddressHistoryList
          addressHistory={familyData?.familyAddresses}
          addressId={currentAddress?.id}
        />
      </Grid>
    </>
  );

  const familyEditFields = getFamilyEditFields(familyData, familyHead);
  const currentAddressFields = getCurrentAddressFields(currentAddress);

  const handleJoinedDateChange = (newValue: string) => {
    const formatedDate = dateInputFromatHelper(newValue, familyForm);
    familyForm.setFieldValue("joinedDate", formatedDate);
  };
  const handleResidencedDateChange = (newValue: string) => {
    const formatedDate = dateInputFromatHelper(newValue, familyForm);
    familyForm.setFieldValue("dateOfResidence", formatedDate);
  };

  const familyMemberList = (
    <>
      <FamilyMembersList
        family={currentAddress}
        familyMembers={familyMembers}
      ></FamilyMembersList>
    </>
  );

  return (
    <>
      <>
        {editStatus ? (
          <>
            <FormContainer titleBar={editTitlebar} titleBarNode={titleBarNode}>
              <Box className="border-custom">
                <Grid
                  container
                  alignItems="stretch"
                  columnSpacing={4}
                  rowSpacing={1}
                >
                  {familyEditFields.map(
                    (field: any, index: number) =>
                      (field?.formField === undefined ||
                        field.formField === true) && (
                        <Grid item md={6} sm={6} xs={12} key={field.id}>
                          <InputTextfield
                            form={familyForm}
                            fieldProps={field}
                            key={index}
                          />
                        </Grid>
                      )
                  )}
                  <Grid item md={6} sm={6} xs={12} key="rationCardType">
                    <ZSSelectBox
                      fieldProps={{
                        id: "family-rationCardType",
                        label: "Ration Card Type",
                        name: "rationCardType",
                        placeholder: "Select an option",
                      }}
                      options={rationCardOptions}
                      form={familyForm}
                      value={familyForm.values?.rationCardType}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} key="joinedDate">
                    <InputTextfield
                      form={familyForm}
                      fieldProps={{
                        label: "Date of Joining",
                        id: "family-joinedDate",
                        name: "joinedDate",
                        type: "text",
                        required: true,
                        placeholder: "DD/MM/YYYY",
                      }}
                      onChange={handleJoinedDateChange}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} key="kudumbaUnitNameMal">
                    <ZSSelectBox
                      fieldProps={{
                        id: "family-kudumbaUnitNameMal",
                        name: "kudumbaUnitNameMal",
                        placeholder: "Select an option",
                        required: true,
                        label: "Kudumba Unit",
                      }}
                      options={KudumbaUnitList}
                      value={selectedKudumbaUnit}
                      onChange={onSelectKudumbaUnit}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} key="microFinanceNameMal">
                    <ZSSelectBox
                      fieldProps={{
                        id: "family-microFinanceNameMal",
                        name: "microFinanceNameMal",
                        placeholder: "Select an option",
                        required: false,
                        label: "Micro Finance",
                      }}
                      options={microFinanceList}
                      onChange={onSelectMicrpFinance}
                      value={selectedMicroFinance}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={6}
                    xs={12}
                    key="ownLand"
                    sx={{
                      mt: 2,
                      ml: 0.2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InputLabel sx={{ mr: 1 }}>Own Land</InputLabel>
                    <Checkbox
                      className="selectAll"
                      checkedIcon={<CheckBoxOutlined />}
                      disableRipple
                      checked={familyForm.values?.ownLand}
                      onChange={onCheckChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </FormContainer>
          </>
        ) : editCurrentAddressStatus || newAddress ? (
          <>
            <FormContainer
              titleBar={editAddressTitlebar}
              titleBarNode={titleAddressBarNode}
            >
              <Box className="border-custom">
                <Grid
                  container
                  alignItems="stretch"
                  columnSpacing={4}
                  rowSpacing={2}
                >
                  {currentAddressFields.map(
                    (field: any, index: number) =>
                      (field?.formField === undefined ||
                        field.formField === true) && (
                        <Grid item md={6} sm={6} xs={12} key={field.id}>
                          <InputTextfield
                            form={familyForm}
                            fieldProps={field}
                            key={index}
                          />
                        </Grid>
                      )
                  )}
                  <Grid item md={6} sm={6} xs={12} key="dateOfResidence">
                    <InputTextfield
                      form={familyForm}
                      fieldProps={{
                        label: "Date of Residence",
                        id: "family-dateOfResidence",
                        name: "dateOfResidence",
                        type: "text",
                        required: true,
                        placeholder: "DD/MM/YYYY",
                      }}
                      onChange={handleResidencedDateChange}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} key="residenceType">
                    <ZSSelectBox
                      fieldProps={{
                        id: "family-residenceType",
                        name: "residenceType",
                        placeholder: "Select an option",
                        label: "Residence Type",
                        required: true,
                      }}
                      options={residenceTypeOptions}
                      form={familyForm}
                      value={familyForm.values?.residenceType}
                      errorMessage={
                        ((familyForm.touched as any)?.residenceType &&
                          (familyForm.errors as any)?.residenceType) ??
                        null
                      }
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} key="wardNo">
                    <ZSSelectBox
                      fieldProps={{
                        id: "family-wardNo",
                        name: "wardNo",
                        placeholder: "Select an option",
                        label: "Ward No",
                        required: true,
                      }}
                      options={lsgdsList}
                      form={familyForm}
                      value={familyForm.values?.wardNo}
                      errorMessage={
                        ((familyForm.touched as any)?.wardNo &&
                          (familyForm.errors as any)?.wardNo) ??
                        null
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </FormContainer>
          </>
        ) : (
          <ViewContainer loading={loading} titleBar={viewTitleBar}>
            <SingleEntityContainer
              entityFields={familyEditFields}
              entityTitle="Family Details"
              tooltips={requestTooltipNode}
            ></SingleEntityContainer>

            <SingleEntityContainer
              entityFields={currentAddressFields}
              entityTitle="Current Address Details"
              tooltips={currentAddressTooltipNode}
              children={
                familyData?.familyAddresses?.length > 1 ? addressHistory : <></>
              }
            ></SingleEntityContainer>
            <SingleEntityContainer
              entityFields={[]}
              entityTitle="Family Members"
              tooltips={addMemberButton}
              children={familyMemberList}
            ></SingleEntityContainer>
          </ViewContainer>
        )}
      </>
    </>
  );
};

export default FamilyView;
