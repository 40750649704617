import * as yup from "yup";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { validateImageTypes } from "../../../../../shared/utils/image-helper";
export const customizeValidationSchema = () => {
  let defaultSchema: any = getValidationSchema([
    "name",
    "dailyRate",
    "description",
    "reducedRateFromSecondDay",
    "availabilityStatus",
    "typeOfFacility",
  ]);
  let customSchema = yup.object().shape({
    formImage: yup
      .mixed()
      .nullable()
      .test(
        "FILE_SIZE",
        "Uploaded image is too big. Max size is 2MB",
        (value) => {
          if (value && typeof value !== "string") {
            const file = value as File;
            return file.size <= 2000000;
          }
          return true;
        }
      )
      .test(
        "file-type",
        "Invalid file type. Please select a valid image.",
        (value) => {
          if (value && typeof value !== "string") {
            return validateImageTypes(value as File);
          }
          return true;
        }
      ),
  });

  return defaultSchema.concat(customSchema);
};
