import WorkIcon from "@mui/icons-material/Work";
import EngineeringIcon from "@mui/icons-material/Engineering";
import JobRequestList from "../components/jobRequestList";
import JobRequestEditView from "../components/jobRequestEditView";
const jobRequestRoute = {
  icon: WorkIcon,
  name: "Job Management",
  link: "/job-management",
  path: "job-management",
  element: "",
  index: 80,
  children: [
    {
      path: "job-requests",
      element: <JobRequestList />,
      name: "Job Requests",
      index: 1,
      permission: "Job Request-Read", // Permission name
      link: "/job-management/job-requests",
      icon: EngineeringIcon,
    },
    {
      path: "job-requests/:id",
      element: <JobRequestEditView />,
    },
  ],
};
export default jobRequestRoute;
