import { Navigate } from "react-router-dom";
import EventList from "../components/eventList";
import CreateEvent from "../components/eventCreate";
import EventIcon from "@mui/icons-material/Event";
import EventEditView from "../components/eventEditView";

const eventRoute = {
  icon: EventIcon,
  name: "Event Management",
  link: "/event-management",
  path: "event-management",
  element: "",
  index: 30,
  children: [
    {
      path: "events",
      element: <EventList />,
      name: "Events",
      permission: "Event-Read", // Permission name
      index: 1,
      link: "/event-management/events",
      icon: EventIcon,
    },
    {
      path: "events/create",
      element: <CreateEvent />,
    },
    {
      path: "events/:id",
      element: <EventEditView />,
    },

    {
      path: "",
      element: <Navigate to="events" replace />,
    },
  ],
};
export default eventRoute;
