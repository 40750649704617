import { Container } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { certificateRequest } from "../../../../../shared/interfaces/certificate-request";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const CertificateRequestList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [requestStatus, setRequestStatusFilter] = useState("All");
  const [certificateType, setCertificateType] = useState("All");
  const { loading, data, fetchData, error } = useQuery(
    "/admin/certificate-request",
    {
      params: {
        ...payload,
        requestStatus,
        certificateType,
      },
    }
  );
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus, certificateType]);

  const displayColumns = [
    "memberName",
    "applicantName",
    "certificateType",
    "familyHead",
    "houseName",
    "status",
  ];

  const sortFields = {
    houseName: `"member"."addressDetails"#>>'{houseNameEng}'`,
    familyHead: `"member"."familyHeadDetails"#>>'{fullNameEng}'`,
    memberName: `"member"."fullNameEng"`,
    applicantName: `"applicant"."fullNameEng"`,
    certificateType: '"certificateType"."certificateTypeName"',
    status: "requestStatus",
  };

  // const sortableColumns = ["fullNameEng"];
  let certificateList: certificateRequest[] = [];
  if (data && (data as any)?.items) {
    certificateList = (data as any)?.items.map((certificate: any) => {
      return {
        id: certificate.id,
        memberName: certificate?.member?.fullNameEng,
        applicantName: certificate?.applicant?.fullNameEng ?? "N/A",
        certificateType: certificate?.certificateType?.certificateTypeName,
        familyHead: certificate?.member?.familyHeadDetails?.fullNameEng,
        houseName: certificate?.member?.addressDetails?.houseNameEng,
        status: certificate?.requestStatus,
      };
    });
  }

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Certificate Requests"
        headerTitle="Certificate Requests"
        children={
          <>
            {/* <Typography variant="body1" color="textSecondary" pt={1}>
              Request Status:
            </Typography> */}
            <TitleBarSelectBox
              fieldProps={{
                id: "email-log-filter",
                name: "email-log-filter",
              }}
              value={requestStatus ?? "All"}
              onChange={(value: string) => setRequestStatusFilter(value)}
              options={[
                { value: "All", label: "All" },
                { value: "Applied", label: "Applied" },
                { value: "Approved", label: "Approved" },
                { value: "Rejected", label: "Rejected" },
              ]}
            />
            {/* <Typography variant="body1" color="textSecondary" pt={1}>
              Certificate Type:
            </Typography> */}

            <TitleBarSelectBox
              fieldProps={{
                id: "email-log-filter",
                name: "email-log-filter",
              }}
              value={certificateType ?? "All"}
              onChange={(value: string) => setCertificateType(value)}
              options={[
                { value: "All", label: "All" },
                { value: "1", label: "Caste Certificate" },
                { value: "2", label: "Marriage Certificate" },
                { value: "3", label: "Transfer Certificate" },
                { value: "4", label: "Certificate Of Testimony" },
                { value: "5", label: "Death Certificate" },
                { value: "6", label: "Widow Certificate" },
              ]}
            />
            <SearchBox />
          </>
        }
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes(
                "Certificate Request-Create"
              )
            : false
        }
      ></TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          data={certificateList}
          displayColumns={displayColumns}
          sortableFields={sortFields}
          linkColumn="fullNameEng"
          linkUrl="/certificate-management/certificate-requests"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default CertificateRequestList;
