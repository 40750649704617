export const getBannerEditFields = (bannerData: any) => [
  {
    id: "name",
    name: "name",
    type: "text",
    label: "Name :",
    required: true,
    placeholder: " Enter Name",
    value: bannerData?.name,
  },
  {
    label: "Status :",
    value: bannerData?.status ? "Enabled" : "Disabled",
    formField: false,
  },
];
