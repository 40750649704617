import React, { FC } from "react";
import { Autocomplete, Chip, InputLabel, TextField } from "@mui/material";
import "./styles.css";
import { ErrorMessage } from "../errorMessage";
import { KeyboardArrowDown } from "@mui/icons-material";

export type listOptionContent = {
  value: string | number;
  label: string;
  id?: string;
};

export type ZSlistOptionContent = {
  value: string | number;
  label: string;
};

type ZSAutocompleteProps =
  | {
      id?: string;

      label?: string;
      placeholder?: string;
      multiSelect?: true;
      options: ZSlistOptionContent[];
      defaultValue?: any;
      value?: any;
      setValue: Function;
      errorMessage?: string;
      refetchFunction?: Function;
      setBlur?: Function;
      required?: boolean;
      prefix?: any;
      disableClearable?: boolean;
      nonRenderTags?: string[];
      direction?: "ltr" | "rtl";
      disabled?: boolean;
    }
  | {
      id?: string;
      label?: string;
      placeholder?: string;
      multiSelect?: false;
      options: ZSlistOptionContent[];
      defaultValue?: any;
      value?: any;
      setValue: Function;
      errorMessage?: string;
      refetchFunction?: Function;
      setBlur?: Function;
      required?: boolean;
      prefix?: any;
      disableClearable?: boolean;
      nonRenderTags?: string[];
      direction?: "ltr" | "rtl";
      disabled?: boolean;
    };

export const ZSAutocomplete: FC<ZSAutocompleteProps> = ({
  id,
  label,
  placeholder,
  disableClearable = false,
  multiSelect = false,
  options,
  setValue,
  value,
  errorMessage,
  defaultValue,
  refetchFunction = () => {},
  setBlur = () => {},
  required,
  prefix,
  nonRenderTags = [],
  direction = "ltr",
  disabled = false,
}) => {
  const SearchChangeFunction = debounce((searchValue: string) => {
    refetchFunction(searchValue.toLowerCase());
  }, 1000);

  function debounce(callback: any, delay = 1000) {
    let time: any;
    return (...args: any) => {
      clearTimeout(time);
      time = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }

  const isRender = (value: string) => {
    return !nonRenderTags.includes(value);
  };
  return (
    <div className="autoCompleteBoxContainer" style={{ width: "" }}>
      {label && (
        <InputLabel>
          {label}
          {required ? <span style={{ color: "red" }}> * </span> : ""}
        </InputLabel>
      )}
      <Autocomplete
        disableClearable={disableClearable}
        multiple={multiSelect}
        disableCloseOnSelect={multiSelect}
        onChange={(event: React.SyntheticEvent, value: any) => {
          setBlur();
          setValue(value);
        }}
        disabled={disabled}
        onInputChange={(
          event: React.SyntheticEvent,
          value: string,
          reason: string
        ) => {
          if (reason !== "reset") SearchChangeFunction(value);
        }}
        defaultValue={defaultValue}
        value={value}
        // onBlur={() => {
        //   setBlur();
        // }}
        options={options ?? []}
        getOptionLabel={(option) => `${option?.label}`}
        isOptionEqualToValue={(option, val) => option?.value === val?.value}
        renderOption={(props, option) => (
          <li style={{ direction }} {...props}>
            {option?.label}
          </li>
        )}
        popupIcon={<KeyboardArrowDown />}
        renderTags={(value, getTagProps) =>
          value.map(
            (option: any, index: number) =>
              isRender(option?.value) && (
                <Chip
                  label={option?.label}
                  {...getTagProps({ index })}
                  className="chipAutocomplete"
                  size="small"
                />
              )
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            error={errorMessage ? true : false}
            placeholder={placeholder}
            InputProps={
              multiSelect
                ? { ...params.InputProps }
                : {
                    ...params.InputProps,
                    startAdornment: prefix && (
                      <Chip
                        tabIndex={-1}
                        label={prefix}
                        className="adornment-chip"
                      />
                    ),
                  }
            }
          />
        )}
      />
      {errorMessage !== undefined && (
        <ErrorMessage message={errorMessage ?? null} />
      )}
    </div>
  );
};
