import UnionApprovalList from "../components/unionApprovalList";
import GroupsIcon from "@mui/icons-material/Groups";
import ListIcon from "@mui/icons-material/List";
const memberRequestRoute = {
  icon: GroupsIcon,
  name: "Yogam Membership",
  link: "/yogam-membership",
  path: "yogam-membership",
  element: "",
  index: 110,
  children: [
    {
      path: "union-approval",
      element: <UnionApprovalList />,
      name: "Union Approval",
      index: 3,
      permission: "Union Approval-Read",
      link: "/yogam-membership/union-approval",
      icon: ListIcon,
    },
  ],
};
export default memberRequestRoute;
