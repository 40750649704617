import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { WelfareSchemes } from "../../../../../shared/interfaces/welfare-schemes";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";

const WelfareList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const { loading, data, fetchData, error } = useQuery(
    "/admin/welfare-schemes",
    {
      params: { ...payload },
    }
  );

  const onButtonClick = () => {
    navigate("/welfare-schemes/welfare/create");
  };

  const welfareDisplayColumns = [
    "category",
    "schemeName",
    "welfareType",
    "status",
  ];

  const sortFields = {
    category: `welfareCategory`,
    schemeName: `schemeName`,
    welfareType: "type",
    status: "status",
  };

  let welfareList: WelfareSchemes[] = [];
  if (data && (data as any)?.items) {
    welfareList = (data as any)?.items.map((item: WelfareSchemes) => {
      return {
        id: item.id,
        category: item?.welfareCategory,
        schemeName: item?.schemeName,
        welfareType: item?.type,
        status: item?.status,
      };
    });
  }
  const sortableColumnsMember = [
    // "code", "houseNameEng",
    "schemeName",
  ];

  const titleBarNode = (
    <>
      <SearchBox />
    </>
  );

  const titleBar = {
    title: "Welfare Schemes",
    buttonTitle: "Create",
    onClick: onButtonClick,
    headerTitle: "Welfare Scheme",
    titleBarNode: titleBarNode,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Welfare scheme-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Welfare Scheme"
          data={welfareList}
          displayColumns={welfareDisplayColumns}
          sortableColumns={sortableColumnsMember}
          sortableFields={sortFields}
          linkColumn="schemeName"
          linkUrl="/welfare-schemes/welfare"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/welfare-schemes"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes(
                  "Welfare scheme-Delete"
                )
              : false
          }
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default WelfareList;
