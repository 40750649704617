import "./style.css";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
} from "@mui/material";
import { FC, Fragment } from "react";

interface ComponentProps {
  entityTitle?: string;
  entityFields: any[];
  tooltips?: React.ReactNode;
  children?: React.ReactNode;
}

const SingleEntityContainer: FC<ComponentProps> = ({
  entityTitle,
  entityFields,
  tooltips,
  children,
}) => {
  // Separate fields with and without columnPreference
  const fieldsWithPreference = entityFields.filter(
    (field) => field.columnPreference
  );
  const fieldsWithoutPreference = entityFields.filter(
    (field) => !field.columnPreference
  );

  // Sort fields with columnPreference by their preference
  const sortedFieldsWithPreference = fieldsWithPreference.sort(
    (a, b) => a.columnPreference - b.columnPreference
  );

  // Distribute fields without columnPreference evenly between the two columns
  const firstColumnData = [
    ...sortedFieldsWithPreference.filter(
      (field) => field.columnPreference === 1
    ),
  ];
  const secondColumnData = [
    ...sortedFieldsWithPreference.filter(
      (field) => field.columnPreference === 2
    ),
  ];

  const multilineData: any = [];
  fieldsWithoutPreference.forEach((field, index) => {
    if (field.multiline) {
      multilineData.push(field);
    } else {
      if (index % 2 === 0) {
        firstColumnData.push(field); // Add to first column
      } else {
        secondColumnData.push(field); // Add to second column
      }
    }
  });

  return (
    <Box className="custom-table-border">
      <TableContainer>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <span>{entityTitle}</span>
          <div style={{ marginLeft: "auto" }}>{tooltips}</div>
        </Toolbar>
        {entityFields.length !== 0 && (
          <Table
            size="small"
            sx={{ "& td": { border: 0, paddingLeft: "30px" }, mb: 2 }}
          >
            <TableBody>
              {firstColumnData.map((dataItem: any, dataIndex: number) => (
                <TableRow key={`first-${dataIndex}`}>
                  <TableCell width="20%">{dataItem?.label}</TableCell>
                  <TableCell width="25%">{dataItem?.value}</TableCell>

                  {/* Check if there's a corresponding item in the second column */}
                  {secondColumnData[dataIndex] ? (
                    <>
                      <TableCell width="20%">
                        {secondColumnData[dataIndex]?.label}
                      </TableCell>
                      <TableCell width="35%">
                        {secondColumnData[dataIndex]?.value}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                </TableRow>
              ))}
              {multilineData?.map((dataItem: any, dataIndex: number) => (
                <Fragment key={`multiline-${dataIndex}`}>
                  <TableRow key={`multiline-label-${dataIndex}`}>
                    <TableCell width="100%" colSpan={4}>
                      {dataItem?.label}
                    </TableCell>
                  </TableRow>
                  <TableRow key={dataIndex}>
                    <TableCell width="100%" colSpan={4}>
                      {dataItem?.value}
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Box sx={{ p: 2.5 }}>{children}</Box>
    </Box>
  );
};

export default SingleEntityContainer;
