import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import { FC, useContext, useState, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { membershipRequest } from "../../../../../shared/interfaces/membership-request";
import moment from "moment";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const MembershipRequestList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [requestStatus, setStatusFilter] = useState("All");
  const { loading, data, fetchData, error } = useQuery(
    "/admin/membership-request",
    {
      params: {
        ...payload,
        // sortBy: "createdAt",
        requestStatus: requestStatus,
      },
    }
  );

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestStatus,
    // payload.skip,
    // payload.take,
    // payload.sortBy,
    // payload.sortOrder,
    // payload.search,
    // payload,
  ]);

  const membershipData = data as any;
  const membershipList: membershipRequest[] = membershipData?.items.map(
    (item: any) => {
      return {
        id: item.id,
        fullNameEng: item?.fullNameEng,
        houseNameEng: item?.createdByUser?.member?.addressDetails?.houseNameEng,
        familyHead: item?.createdByUser?.member?.familyHeadDetails.fullNameEng,
        gender: item?.gender,
        createdBy: item?.createdByUser?.member?.fullNameEng,
        status: item?.requestStatus,
        createdOn: moment(item?.createdAt).format("DD/MM/YYYY"),
        deleteButtonStatus: item?.requestStatus === "Approved" ? true : false,
      };
    }
  );
  const displayColumns = [
    "fullNameEng",
    "gender",
    "houseNameEng",
    "familyHead",
    "createdBy",
    "createdOn",
    "status",
  ];

  const sortFields = {
    houseNameEng: `"createdByUser->member"."addressDetails"#>>'{houseNameEng}'`,
    // familyHead: "familyHeadDetails.fullNameEng",
    familyHead: `"createdByUser->member"."familyHeadDetails"#>>'{fullNameEng}'`,
    fullNameEng: "fullNameEng",
    createdBy: `"createdByUser->member"."fullNameEng"`,
    createdOn: "createdAt",
    gender: "gender",
  };

  const sortableColumns = ["fullNameEng"];

  const titleBarNode = (
    <>
      <TitleBarSelectBox
        fieldProps={{
          id: "membership-request-filter",
          name: "membership-request-filter",
        }}
        value={requestStatus ?? "All"}
        onChange={(value: string) => setStatusFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: "Pending", label: "Pending" },
          { value: "Approved", label: "Approved" },
          { value: "Rejected", label: "Rejected" },
        ]}
      />
      <SearchBox />
    </>
  );

  const titleBar = {
    title: "Membership Requests",
    headerTitle: "Membership Requests",
    titleBarNode: titleBarNode,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}{" "}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Membership Requests"
          data={membershipList}
          displayColumns={displayColumns}
          sortableColumns={sortableColumns}
          sortableFields={sortFields}
          linkColumn="fullNameEng"
          linkUrl="/branchMembership/membership-requests"
          pagination={{ ...payload }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/membership-request"
          refetchFunction={fetchData}
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes(
                  "Membership Request-Delete"
                )
              : false
          }
        />
      )}
    </TableViewContainer>
  );
};

export default MembershipRequestList;
