import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { FC } from "react";

const ErrorMessageBox = styled(Box)({
  color: "var(--zs-red)",
  height: "15px",
  fontSize: "12px",
});

type ErrorMessageProps = {
  message: string | null;
};

export const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return <ErrorMessageBox>{message}</ErrorMessageBox>;
};
