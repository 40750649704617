/* eslint-disable array-callback-return */
import * as yup from "yup";
import regEx from "./regularExpression";
import { validateImageTypes } from "../utils/image-helper";

const validationSchema: any = {
  firstName: yup
    .string()
    .trim("First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "First name must be more than 2 characters")
    .max(30, "First name cannot exceed more than 30 characters")
    .required("First name is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),

  lastName: yup
    .string()
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field")
    .trim("Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(1, "Last name must be more than 1 characters")
    .max(30, "Last name cannot exceed more than 30 characters")
    .required("Last name is a required field"),

  annualIncome: yup
    .number()
    .typeError("Annual Income must be a number")
    .positive("Annual Income must be a positive number")
    .integer("Annual Income must be a whole number")
    .nullable(),

  fullNameMal: yup
    .string()
    .trim("Full name in malayalam cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Full name in malayalam must be more than 2 characters")
    .max(30, "Full name in malayalam cannot exceed more than 30 characters")
    .required("Full name in malayalam is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),

  fullNameEng: yup
    .string()
    .trim("Full name  in english cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Full name in english must be more than 2 characters")
    .max(30, "Full name in english cannot exceed more than 30 characters")
    .required("Full name in english is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  phone: yup
    .string()
    .strict(true)
    .matches(/^[0-9]+$/, "Phone must only contain digits")
    .min(10, "Phone must be 10 characters")
    .max(10, "Phone must be 10 characters")
    .required("Phone is a required field"),
  name: yup
    .string()
    .trim("Name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Name must be more than 2 characters")
    .max(30, "Name cannot exceed more than 30 characters")
    .required("Name is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  houseNo: yup.string().required("House no is a required field"),
  houseNameEng: yup.string().required("House name eng is a required field"),
  title: yup.string().required("Title is a required field"),
  streetNameEng: yup.string().required("Street name eng is a required field"),
  wardNo: yup.string().required("Please select a ward no"),
  gender: yup.string().required("Gender is a required field"),
  kudumbaUnitNameEng: yup.string().required("Please select a kudumba Unit"),
  relationWithFamilyHead: yup
    .string()
    .trim(
      "Relationship with family head cannot include leading and trailing spaces"
    )
    .strict(true)
    .min(2, "Relationship with family head  must be more than 2 characters")
    .max(
      30,
      "Relationship with family head  cannot exceed more than 30 characters"
    )
    .required("Relationship with family head  is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  dateOfResidence: yup
    .string()
    .required("Date of residance is a required field"),
  validityPeriod: yup.string().required("Validity period is a required field"),
  residenceType: yup.string().required("Please select a residance type"),
  houseNameMal: yup
    .string()
    .required("House name malayalam is a required field"),
  streetNameMal: yup
    .string()
    .required("Street name malayalam is a required field"),
  rationCardNumber: yup
    .string()
    .length(10, "Ration Card Number must be exactly 10 characters")
    .matches(regEx.numbersOnly, "Ration Card Number must be valid"),
  isValidityPeriod: yup
    .string()
    .required("Is validity period is a required field"),
  email: yup
    .string()
    .nullable()
    .trim("Email address cannot include leading and trailing spaces")
    .email("Invalid Email address format")
    .matches(regEx.email, "Invalid Email address format")
    .min(2, "Email address must be more than 2 characters")
    .max(50, "Email address cannot exceed more than 30 characters"),
  adminEmail: yup
    .string()
    .trim("Email address cannot include leading and trailing spaces")
    .strict(true)
    .email("Invalid Email address format")
    .required("Email is a required field")
    .matches(regEx.email, "Invalid Email address format")
    .min(2, "Email address must be more than 2 characters")
    .max(50, "Email address cannot exceed more than 30 characters"),
  dob: yup.string().required("Date of birth is a required field"),
  familyID: yup.string().required("Family is a required field"),
  password: yup
    .string()
    .test(
      "betweenSpaces",
      "Password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(8, "Password must be more than 8 characters")
    .max(16, "Password cannot exceed more than 16 characters")
    .required("Password is a required field"),

  newPassword: yup
    .string()
    .test(
      "betweenSpaces",
      "New password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(8, "New password must be more than 8 characters")
    .max(16, "New password cannot exceed more than 16 characters")
    .required("New password is a required field"),
  confirmPassword: yup
    .string()
    .required("Confirm password is a required field")
    .test(
      "betweenSpaces",
      "Conform password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .oneOf(
      [yup.ref("newPassword")],
      "Confirmation password must match the new password"
    ),
  fullName: yup
    .string()
    .trim("Full name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Full name must be more than 2 characters")
    .max(30, "Full name cannot exceed more than 30 characters")
    .required("Full name is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  roleID: yup.string().required("Role is a required field"),

  address: yup.string().strict(true).required("Address is a required field"),
  pinCode: yup.string().strict(true).required("PIN code is a required field"),
  joinedDate: yup
    .string()
    .strict(true)
    .required("Date of joining is a required field"),
  startDate: yup.string().required("Start Date is required"),
  startTime: yup.string().required("Start Time is required"),
  endDate: yup.string().required("End Date is required"),
  endTime: yup.string().required("End Time is required"),
  eventTypeName: yup
    .string()
    .trim("Event type name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Event type name must be more than 2 characters")
    .max(30, "Event type name cannot exceed more than 30 characters")
    .required("Event type name is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  eventTypeId: yup.string().required("Event type is a required field"),
  formImage: yup
    .mixed()
    .nullable()
    .test(
      "FILE_SIZE",
      "Uploaded image is too big. Max size is 2MB",
      (value) => {
        if (value && typeof value !== "string") {
          const file = value as File;
          return file.size <= 2000000;
        }
        return true;
      }
    )
    .test(
      "file-type",
      "Invalid file type. Please select a valid image.",
      (value) => {
        if (!value) return false;
        return validateImageTypes(value as File);
      }
    ),

  location: yup.string().required("Venue is a required field"),

  facilityID: yup.string().required("Facility name is required"),
  bookingStartDate: yup.string().required("Start Date is required"),

  bookingEndDate: yup.string().required("End Date is required"),
  dailyRate: yup
    .number()
    .typeError("Daily rate must be a number")
    .positive("Daily rate must be a positive number")
    .required("Daily rate is required"),
  reducedRateFromSecondDay: yup
    .number()
    .typeError("Discount rate must be a number")
    .positive("Discount rate must be a positive number")
    .nullable(),
  description: yup.string().required("Description is a required field"),
  availabilityStatus: yup
    .string()
    .required("Please select availability status"),
  typeOfFacility: yup.string().required("Please select facility type"),
  //Welfare...........
  welfareCategory: yup
    .string()
    .required("Welfare Category is a required field"),
  type: yup.string().required("Welfare type is a required field"),
  status: yup.string().required("Welfare status is a required field"),
  remarks: yup.string().required("Remark is required"),
  welfareStartDate: yup.string().required("Start Date is required"),
  allotedAmount: yup
    .number()
    .typeError("Alloted amount must be a number")
    .positive("Alloted amount must be a positive number"),
  schemeName: yup
    .string()
    .matches(regEx.alphabetsOnly, "Scheme Name can only contain alphabets")
    .test(
      "betweenSpaces",
      "Scheme Name  has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(2, " Scheme Name  must be more than 2 characters")
    .max(30, " Scheme Name  cannot exceed more than 30 characters")
    .required(" Scheme Name  is a required field"),
};

export const getValidationSchema = (fields: string[]) => {
  const newValidationSchema: any = {};

  fields.map((fieldName) => {
    newValidationSchema[fieldName] = validationSchema[fieldName];
  });

  return yup.object().shape(newValidationSchema);
};
