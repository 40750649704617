import { FC, useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { Gender, MalayalamMonth } from "../../../../../shared/enum";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import { useLocation } from "react-router-dom";
import { EntityContext } from "../../../../../contexts/EntityContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { Family } from "../../../../../shared/interfaces/family";
import InputFormLabel from "../../../../../shared/components/inputFormLabel";
import {
  dateInputFromatHelper,
  dateToISOString,
  validateDateFormat,
  validateNotFutureDate,
} from "../../../../../shared/utils/date-helper";
import { customizeValidationSchema } from "./customSchema";
import moment from "moment";

const Member: FC = () => {
  const location = useLocation();

  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation(
    "/branchMembership/members"
  );
  const { payload, setPayload } = useContext(SidebarContext);
  const [familyID, setFamilyID] = useState(location.state?.familyID);
  const { setEntityName } = useContext(EntityContext);

  useEffect(() => {
    setEntityName({
      entityName: ["Create"],
      entityTitle: "Create Member",
    });
  }, [setEntityName]);

  const handleDOBChange = (newValue: string) => {
    const formatedDate = dateInputFromatHelper(newValue, memberForm);
    memberForm.setFieldValue("dob", formatedDate);
  };
  const numberOptions = Array.from({ length: 32 }, (_, i) => ({
    label: `${i + 1}`,
    value: `${i + 1}`,
  }));

  const { data: familyData } = useQuery("/admin/family", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
    },
  });

  function isAbove18(dobString: any) {
    const dob = moment(dobString, "DD/MM/YYYY");
    const today = moment();

    return today.diff(dob, "years") >= 18;
  }
  console.log(isAbove18("13/02/1989"));

  interface SelectedFamilyOption {
    value: string;
    label: string;
  }
  const selectedFamily: SelectedFamilyOption | null =
    familyData && (familyData as Family)?.familyCode
      ? {
          value: (familyData as Family)?.familyCode,
          label: (familyData as Family)?.familyCode,
        }
      : null;

  const families = (familyData as any)?.items?.map((family: any) => ({
    label: `${family?.familyCode} - ${family?.addressDetails?.houseNameEng}`,
    value: family?.id,
  }));

  const memberForm = useFormik({
    initialValues: {
      familyID: "",
      fullNameEng: "",
      fullNameMal: "",
      email: "",
      phone: null,
      sndpMemberRegNo: null,
      joinedSangamNo: null,
      dob: "",
      gender: null,
      kollavarsham: null,
      month: null,
      date: null,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: customizeValidationSchema,
    validate: (values: any) => {
      const errors: any = {};

      if (values?.dob) {
        const isValidDate = validateDateFormat(values?.dob, "dd/MM/yyyy");
        if (!isValidDate) {
          errors.dob = "Invalid Date format ( DD/MM/YYYY )";
        } else {
          const isValidAndNotFuture = validateNotFutureDate(
            values?.dob,
            "dd/MM/yyyy"
          );
          if (!isValidAndNotFuture) {
            errors.dob = "Date of birth cannot be a future date";
          }
        }
      }
      if (values?.sndpMemberRegNo) {
        if (!isAbove18(values?.dob)) {
          errors.sndpMemberRegNo =
            "Members under age 18 cannot have SNDP Reg number";
        }
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      if (!formData.phone) {
        formData.phone = null;
      }
      let response = null;
      if (id) {
        response = await modifyData(
          `/admin/members/${id}`,
          "put",
          {
            ...formData,
            dob: dateToISOString(formData.dob),
          },
          memberForm
        );
      } else {
        response = await modifyData(
          `/admin/members`,
          "post",
          {
            ...formData,
            dob: dateToISOString(formData.dob),
            dobMal: {
              kollavarsham: formData.kollavarsham,
              date: formData.date,
              month: formData.month,
            },
          },
          memberForm
        );
      }
      if (response) {
        setFamilyID("");
      }
    },
  });

  useEffect(() => {
    if (familyID) {
      memberForm.setFieldValue("familyID", familyID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyID]);

  const titleBar = {
    title: "Create Member",
    buttonTitle: "Save",
    headerTitle: "Member",
    onClick: () => memberForm.handleSubmit(),
    dirty: memberForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      {!familyID ? (
        <>
          <Box className="border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={1}
            >
              <Grid item md={6} sm={6} xs={12}>
                <ZSAutocomplete
                  options={families}
                  label="Family"
                  placeholder="Select Family"
                  required
                  setValue={(selectedOption: any) => {
                    memberForm.setFieldTouched("familyID", true);
                    if (selectedOption?.value) {
                      memberForm.setFieldValue(
                        "familyID",
                        selectedOption?.value
                      );
                    } else {
                      memberForm.setFieldValue("familyID", "");
                    }
                  }}
                  defaultValue={selectedFamily}
                  setBlur={() => {
                    memberForm.setFieldTouched("familyID", true);
                  }}
                  errorMessage={
                    ((memberForm.touched as any)?.familyID &&
                      (memberForm.errors as any)?.familyID) ??
                    null
                  }
                  refetchFunction={(searchString: string) => {
                    setPayload((prevData: any) => {
                      return { ...prevData, search: searchString };
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}

      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={memberForm}
              value={memberForm?.values?.fullNameEng || ""}
              fieldProps={{
                id: "fullNameEng",
                label: "Name (English)",
                name: "fullNameEng",
                type: "text",
                required: true,
                placeholder: "Name in English",
              }}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={memberForm}
              value={memberForm?.values?.fullNameMal || ""}
              fieldProps={{
                id: "fullNameMal",
                name: "fullNameMal",
                label: "Name (Malayalam)",
                type: "text",
                placeholder: "Name in Malayalam",
                required: true,
              }}
            />
          </Grid>

          <Grid item container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputTextfield
                form={memberForm}
                value={memberForm?.values?.dob || ""}
                fieldProps={{
                  label: "Date of birth",
                  id: "dob",
                  name: "dob",
                  type: "text",
                  required: true,
                  placeholder: "DD/MM/YYYY",
                }}
                onChange={handleDOBChange}
              />
            </Grid>

            <Grid container columnSpacing={1} item md={6} sm={6} xs={12}>
              <Grid item ml={1} mt={-1} mb={1} md={12} sm={3} xs={12}>
                <InputFormLabel
                  label={"Date Of Birth in malayalam"}
                ></InputFormLabel>
              </Grid>
              <Grid item md={4} sm={3} xs={12}>
                <ZSSelectBox
                  fieldProps={{
                    id: "member-date",
                    name: "date",
                    placeholder: "Day",
                  }}
                  options={numberOptions}
                  form={memberForm}
                  value={memberForm.values?.date}
                />
              </Grid>
              <Grid item md={4} sm={3} xs={12}>
                <ZSSelectBox
                  fieldProps={{
                    id: "member-month",
                    name: "month",
                    placeholder: "Month",
                  }}
                  options={[
                    {
                      label: MalayalamMonth.Chingam,
                      value: MalayalamMonth.Chingam,
                    },
                    {
                      label: MalayalamMonth.Kanni,
                      value: MalayalamMonth.Kanni,
                    },
                    {
                      label: MalayalamMonth.Thulam,
                      value: MalayalamMonth.Thulam,
                    },
                    {
                      label: MalayalamMonth.Vrischikam,
                      value: MalayalamMonth.Vrischikam,
                    },
                    {
                      label: MalayalamMonth.Dhanu,
                      value: MalayalamMonth.Dhanu,
                    },
                    {
                      label: MalayalamMonth.Makaram,
                      value: MalayalamMonth.Makaram,
                    },
                    {
                      label: MalayalamMonth.Kumbham,
                      value: MalayalamMonth.Kumbham,
                    },
                    {
                      label: MalayalamMonth.Meenam,
                      value: MalayalamMonth.Meenam,
                    },
                    {
                      label: MalayalamMonth.Medam,
                      value: MalayalamMonth.Medam,
                    },
                    {
                      label: MalayalamMonth.Edavam,
                      value: MalayalamMonth.Edavam,
                    },
                    {
                      label: MalayalamMonth.Midhunam,
                      value: MalayalamMonth.Midhunam,
                    },
                    {
                      label: MalayalamMonth.Karkidakam,
                      value: MalayalamMonth.Karkidakam,
                    },
                  ]}
                  form={memberForm}
                  value={memberForm.values?.month}
                />
              </Grid>

              <Grid item md={4} sm={3} xs={12}>
                <InputTextfield
                  form={memberForm}
                  value={memberForm?.values?.kollavarsham || ""}
                  fieldProps={{
                    id: "kollavarsham",
                    name: "kollavarsham",
                    type: "text",
                    required: true,
                    placeholder: "Kollavarsham",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ZSSelectBox
              fieldProps={{
                label: "Gender",
                id: "member-gender",
                name: "gender",
                placeholder: "Select Gender",
                required: true,
              }}
              options={[
                { label: Gender.Male, value: Gender.Male },
                { label: Gender.Female, value: Gender.Female },
                { label: Gender.Other, value: Gender.Other },
              ]}
              form={memberForm}
              value={memberForm.values?.gender}
              errorMessage={
                ((memberForm.touched as any)?.gender &&
                  (memberForm.errors as any)?.gender) ??
                null
              }
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={memberForm}
              value={memberForm?.values?.email || ""}
              fieldProps={{
                id: "email",
                name: "email",
                label: "Email",
                type: "text",
                required: false,
                placeholder: "Email",
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={memberForm}
              value={memberForm?.values?.phone || ""}
              fieldProps={{
                id: "phone",
                label: "Phone",
                name: "phone",
                type: "text",
                required: true,
                placeholder: "Phone",
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={memberForm}
              value={memberForm?.values?.sndpMemberRegNo || ""}
              fieldProps={{
                label: "Yogam Membership Number",
                id: "sndpMemberRegNo",
                name: "sndpMemberRegNo",
                type: "text",
                placeholder: "Yogam Membership Number",
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={memberForm}
              value={memberForm?.values?.joinedSangamNo || ""}
              fieldProps={{
                id: "joinedSangamNo",
                label: "Joined Sangam Number",
                name: "joinedSangamNo",
                type: "joinedSangamNo",
                placeholder: "Joined Sangam Number",
              }}
            />
          </Grid>
        </Grid>
        {/* <div className={editPermission ? "disabled" : ""}></div> */}
      </Box>
    </FormContainer>
  );
};

export default Member;
