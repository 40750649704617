/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import { FC } from "react";
import { Relations } from "../../../../../shared/interfaces/relationship";

interface RelationshipsDetails {
  memberRelations: Relations[];
}
const Relationships: FC<RelationshipsDetails> = ({ memberRelations }) => {
  return (
    <Container className="tableListContainer">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Member Code</TableCell>
              <TableCell>Member Name</TableCell>
              <TableCell>Relationship</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberRelations?.map((data: any) => (
              <TableRow>
                <TableCell>
                  {data.relationWithmember?.code
                    ? data?.relationWithmember?.code
                    : "No Data"}
                </TableCell>
                <TableCell>
                  {data.relationWithmember?.fullNameEng
                    ? data.relationWithmember?.fullNameEng
                    : "No Data"}
                </TableCell>
                <TableCell>
                  {data.relationship ? data?.relationship : "No Data"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Relationships;
