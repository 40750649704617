import moment from "moment";

const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const formatDate = (date: any) => {
  return isoRegex.test(date);
};
export const getFamilyFields = (memberRequestData: any) => [
  {
    label: "Family Code:",
    value: memberRequestData?.family?.familyCode,
  },
  {
    label: "Family Head:",
    value: memberRequestData?.family?.familyHeadDetails?.fullNameEng,
  },
  {
    label: "Date Of Joining:",
    value: formatDate(memberRequestData?.family?.joinedDate)
      ? moment(memberRequestData?.family?.joinedDate).format("DD/MM/YYYY")
      : moment(memberRequestData?.family?.joinedDate, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
    formField: false,
  },
  {
    label: "House Name:",
    value: memberRequestData?.family?.addressDetails.houseNameEng,
  },
  {
    label: "House Location:",
    value: memberRequestData?.family?.addressDetails.streetNameEng,
  },
  {
    label: "Kudumba Unit:",
    value: memberRequestData?.family?.kudumbaUnitNameEng ?? "Not Available",
  },
];
