import WelfareRequestList from "../components/welfareRequestList";
import DiamondIcon from "@mui/icons-material/Diamond";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import WelfareRequestApproveView from "../components/welfareRequestApproveView";
const memberRequestRoute = {
  icon: DiamondIcon,
  name: "Welfare Scheme",
  link: "/welfare-schemes",
  path: "welfare-schemes",
  element: "",
  index: 70,
  children: [
    {
      path: "welfare-requests",
      element: <WelfareRequestList />,
      name: "Welfare Requests",
      index: 1,
      permission: "Welfare request-Read", // Permission name
      link: "/welfare-schemes/welfare-requests",
      icon: RequestPageIcon,
    },
    {
      path: "welfare-requests/:id",
      element: <WelfareRequestApproveView />,
    },
  ],
};
export default memberRequestRoute;
