import * as Yup from "yup";
import regEx from "../../../../../shared/validations/regularExpression";

let internalSchema = Yup.object().shape({
  jobType: Yup.string().required("Job Type is required"),
  jobTitle: Yup.string().required("Job Title is required"),
  noOfOpenings: Yup.number()
    .typeError("No of Openings must be a number")
    .positive("No of Openings must be a positive number")
    .required("No of Openings is required"),
  name: Yup.string()
    .trim("Name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Name must be more than 2 characters")
    .max(30, "Name cannot exceed more than 30 characters")
    .required("Name is a required field")
    .matches(regEx.alphabetsOnly, "Only alphabets are allowed for this field"),
  email: Yup.string()
    .nullable()
    .trim("Email address cannot include leading and trailing spaces")
    .email("Invalid Email address format")
    .matches(regEx.email, "Invalid Email address format")
    .min(2, "Email address must be more than 2 characters")
    .max(50, "Email address cannot exceed more than 30 characters"),

  phone: Yup.string()
    .strict(true)
    .min(10, "Phone must be 10 characters")
    .max(10, "Phone must be 10 characters")
    .required("Phone is a required field")
    .matches(regEx.numbersOnly, "Only numbers are allowed"),

  location: Yup.string().required("Location is required"),
  salaryRange: Yup.string().required("Salary Range is required"),
  description: Yup.string().required("Description is required"),
  applicationEndDate: Yup.string()
    .strict(true)
    .required("Application end date is a required field"),
  employmentType: Yup.string().required("Employment Type is required"),
});

export default internalSchema;
