import "./styles.css";
import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { FC } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";

type FieldProps = {
  id: string;
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
};

type ErrorMessageProps = {
  fieldProps: FieldProps;
  options: any[];
  defaultValue?: any;
  value?: any;
  errorMessage?: string | boolean;
  onChange?: Function;
  direction?: "ltr" | "rtl";
  disabled?: boolean;
  form?: any;
};

export const TitleBarSelectBox: FC<ErrorMessageProps> = ({
  fieldProps,
  options,
  defaultValue,
  value,
  errorMessage,
  direction = "ltr",
  disabled = false,
  form,
  onChange,
}) => {
  return (
    <div className="selectBoxContainerTitleBar">
      {fieldProps?.label && (
        <InputLabel sx={{ marginBottom: "9px" }}>
          {fieldProps?.label}
          {fieldProps.required && <span style={{ color: "red" }}> * </span>}
        </InputLabel>
      )}
      <Select
        name={fieldProps?.name}
        fullWidth
        defaultValue={defaultValue || " "}
        value={value ?? ((form?.values as any)?.[fieldProps?.name] || " ")}
        disabled={disabled}
        IconComponent={KeyboardArrowDown}
        error={Boolean(
          form?.errors?.[fieldProps?.name] && form?.touched?.[fieldProps?.name]
        )}
        onChange={(e) => {
          form?.setFieldTouched(fieldProps?.name, true);
          if (onChange) {
            onChange(e.target.value);
          } else {
            form?.setFieldValue(fieldProps?.name, e.target.value);
          }
        }}
      >
        {fieldProps?.placeholder && (
          <MenuItem disabled value=" " style={{ direction }}>
            <Typography className="disableSelect">
              {fieldProps?.placeholder}
            </Typography>
          </MenuItem>
        )}
        {options?.map((option: any, index: number) => (
          <MenuItem value={option?.value} style={{ direction }} key={index}>
            {option?.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
