import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { FC } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { FormContainer } from "../../../../../shared/components/formContainer";

const EventType: FC = () => {
  const { loading: apiLoading, modifyData } = useMutation(
    "/event-management/event-type"
  );

  const eventTypeForm = useFormik({
    initialValues: {
      eventTypeName: "",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(["eventTypeName"]),

    onSubmit: async (formData: any) => {
      modifyData(
        `/admin/event-type`,
        "post",
        {
          ...formData,
        },
        eventTypeForm
      );
    },
  });

  const titleBar = {
    title: "Create Event Type",
    buttonTitle: "Save",
    headerTitle: "Event Type",
    onClick: () => eventTypeForm.handleSubmit(),
    dirty: eventTypeForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={0}>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={eventTypeForm}
              fieldProps={{
                id: "eventTypeName",
                name: "eventTypeName",
                type: "text",
                required: true,
                placeholder: "Event Type Name",
                capitalizedFirst: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </FormContainer>
  );
};
export default EventType;
