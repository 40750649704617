import { FC, useContext, useEffect, useState } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { Job } from "../../../../../shared/interfaces/job";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import { JobStatus, JobType } from "../../../../../shared/enum";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const JobList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const [jobType, setTypeFilter] = useState("All");
  const [jobStatus, setStatusFilter] = useState("All");
  const { loading, data, fetchData, error } = useQuery("/admin/job", {
    params: {
      ...payload,
      jobType: jobType,
      jobStatus: jobStatus,
    },
  });
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobType, jobStatus]);
  const onButtonClick = () => {
    navigate("/job-management/jobs/create");
  };

  const jobDisplayColumns = ["jobTitle", "type", "location", "status"];

  let jobList: Job[] = [];
  if (data && (data as any)?.items) {
    jobList = (data as any)?.items.map((item: Job) => {
      return {
        id: item.id,
        jobTitle: item?.jobTitle,
        type: item?.jobType,
        location: item?.location,
        status: item?.jobStatus,
        deleteButtonStatus: item?.jobStatus === "Published" ? true : false,
      };
    });
  }

  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    jobTitle: `jobTitle`,
    type: `jobType`,
    location: `location`,
    status: "jobStatus",
    createdOn: "createdAt",
  };
  const sortableColumnsMember = ["type"];

  const titleBarNode = (
    <>
      <TitleBarSelectBox
        fieldProps={{
          id: "type-filter",
          name: "type-filter",
        }}
        value={jobType ?? "All"}
        onChange={(value: string) => setTypeFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: JobType.Internal, label: JobType.Internal },
          { value: JobType.External, label: JobType.External },
        ]}
      />
      <TitleBarSelectBox
        fieldProps={{
          id: "status-log-filter",
          name: "status-log-filter",
        }}
        value={jobStatus ?? "All"}
        onChange={(value: string) => setStatusFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: JobStatus.Draft, label: JobStatus.Draft },
          { value: JobStatus.Published, label: JobStatus.Published },
          { value: JobStatus.Closed, label: JobStatus.Closed },
        ]}
      />
      <SearchBox />
    </>
  );

  const titleBar = {
    title: "Jobs",
    buttonTitle: "Create",
    headerTitle: "Jobs",
    onClick: onButtonClick,
    titleBarNode: titleBarNode,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Job-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Job"
          data={jobList}
          displayColumns={jobDisplayColumns}
          sortableColumns={sortableColumnsMember}
          linkColumn="jobTitle"
          linkUrl="/job-management/jobs"
          sortableFields={sortFields}
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/job"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("Job-Delete")
              : false
          }
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default JobList;
