import "./styles.css";
import { FC, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import TabPanel from "../../../../../shared/components/tabpanel/tabpanel";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import InputTextfield from "../../../../../shared/components/textField";
import { useFormik } from "formik";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { Gender, IdProofType } from "../../../../../shared/enum";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { Member } from "../../../../../shared/interfaces/member";
import { Address } from "../../../../../shared/interfaces/address";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import { Family } from "../../../../../shared/interfaces/family";
import { getFamilyEditFields } from "./familyFields";
import { getMemberEditFields } from "./memberEditFields";
import { getCurrentAddressFields } from "./currentAddressFields";
import CourseList from "./courseList";
import JobList from "./jobList";
import VehicleList from "./vehicleList";
import Relationships from "./relationships";
import { customizeValidationSchema } from "./customSchema";
import { Relations } from "../../../../../shared/interfaces/relationship";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

const MemberView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const {
    modifyData,
    error: saveError,
    loading: saveApiLoading,
  } = useMutation();
  const { loading, data, fetchData } = useQuery(`/admin/members/${id}`, null);
  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Member-Update")
    : true;

  const setUploadFile = (file: any) => {
    memberForm.values.uploadFile = file;
    memberForm.dirty = true;
  };

  const memberData: Member = (data as any)?.data || {};
  const memberRelations: Relations[] | null = memberData?.relations || null;
  let currentAddress: Address | null =
    memberData?.family?.familyAddresses?.find(
      (address: Address) => address.currentAddress
    ) || null;
  const closeMemberEdit = () => {
    fetchData();
    setEditStatus(false);
  };
  const memberForm = useFormik({
    initialValues: {
      fullNameEng: memberData?.fullNameEng,
      fullNameMal: memberData?.fullNameMal,
      memberCode: memberData?.code,
      email: memberData?.email,
      phone: memberData?.phone,
      sndpMemberRegNo: memberData?.sndpMemberRegNo,
      joinedSangamNo: memberData?.joinedSangamNo,
      dob: memberData?.dob,
      gender: memberData?.gender,
      idProofType: memberData?.idProofType,
      idProofNumber: memberData?.idProofNumber,
      uploadFile: null,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: customizeValidationSchema,
    validate: (values: any) => {
      const errors: any = {};
      if (memberForm.values?.phone && memberForm.values?.phone.length !== 10) {
        errors.phone = "Invalid Phone Number";
      }
      return errors;
    },
    onSubmit: async (formData: any, { resetForm }) => {
      if (!formData.phone) {
        formData.phone = null;
      }

      const formDataToSend = new FormData();
      formDataToSend.append("fullNameEng", formData.fullNameEng);
      formDataToSend.append("fullNameMal", formData.fullNameMal);
      formDataToSend.append("memberCode", formData.memberCode);
      formDataToSend.append("phone", formData.phone ?? "");
      formDataToSend.append("sndpMemberRegNo", formData.sndpMemberRegNo ?? "");
      formDataToSend.append("joinedSangamNo", formData.joinedSangamNo ?? "");
      formDataToSend.append("dob", formData.dob);
      formDataToSend.append("gender", formData.gender);
      if (formData.idProofType) {
        formDataToSend.append("idProofType", formData.idProofType);
      }
      formDataToSend.append("idProofNumber", formData.idProofNumber ?? "");
      formDataToSend.append("file", formData.uploadFile);
      const response = await modifyData(
        `/admin/members/${id}`,
        "put",
        formDataToSend,
        memberForm
      );
      console.log(saveError);
      if (saveError) {
        return;
      }
      console.log("response  >>>  ", response);
      if (response) {
        fetchData();
        resetForm();
        setEditStatus(false);
      }
    },
  });
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const viewTitleBar: TitleBarProps = {
    title: "Member Edit",
    headerTitle: "Edit",
  };

  const editTitlebar: TitleBarProps = {
    title: "Member Edit",
    headerTitle: "Member Edit",
    buttonTitle: "Save",
    onClick: () => memberForm.handleSubmit(),
    dirty: memberForm.dirty,
    buttonLoading: loading,
  };

  const titleBarNode = (
    <Tooltip arrow title="Cancel">
      <IconButton
        className="TitleBarIcon"
        size="medium"
        id={`member-edit`}
        onClick={() => closeMemberEdit()}
        aria-label={`member-edit`}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );

  const memberEditTooltipNode = editPermission && (
    <Tooltip arrow title="Edit">
      <IconButton
        className="TitleBarIcon"
        size="medium"
        id={`Member-edit`}
        onClick={() => setEditStatus(true)}
        aria-label={`member-edit`}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );

  const familyData: Family = memberData.family || {};
  const familyFields = getFamilyEditFields(familyData);
  const memberEditFields = getMemberEditFields(memberData);
  const currentAddressFields = getCurrentAddressFields(currentAddress);

  const handleDocView = (fileUrl: string | URL) => {
    window.open(fileUrl);
  };

  const idProofVIew = (
    <>
      {memberData?.idProofUrl ? (
        <Box className="border-custom">
          <Typography sx={{ mb: 1 }} component="span">
            File Preview: &nbsp;&nbsp;
            <Tooltip arrow title="View Document">
              <Link
                onClick={() => handleDocView(memberData?.idProofUrl)}
                to={""}
              >
                <DocumentScannerIcon sx={{ mb: -0.4 }} />
              </Link>
            </Tooltip>
          </Typography>
        </Box>
      ) : null}
    </>
  );

  return (
    <>
      {editStatus ? (
        <>
          <FormContainer
            titleBar={editTitlebar}
            loading={saveApiLoading}
            titleBarNode={titleBarNode}
          >
            <Box className="border-custom">
              <Grid
                container
                alignItems="stretch"
                columnSpacing={4}
                rowSpacing={2}
              >
                {memberEditFields.map(
                  (field: any, index: number) =>
                    (field?.formField === undefined ||
                      field.formField === true) && (
                      <Grid item md={6} sm={6} xs={12} key={field.id}>
                        <InputTextfield
                          form={memberForm}
                          fieldProps={field}
                          key={index}
                        />
                      </Grid>
                    )
                )}

                <Grid item md={6} sm={6} xs={12} key="idProofType">
                  <ZSSelectBox
                    fieldProps={{
                      id: "member-idProofType",
                      name: "idProofType",
                      label: "ID Proof Type",
                      placeholder: "Select an option",
                    }}
                    options={[
                      { label: IdProofType.Aadhar, value: IdProofType.Aadhar },
                      {
                        label: IdProofType.License,
                        value: IdProofType.License,
                      },
                      {
                        label: IdProofType.PAN,
                        value: IdProofType.PAN,
                      },
                      {
                        label: IdProofType.VoterID,
                        value: IdProofType.VoterID,
                      },
                    ]}
                    form={memberForm}
                    value={memberForm.values?.idProofType}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} key="tender-file">
                  <InputLabel>Upload ID Proof</InputLabel>
                  <OutlinedInput
                    size="small"
                    fullWidth
                    id="outlined-basic"
                    inputProps={{
                      accept: [".pdf, image/*"],
                    }}
                    type={"file"}
                    className="fileInput"
                    onChange={(event: any) =>
                      setUploadFile(event.target.files[0])
                    }
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} key="gender">
                  <ZSSelectBox
                    fieldProps={{
                      id: "member-gender",
                      name: "gender",
                      label: "Gender",
                      placeholder: "Select an option",
                    }}
                    options={[
                      { label: Gender.Male, value: Gender.Male },
                      {
                        label: Gender.Female,
                        value: Gender.Female,
                      },
                      {
                        label: Gender.Other,
                        value: Gender.Other,
                      },
                    ]}
                    form={memberForm}
                    value={memberForm.values?.gender}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormContainer>
        </>
      ) : (
        <ViewContainer
          loading={loading}
          titleBar={viewTitleBar}
          tabs={
            <div className="tabs-container">
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Profile" />
                <Tab label="Education" />
                <Tab label="Occupation" />
                <Tab label="Vehicle" />
                <Tab label="Relationship" />
              </Tabs>
            </div>
          }
        >
          {" "}
          <div className="  ">
            <TabPanel value={activeTab} index={0}>
              <SingleEntityContainer
                entityFields={memberEditFields}
                entityTitle="Profile Details"
                children={idProofVIew}
                tooltips={memberEditTooltipNode}
              ></SingleEntityContainer>
              <SingleEntityContainer
                entityFields={familyFields}
                entityTitle="Family Details"
              ></SingleEntityContainer>
              <SingleEntityContainer
                entityFields={currentAddressFields}
                entityTitle="Address Details"
              ></SingleEntityContainer>
            </TabPanel>

            <TabPanel value={activeTab} index={1}>
              <Box className="border-custom">
                <span>
                  <b>Education Details</b>
                </span>
                {memberData?.memberCourse &&
                memberData.memberCourse.length > 0 ? (
                  <CourseList memberData={memberData} />
                ) : (
                  <center>
                    <Typography
                      sx={{ mt: 2, mb: 2 }}
                      variant="body1"
                      color="textSecondary"
                    >
                      Education details not updated
                    </Typography>
                  </center>
                )}
              </Box>
            </TabPanel>

            <TabPanel value={activeTab} index={2}>
              <Box className="border-custom">
                <span>
                  <b>Occupation Details</b>
                </span>
                {memberData?.occupations &&
                memberData.occupations.length > 0 ? (
                  <JobList memberData={memberData} />
                ) : (
                  <center>
                    <Typography
                      sx={{ mt: 2, mb: 2 }}
                      variant="body1"
                      color="textSecondary"
                    >
                      Occupation details not updated
                    </Typography>
                  </center>
                )}
              </Box>
            </TabPanel>

            <TabPanel value={activeTab} index={3}>
              <Box className="border-custom">
                <span>
                  <b>Vehicle Details</b>
                </span>
                {memberData?.vehicles && memberData?.vehicles.length > 0 ? (
                  <VehicleList memberData={memberData} />
                ) : (
                  <center>
                    <Typography
                      sx={{ mt: 2, mb: 2 }}
                      variant="body1"
                      color="textSecondary"
                    >
                      Vehicle details not updated
                    </Typography>
                  </center>
                )}
              </Box>
            </TabPanel>

            <TabPanel value={activeTab} index={4}>
              <Box className="border-custom">
                <span>
                  <b>Relationship Details</b>
                </span>
                {memberRelations && memberRelations.length > 0 ? (
                  <Relationships memberRelations={memberRelations} />
                ) : (
                  <center>
                    <Typography
                      sx={{ mt: 2, mb: 2 }}
                      variant="body1"
                      color="textSecondary"
                    >
                      Relathionship details not updated
                    </Typography>
                  </center>
                )}
              </Box>
            </TabPanel>
          </div>
        </ViewContainer>
      )}
    </>
  );
};
export default MemberView;
