import { FC, useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { useFormik } from "formik";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import CloseIcon from "@mui/icons-material/Close";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
// import { getValidationSchema } from "../../../../../shared/validations/schema";
import { getBannerEditFields } from "./bannerEditFields";
import { EntityContext } from "../../../../../contexts/EntityContext";
import ImageInputField from "../../../../../shared/components/imageInputField";
import SingleEntityImageViewer from "../../../../../shared/components/singleEntityImageViewer";
import ToggleSwitch from "../../../../../shared/components/switch";
import { customizeValidationSchema } from "../customSchema";

const BannerEditView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const { setEntityName } = useContext(EntityContext);
  const { loading, data, fetchData } = useLazyQuery(
    `/admin/banner/${id}`,
    null
  );

  const { loading: saveApiLoading, modifyData } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setEntityName({
      entityName: ["Edit"],
      entityTitle: "Edit Banner",
    });
  }, [setEntityName]);

  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Banner-Update")
    : true;

  const bannerData = (data as any)?.data || {};
  const bannerEditFields = getBannerEditFields(bannerData);
  const bannerForm = useFormik({
    initialValues: {
      name: bannerData?.name,
      status: bannerData?.status,
      // formImage: bannerData?.imageUrl,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: customizeValidationSchema(),
    onSubmit: async (formData: any, { resetForm }) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("status", formData.status);
      if (formData.formImage) {
        formDataToSend.append("file", formData.formImage);
      }

      const responseData = await modifyData(
        `/admin/banner/${id}`,
        "put",
        formDataToSend,
        config
      );
      if (responseData) {
        await fetchData();
      }
      resetForm();
      setEditStatus(false);
    },
  });

  const handleCloseEdit = () => {
    bannerForm.resetForm();
    setEditStatus(false);
  };

  const handleEdit = () => {
    setEditStatus(!editStatus);
  };

  const viewTitleBar: TitleBarProps = {
    title: "Banner",
    headerTitle: " Edit",
  };

  const editTitlebar: TitleBarProps = {
    title: "Banner Edit",
    headerTitle: "Banner",
    buttonTitle: "Save",
    onClick: () => bannerForm.handleSubmit(),
    dirty: bannerForm.dirty,
    buttonLoading: loading,
  };

  const titleBarNode = (
    <>
      <Tooltip arrow title="Cancel">
        <IconButton
          className="TitleBarButton"
          size="medium"
          id={`banner-type-edit`}
          onClick={handleCloseEdit}
          aria-label={`banner-type-edit`}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const requestTooltipNode = editPermission && (
    <Tooltip arrow title="Edit">
      <IconButton
        className="TitleBarButton"
        size="medium"
        id={`banner-type-edit`}
        onClick={handleEdit}
        aria-label={`banner-type-edit`}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {editStatus ? (
        <FormContainer
          loading={saveApiLoading}
          titleBar={editTitlebar}
          titleBarNode={titleBarNode}
        >
          <Box className="border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={2}
            >
              <Grid item md={12} sm={12} xs={12} sx={{ marginTop: 4 }}>
                <ToggleSwitch
                  status={bannerForm?.values?.status}
                  handleChange={() =>
                    bannerForm.setFieldValue(
                      "status",
                      !bannerForm?.values?.status
                    )
                  }
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={bannerForm}
                  fieldProps={{
                    id: "name",
                    name: "name",
                    type: "text",
                    label: "Name",
                    required: true,
                    placeholder: "Banner Name",
                    capitalizedFirst: true,
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12} key="facility-imageURL">
                <ImageInputField
                  id="facility-image"
                  name="formImage"
                  label="Banner Image"
                  setPreviewUrl={setPreviewUrl}
                  form={bannerForm}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12} key="facility-imagePreview">
                <SingleEntityImageViewer
                  src={previewUrl ? previewUrl : bannerData?.imageUrl}
                  alt="Event"
                />
              </Grid>
            </Grid>
          </Box>
        </FormContainer>
      ) : (
        <ViewContainer loading={loading} titleBar={viewTitleBar}>
          <SingleEntityContainer
            entityFields={bannerEditFields}
            entityTitle="Banner  Details"
            tooltips={requestTooltipNode}
          >
            {bannerData?.imageUrl ? (
              <SingleEntityImageViewer
                src={previewUrl ? previewUrl : bannerData?.imageUrl}
                alt="Banner"
              />
            ) : null}
          </SingleEntityContainer>
        </ViewContainer>
      )}
    </>
  );
};
export default BannerEditView;
