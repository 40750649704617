import EventType from "../components/eventTypeCreate";
import EventTypeList from "../components/eventTypeList";
import EventNoteIcon from "@mui/icons-material/EventNote";
import EventIcon from "@mui/icons-material/Event";
import { Navigate } from "react-router-dom";
import EventTypeEditView from "../components/eventTypeEditView";

const eventTypeRoute = {
  icon: EventIcon,
  name: "Event Management",
  link: "/event-management",
  path: "event-management",
  element: "",
  index: 30,
  children: [
    {
      path: "event-type",
      element: <EventTypeList />,
      name: "Event Types",
      permission: "EventType-Read", // Permission name
      index: 0,
      link: "event-management/event-type",
      icon: EventNoteIcon,
    },
    {
      path: "event-type/create",
      element: <EventType />,
    },
    {
      path: "event-type/:id",
      element: <EventTypeEditView />,
    },

    {
      path: "",
      element: <Navigate to="event" replace />,
    },
  ],
};
export default eventTypeRoute;
