import TenderCreate from "../components/tenderCreate";
import TenderList from "../components/tenderList";
import HandshakeIcon from "@mui/icons-material/Handshake";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Navigate } from "react-router-dom";
import TenderView from "../components/tenderEditView";
const tenderRoute = {
  icon: TextSnippetIcon,
  name: "Tender Management",
  link: "/tender-management",
  path: "tender-management",
  index: 60,
  element: "",
  children: [
    {
      path: "tenders",
      element: <TenderList />,
      permission: "Tenders-Read",
      name: "Tenders",
      index: 1,
      link: "tender-management/tenders",
      icon: HandshakeIcon,
    },
    {
      path: "tenders/create",
      element: <TenderCreate />,
    },
    {
      path: "tenders/:id",
      element: <TenderView />,
    },
    {
      path: "",
      element: <Navigate to="tenders" replace />,
    },
  ],
};
export default tenderRoute;
