import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { BookingStatus } from "../../../../../shared/enum";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { LoadingButton } from "@mui/lab";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { getFacilityBookingFields } from "./facilityBookingFields";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const FacilityBookingView: FC = () => {
  const { id } = useParams();
  const [openConfirmationModalData, setOpenConfirmationModalData] = useState({
    status: false,
    message: "",
    bookingStatus: "",
  });
  const [textContent, setTextContent] = useState<{ type: String; value: any }>({
    type: "",
    value: "",
  });

  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/facility-booking/${id}`,
    null
  );

  const { error: saveError, modifyData } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const facilityBookingData = (data as any)?.data || {};
  const facilityBookingFields = getFacilityBookingFields(facilityBookingData);

  const handleStatusChange = async () => {
    const loadingSetter =
      openConfirmationModalData.bookingStatus === BookingStatus.Approved
        ? setApproveLoading
        : openConfirmationModalData.bookingStatus === BookingStatus.Rejected
        ? setRejectLoading
        : setCancelLoading;

    loadingSetter(true);

    await modifyData(
      `/admin/facility-booking/update-booking-status/${id}`,
      "put",
      {
        bookingStatus: openConfirmationModalData.bookingStatus,
        remarks: textContent.value || "",
      }
    );

    loadingSetter(false);

    if (!saveError) {
      fetchData();
    }

    handleCloseConfirmDialog();
  };

  const handleOpenConfirmDialog = (message: string, bookingStatus: string) => {
    setOpenConfirmationModalData({
      status: true,
      message: message,
      bookingStatus: bookingStatus,
    });
    setTextContent((prev: any) => ({
      ...prev,
      type: "text",
    }));
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmationModalData({
      status: false,
      message: "",
      bookingStatus: "",
    });
    setTextContent((prev: any) => ({
      type: "",
      value: "",
    }));
  };

  const handleConfirmAction = async (responseStatus: boolean) => {
    if (responseStatus) {
      handleStatusChange();
    }
    handleCloseConfirmDialog();
  };

  const facilityApprovePermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Facility Bookings-Approve")
    : true;

  const facilityRejectPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Facility Bookings-Reject")
    : true;

  const facilityCancelPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Facility Bookings-Cancel")
    : true;

  const editTitlebar: TitleBarProps = {
    title: "Facility Booking",
    headerTitle: "Facility Booking",
    buttonTitle: "Save",
    buttonLoading: loading,
  };

  const titleBarNode = (
    <>
      {facilityBookingData?.bookingStatus === BookingStatus.Approved &&
        facilityCancelPermission && (
          <LoadingButton
            className="TitleBarButton"
            loading={cancelLoading}
            variant="contained"
            size="small"
            id="facility-booking-cancel"
            onClick={() =>
              handleOpenConfirmDialog(
                "Are you sure you want to cancel this booking?",
                BookingStatus.Cancelled
              )
            }
          >
            Cancel Booking
          </LoadingButton>
        )}

      {facilityBookingData?.bookingStatus === BookingStatus.Pending && (
        <>
          {facilityApprovePermission && (
            <LoadingButton
              className="TitleBarButton"
              loading={approveLoading}
              variant="contained"
              size="small"
              id="facility-booking-approve"
              onClick={() =>
                handleOpenConfirmDialog(
                  "Are you sure you want to approve this booking?",
                  BookingStatus.Approved
                )
              }
            >
              Approve
            </LoadingButton>
          )}

          {facilityRejectPermission && (
            <LoadingButton
              className="TitleBarButton"
              loading={rejectLoading}
              variant="contained"
              size="small"
              id="facility-booking-reject"
              onClick={() =>
                handleOpenConfirmDialog(
                  "Are you sure you want to reject this booking?",
                  BookingStatus.Rejected
                )
              }
            >
              Reject
            </LoadingButton>
          )}
        </>
      )}

      <IconButton
        className="TitleBarButton"
        size="medium"
        id={`tender-edit`}
        onClick={() => window.history.back()}
        aria-label={`tender-edit`}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <>
      <ViewContainer
        loading={loading}
        titleBar={editTitlebar}
        titleBarNode={titleBarNode}
      >
        <SingleEntityContainer
          entityFields={facilityBookingFields}
          entityTitle="Facility Booking Details"
        />
      </ViewContainer>

      <ConfirmDialog
        title={
          openConfirmationModalData?.bookingStatus === BookingStatus.Rejected
            ? "Confirm Rejection"
            : "Confirm"
        }
        openConfirmDialog={openConfirmationModalData?.status}
        setOpenConfirmDialog={handleCloseConfirmDialog}
        message={openConfirmationModalData.message}
        handleSubmit={handleConfirmAction}
        setTextContent={setTextContent}
        required={true}
        label={
          openConfirmationModalData?.bookingStatus === BookingStatus.Rejected
            ? "Remarks for rejection"
            : "Remarks"
        }
        textContent={textContent}
      />
    </>
  );
};

export default FacilityBookingView;
