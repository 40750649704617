import CertificateRequestEditView from "../components/certificateRequestEditView";
import CertificateRequestList from "../components/certificateRequestList";
import ApprovalIcon from "@mui/icons-material/Approval";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Navigate } from "react-router-dom";
const certificateRoute = {
  icon: ApprovalIcon,
  name: "Certificate Management",
  link: "/certificate-management",
  path: "/certificate-management",
  element: "",
  index: 20,
  children: [
    {
      path: "certificate-requests",
      element: <CertificateRequestList />,
      name: "Certificate Requests",
      permission: "Certificate Request-Read", // Permission
      index: 0,
      link: "/certificate-management/certificate-requests",
      icon: AdminPanelSettingsIcon,
    },
    {
      path: "certificate-requests/:id",
      element: <CertificateRequestEditView />,
    },
    {
      path: "",
      element: <Navigate to="certificate-requests" replace />,
    },
  ],
};
export default certificateRoute;
