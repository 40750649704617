import moment from "moment";
const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const formatDate = (date: any) => {
  return isoRegex.test(date);
};
export const getCurrentAddressFields = (currentAddress: any) => [
  {
    id: "houseNo",
    name: "houseNo",
    label: "House No:",
    value: currentAddress?.houseNo,
    type: "text",
    placeholder: "Enter your House Number",
    required: true,
  },
  {
    id: "houseNameEng",
    name: "houseNameEng",
    label: "House Name English:",
    value: currentAddress?.houseNameEng,
    type: "text",
    placeholder: "Enter your House Name in English",
    required: true,
  },
  {
    id: "houseNameMal",
    name: "houseNameMal",
    label: "House Name Malayalam:",
    value: currentAddress?.houseNameMal,
    type: "text",
    placeholder: "Enter your House Name in Malayalam",
    required: true,
  },
  {
    label: "Residence Type:",
    value: currentAddress?.residenceType,
    required: true,
    formField: false,
  },
  {
    id: "streetNameEng",
    name: "streetNameEng",
    label: "Street Name English:",
    value: currentAddress?.streetNameEng,
    type: "text",
    placeholder: "Enter your Street Name in English",
    required: true,
  },
  {
    id: "streetNameMal",
    name: "streetNameMal",
    label: "Street Name Malayalam:",
    value: currentAddress?.streetNameMal,
    type: "text",
    placeholder: "Enter your Street Name in Malayalam",
    required: true,
  },

  {
    label: "Date Of Residence:",
    value: formatDate(currentAddress?.dateOfResidence)
      ? moment(currentAddress?.dateOfResidence).format("DD/MM/YYYY")
      : moment(currentAddress?.dateOfResidence, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
    formField: false,
  },
];
