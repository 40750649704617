export const getAddressFields = () => [
  {
    id: "family-houseNameEng",
    name: "houseNameEng",
    label: "House Name (English)",
    type: "text",
    placeholder: "House Name (English)",
    required: true,
  },
  {
    id: "family-houseNameMal",
    name: "houseNameMal",
    label: "House Name (Malayalam)",
    type: "text",
    placeholder: "House Name (Malayalam)",
    required: true,
  },
  {
    id: "family-streetNameEng",
    name: "streetNameEng",
    label: "Street Name (English)",
    type: "text",
    placeholder: "Street Name (English)",
    required: true,
  },
  {
    id: "family-streetNameMal",
    name: "streetNameMal",
    label: "Street Name (Malayalam)",
    type: "text",
    placeholder: "Street Name (Malayalam)",
    required: true,
  },
];
