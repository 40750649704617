import { FC, useContext, useState, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { Tender } from "../../../../../shared/interfaces/tender";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import moment from "moment";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const TenderList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const [statusFilter, setStatusFilter] = useState<string>("All");
  const { loading, data, fetchData, error } = useQuery("admin/tender", {
    params: {
      ...payload,
      tenderStatus: statusFilter,
    },
  });
  useEffect(() => {
    if (!loading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  const tenderDisplayColumns = [
    "title",
    "startDate",
    "endDate",
    "status",
    "publishedBy",
  ];
  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    title: "title",
    status: `tenderStatus`,
    startDate: "startDate",
    endDate: "endDate",
    publishedBy: `"publishedByUser->admin"."fullName"`,
  };
  const sortableColumns = ["title"];

  let tenderList: Tender[] = [];
  if (data && (data as any)?.items) {
    tenderList = (data as any)?.items.map((item: Tender) => {
      return {
        id: item.id,
        title: item?.title,
        description: item?.description,
        startDate: moment(item?.startDate).format("DD/MM/YYYY"),
        endDate: moment(item?.endDate).format("DD/MM/YYYY"),
        status: item?.tenderStatus,
        publishedBy: item?.publishedByUser?.admin?.fullName,
        deleteButtonStatus: item?.tenderStatus === "Draft" ? false : true,
      };
    });
  }

  const onButtonClick = () => {
    navigate("/tender-management/tenders/create");
  };

  const titleBarNode = (
    <>
      <TitleBarSelectBox
        fieldProps={{
          id: "tender-filter",
          name: "tender-filter",
        }}
        value={statusFilter ?? "All"}
        onChange={(value: string) => setStatusFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: "Draft", label: "Draft" },
          { value: "Published", label: "Published" },
          { value: "Closed", label: "Closed" },
        ]}
      />
      <SearchBox />
    </>
  );
  const titleBar = {
    title: "Tenders",
    headerTitle: "Tenders",
    buttonTitle: "Create",
    onClick: onButtonClick,
    titleBarNode: titleBarNode,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Tenders-Create")
      : false,
  };
  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Tenders"
          data={tenderList}
          displayColumns={tenderDisplayColumns}
          sortableColumns={sortableColumns}
          sortableFields={sortFields}
          linkColumn="title"
          linkUrl="/tender-management/tenders"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/tender"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("Tenders-Delete")
              : false
          }
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};
export default TenderList;
