import FacilityCreate from "../components/facilityCreate";
import FacilityList from "../components/facilitiesList";
import FacilityEditView from "../components/facilityEditView";
import BusinessIcon from "@mui/icons-material/Business";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { Navigate } from "react-router-dom";
const facilityRoute = {
  icon: BusinessIcon,
  name: "Facility Management",
  link: "/facility-management",
  path: "facility-management",
  index: 40,
  element: "",
  children: [
    {
      path: "facilities",
      element: <FacilityList />,
      name: "Facilities",
      permission: "Facilities-Read",
      index: 0,
      link: "facility-management/facilities",
      icon: ClearAllIcon,
    },
    {
      path: "facilities/create",
      element: <FacilityCreate />,
    },
    {
      path: "facilities/:id",
      element: <FacilityEditView />,
    },
    {
      path: "",
      element: <Navigate to="facilities" replace />,
    },
  ],
};
export default facilityRoute;
