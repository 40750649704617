import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { Facility } from "../../../../../shared/interfaces/facility";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";

const FacilityList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const { loading, data, fetchData, error } = useQuery("admin/facility", {
    params: { ...payload },
  });

  const facilityData = data as any;
  const facilityList = facilityData?.items.map((item: Facility) => {
    return {
      id: item.id,
      name: item?.name,
      dailyRate: item?.dailyRate,
      availabilityStatus: item?.availabilityStatus,
      FacilityType: item?.typeOfFacility,
    };
  });
  const facilityDisplayColumns = [
    "name",
    "FacilityType",
    "dailyRate",
    "availabilityStatus",
  ];

  const sortFields = {
    name: `name`,
    FacilityType: `typeOfFacility`,
    // dailyRate: `dailyRate`,
    availabilityStatus: "availabilityStatus",
  };
  const sortableColumns = ["name"];

  const titleBar = {
    headerTitle: "Facilities",
    title: "Facilities",
    buttonTitle: "Create",
    titleBarNode: <SearchBox />,
    onClick: () => {
      navigate("/facility-management/facilities/create");
    },
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Facilities-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Facility"
          data={facilityList}
          displayColumns={facilityDisplayColumns}
          sortableColumns={sortableColumns}
          sortableFields={sortFields}
          linkColumn="name"
          linkUrl="/facility-management/facilities"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/facility"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("Facilities-Delete")
              : false
          }
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default FacilityList;
