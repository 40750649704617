import moment from "moment";
export const getFacilityBookingFields = (facilityBookingData: any) => [
  {
    id: "facilityType",
    label: "Facility Type :",
    value: facilityBookingData?.facility?.typeOfFacility,
    formField: false,
  },
  {
    id: "name",
    label: "Applicant Name :",
    value: facilityBookingData?.member
      ? facilityBookingData?.member?.fullNameEng
      : "Outsider",
    formField: false,
  },

  {
    id: "facilityName",
    label: "Facility Name :",
    value: facilityBookingData?.facility?.name,
    formField: false,
  },

  {
    label: "Start Date:",
    value: moment(facilityBookingData?.bookingStartDate).format("DD/MM/YYYY"),
    formField: false,
  },
  {
    label: "End Date:",
    value: moment(facilityBookingData?.bookingEndDate).format("DD/MM/YYYY"),
    formField: false,
  },
  {
    id: "bookingStatus",
    label: "Booking Status :",
    value: facilityBookingData?.bookingStatus,
    formField: false,
  },
  {
    id: "remarks",
    label: "Remarks :",
    value: facilityBookingData?.remarks,
    formField: false,
  },
];
