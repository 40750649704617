import { FC, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { LoadingButton } from "@mui/lab";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { getWelfareRequestFields } from "../../../welfare-requests/components/welfareRequestApproveView/welfareRequestFields";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";

const OngoingSchemeEdit: FC = () => {
  const { id } = useParams();

  const [openDialog, setOpenDialog] = useState(false);
  const [statusToChange, setStatusToChange] = useState<string | null>(null);

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/welfare-request/${id}`,
    null
  );

  const { error: saveError, modifyData } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const ongoingSchemetData = (data as any)?.data || {};
  const welfareRequestFields = getWelfareRequestFields(ongoingSchemetData);

  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Welfare request-Update")
    : true;
  const handleOpenDialog = (status: string) => {
    setStatusToChange(status);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setStatusToChange(null);
  };
  const viewTitleBar: TitleBarProps = {
    title: "View",
    headerTitle: "Ongoing Scheme",
  };
  const handleConfirmChange = async (schemeStatus: boolean) => {
    if (schemeStatus) {
      try {
        await modifyData(
          `/admin/welfare-request/ongoing-scheme-status/${id}`,
          "put",
          { welfareStatus: statusToChange },
          null
        );
        fetchData();
        handleCloseDialog();
      } catch (error) {
        console.error("Error updating the welfare request:", error);
      }
      if (saveError) {
        return;
      }
    } else {
      handleCloseDialog();
    }
  };

  const titleBarNode = (
    <>
      {ongoingSchemetData.welfareStatus === "Active" && editPermission && (
        <>
          <LoadingButton
            className="TitleBarButton"
            loading={false}
            variant="contained"
            size="small"
            onClick={() => handleOpenDialog("Onhold")}
          >
            Onhold
          </LoadingButton>
          <LoadingButton
            className="TitleBarButton"
            loading={false}
            variant="contained"
            size="small"
            onClick={() => handleOpenDialog("Cancel")}
          >
            Cancel
          </LoadingButton>
        </>
      )}

      {ongoingSchemetData.welfareStatus === "Onhold" && (
        <>
          <LoadingButton
            className="TitleBarButton"
            loading={false}
            variant="contained"
            size="small"
            onClick={() => handleOpenDialog("Active")}
          >
            Active
          </LoadingButton>
          <LoadingButton
            className="TitleBarButton"
            loading={false}
            variant="contained"
            size="small"
            onClick={() => handleOpenDialog("Cancel")}
          >
            Cancel
          </LoadingButton>
        </>
      )}
    </>
  );

  return (
    <>
      <ViewContainer
        loading={loading}
        titleBar={viewTitleBar}
        titleBarNode={titleBarNode}
      >
        <SingleEntityContainer
          entityFields={welfareRequestFields}
          entityTitle="Welfare Request Details"
        />
      </ViewContainer>
      {openDialog && (
        <ConfirmDialog
          openConfirmDialog={openDialog}
          setOpenConfirmDialog={handleCloseDialog}
          handleSubmit={handleConfirmChange}
          title={"Confirm"}
          message={"Are you sure you want to change the welfare status?"}
        />
      )}
    </>
  );
};

export default OngoingSchemeEdit;
