import "./styles.css";
import { FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { InputLabel } from "@mui/material";
import { ErrorMessage } from "../errorMessage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";

type DatePickerProps = {
  id?: string;
  name: string;
  format?: string;
  views?: any;
  label?: string;
  value?: Date | null;
  onChange?: (date: string | null) => void;
  required?: boolean;
  disableFuture?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  form?: any;
};
export const CustomDatePicker: FC<DatePickerProps> = ({
  id,
  name,
  format = "dd/MM/yyyy",
  views = ["year", "month", "day"],
  label,
  value,
  onChange,
  required,
  disableFuture,
  placeholder,
  fullWidth,
  form,
}) => {
  const handleDateChange = (newValue: any) => {
    const formattedTime = newValue
      ? moment(newValue).format("YYYY-MM-DD")
      : null;
    form.setFieldValue(name, formattedTime);
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <div className="datePickerContainer" id={id}>
      {label && (
        <InputLabel>
          {label}
          {required ? <span style={{ color: "red" }}> * </span> : ""}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={views}
          className="datePickerIcon"
          format={format}
          disableFuture={disableFuture}
          value={value}
          onChange={handleDateChange}
          slots={{ openPickerIcon: CalendarMonthIcon }}
          slotProps={{
            field: { clearable: true },
            textField: {
              error:
                (form?.touched as any)?.[name] && (form?.errors as any)?.[name],
              fullWidth,
              InputProps: {
                size: "medium",
                placeholder: placeholder,
                name: name,
              },
            },
          }}
        />
      </LocalizationProvider>
      <ErrorMessage
        message={
          ((form?.touched as any)?.[name] && (form?.errors as any)?.[name]) ??
          ""
        }
      />
    </div>
  );
};
