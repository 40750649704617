import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import VrpanoIcon from "@mui/icons-material/Vrpano";
import { Navigate } from "react-router-dom";
import BannerList from "../components/bannerList";
import Banner from "../components/bannerCreate";
import BannerEditView from "../components/bannerEditView";

const eventTypeRoute = {
  icon: VrpanoIcon,
  name: "Banner Management",
  link: "/banner-management",
  path: "banner-management",
  element: "",
  index: 120,
  children: [
    {
      path: "banners",
      element: <BannerList />,
      name: "Banners",
      permission: "Banner-Read", // Permission name
      index: 0,
      link: "banner-management/banners",
      icon: ViewCarouselIcon,
    },
    {
      path: "banners/create",
      element: <Banner />,
    },
    {
      path: "banners/:id",
      element: <BannerEditView />,
    },

    {
      path: "",
      element: <Navigate to="banners" replace />,
    },
  ],
};
export default eventTypeRoute;
