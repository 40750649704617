import moment from "moment";
const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const formatDate = (date: any) => {
  return isoRegex.test(date);
};

export const getFamilyEditFields = (familyData: any, familyHead: any) => [
  {
    label: "Family Code:",
    value: familyData?.familyCode,
    formField: false,
  },
  {
    id: "annualIncome",
    name: "annualIncome",
    label: "Annual Income:",
    value:
      familyData?.annualIncome === "" || familyData?.annualIncome === null
        ? "Not Available"
        : familyData?.annualIncome,
    type: "text",
    placeholder: "Enter your Annual Income",
    required: false,
  },
  {
    id: "rationCardNumber",
    name: "rationCardNumber",
    label: "Ration Card Number:",
    value:
      familyData?.rationCardNumber === "" ||
      familyData?.rationCardNumber === null
        ? "Not Available"
        : familyData?.rationCardNumber,
    type: "text",
    placeholder: "Enter your ration card number",
    required: false,
  },
  {
    label: "Ration Card Type:",
    value: familyData?.rationCardType ?? "Not Available",
    formField: false,
  },
  {
    label: "Date Of Joining:",
    value: formatDate(familyData?.joinedDate)
      ? moment(familyData?.joinedDate).format("DD/MM/YYYY")
      : moment(familyData?.joinedDate, "DD/MM/YYYY").format("DD/MM/YYYY"),
    formField: false,
  },
  {
    label: "Head:",
    value: familyHead?.fullNameEng ?? "Not Available",
    formField: false,
  },
  {
    label: "Kudumba Unit:",
    value: familyData?.kudumbaUnitNameMal ?? "Not Available",
    formField: false,
  },
  {
    label: "Micro Finance:",
    value:
      familyData?.microFinanceNameMal !== "" ||
      familyData?.microFinanceNameMal !== null
        ? familyData?.microFinanceNameMal
        : "Not Available",
    formField: false,
  },
  {
    label: "Own Land:",
    value: familyData?.ownLand ? "Yes" : "No",
    formField: false,
  },

  // {
  //   label: "Date of Birth:",
  //   value: moment(memberRequestData?.dob).format("DD/MM/YYYY"),
  //   formField: false,
  // },
];
