import { FC, useContext, useState, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { FacilityBooking } from "../../../../../shared/interfaces/facility-booking";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import moment from "moment";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const FacilityBookingList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const [statusFilter, setStatusFilter] = useState<string | null>("All");
  const { loading, data, fetchData, error } = useQuery(
    "admin/facility-booking",
    {
      params: {
        ...payload,
        bookingStatus: statusFilter === "All" ? null : statusFilter,
      },
    }
  );

  const onButtonClick = () => {
    navigate("/facility-management/facility-bookings/create");
  };

  const facilityBookingDisplayColumns = [
    "applicantName",
    "facilty",
    "startDate",
    "endDate",
    "bookingStatus",
  ];

  let facilityBookingList: FacilityBooking[] = [];
  if (data && (data as any)?.items) {
    facilityBookingList = (data as any)?.items.map((item: FacilityBooking) => {
      return {
        id: item.id,
        applicantName: item?.member ? item?.member?.fullNameEng : "Outsider",
        facilty: item?.facility?.name,
        bookingStatus: item?.bookingStatus,
        startDate: moment(item?.bookingStartDate).format("DD/MM/YYYY"),
        endDate: moment(item?.bookingEndDate).format("DD/MM/YYYY"),
        remarks: item?.remarks,
      };
    });
  }

  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    facilty: `"facility"."name"`,
    bookingStatus: `bookingStatus`,
    startDate: "bookingStartDate",
    endDate: "bookingEndDate",
  };

  useEffect(() => {
    if (statusFilter) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);
  const sortableColumns = ["applicantName"];

  const titleBarNode = (
    <>
      <TitleBarSelectBox
        fieldProps={{
          id: "booking-filter",
          name: "booking-filter",
        }}
        value={statusFilter ?? "All"}
        onChange={(value: string) => setStatusFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: "Pending", label: "Pending" },
          { value: "Approved", label: "Approved" },
          { value: "Rejected", label: "Rejected" },
          { value: "Cancelled", label: "Cancelled" },
        ]}
      />
      <SearchBox />
    </>
  );

  const titleBar = {
    headerTitle: "Facility Bookings",
    title: "Facility Bookings",
    titleBarNode: titleBarNode,
    buttonTitle: "Create",
    onClick: onButtonClick,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Facility Bookings-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Facility Bookings"
          data={facilityBookingList}
          displayColumns={facilityBookingDisplayColumns}
          sortableColumns={sortableColumns}
          sortableFields={sortFields}
          linkColumn="name"
          linkUrl="/facility-management/facility-bookings"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/facility-booking"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes(
                  "Facility Bookings-Delete"
                )
              : false
          }
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default FacilityBookingList;
