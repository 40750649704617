import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { FC } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import {
  PayoutTypeOptions,
  WelfareCategoryOptions,
  WelfareStatusOptions,
  WelfareTypeOptions,
} from "../../../../../shared/constants/selectOptions";

const CreateWelfare: FC = () => {
  const { loading: apiLoading, modifyData } = useMutation(
    "/welfare-schemes/welfare"
  );

  const welfareForm = useFormik({
    initialValues: {
      welfareCategory: null,
      schemeName: "",
      type: null,
      allotedAmount: "",
      payoutType: null,
      nameOfMaterial: "",
      count: "",
      description: "",
      status: null,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "welfareCategory",
      "type",
      "schemeName",
      "status",
      "allotedAmount",
    ]),
    onSubmit: async (formData: any) => {
      modifyData(
        `admin/welfare-schemes`,
        "post",
        {
          ...formData,
        },
        welfareForm
      );
    },
  });

  const titleBar = {
    title: "Create Welfare Scheme",
    buttonTitle: "Save",
    headerTitle: "Welfare Scheme",
    onClick: () => welfareForm.handleSubmit(),
    dirty: welfareForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12} key="welfare-category">
            <ZSSelectBox
              fieldProps={{
                label: "Category",
                id: "welfareCategory",
                name: "welfareCategory",
                placeholder: "Select an option",
                required: true,
              }}
              options={WelfareCategoryOptions}
              form={welfareForm}
              value={welfareForm.values?.welfareCategory}
              errorMessage={
                ((welfareForm.touched as any)?.welfareCategory &&
                  (welfareForm.errors as any)?.welfareCategory) ??
                null
              }
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={welfareForm}
              fieldProps={{
                id: "schemeName",
                name: "schemeName",
                label: "Scheme Name",
                type: "text",
                placeholder: "Enter Scheme Name",
                required: true,
                capitalizedFirst: true,
              }}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12} key="welfare-type">
            <ZSSelectBox
              fieldProps={{
                label: "Welfare Type",
                id: "type",
                name: "type",
                placeholder: "Select an option",
                required: true,
              }}
              options={WelfareTypeOptions}
              form={welfareForm}
              value={welfareForm.values?.type}
              errorMessage={
                ((welfareForm.touched as any)?.type &&
                  (welfareForm.errors as any)?.type) ??
                null
              }
            />
          </Grid>
          {welfareForm.values?.type === "Financial" ? (
            <>
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={welfareForm}
                  fieldProps={{
                    id: "allotedAmount",
                    name: "allotedAmount",
                    label: "Alloted amount",
                    type: "text",
                    placeholder: "Enter Alloted amount",
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12} key="welfare-payout">
                <ZSSelectBox
                  fieldProps={{
                    label: "Payout Type",
                    id: "payoutType",
                    name: "payoutType",
                    placeholder: "Select an option",
                    required: true,
                  }}
                  options={PayoutTypeOptions}
                  form={welfareForm}
                  value={welfareForm.values?.payoutType}
                  errorMessage={
                    ((welfareForm.touched as any)?.payoutType &&
                      (welfareForm.errors as any)?.payoutType) ??
                    null
                  }
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={welfareForm}
                  fieldProps={{
                    id: "nameOfMaterial",
                    name: "nameOfMaterial",
                    label: "Name of Material",
                    type: "text",
                    placeholder: "Enter Name of Material",

                    capitalizedFirst: true,
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={welfareForm}
                  fieldProps={{
                    id: "count",
                    name: "count",
                    label: "Count",
                    type: "text",
                    placeholder: "Enter Count",
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item md={6} sm={6} xs={12} key="welfare-status">
            <ZSSelectBox
              fieldProps={{
                label: "Status",
                id: "status",
                name: "status",
                placeholder: "Select an option",
                required: true,
              }}
              options={WelfareStatusOptions}
              form={welfareForm}
              value={welfareForm.values?.status}
              errorMessage={
                ((welfareForm.touched as any)?.status &&
                  (welfareForm.errors as any)?.status) ??
                null
              }
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={welfareForm}
              multiline
              fieldProps={{
                id: "description",
                name: "description",
                label: "Description",
                type: "text",
                maxLength: 255,
                required: false,
                placeholder: "Enter description",
                capitalizedFirst: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </FormContainer>
  );
};
export default CreateWelfare;
