import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { Family } from "../../../../../shared/interfaces/family";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";

const FamilyList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const { loading, data, fetchData, error } = useQuery("/admin/family", {
    params: { ...payload },
  });
  const onButtonClick = () => {
    navigate("/branchMembership/families/create");
  };

  const familyDisplayColumns = [
    "code",
    "familyHead",
    "houseName",
    "houseNo",
    "streetName",
    "kudumbaUnit",
  ];

  let familyList: Family[] = [];
  if (data && (data as any)?.items) {
    familyList = (data as any)?.items.map((item: Family) => {
      return {
        id: item.id,
        code: item?.familyCode,
        houseNo: item?.addressDetails?.houseNo,
        streetName: item?.addressDetails?.streetNameEng,
        houseName: item?.addressDetails?.houseNameEng,
        kudumbaUnit: item?.kudumbaUnitNameEng,
        familyHead: item?.familyHeadDetails?.fullNameEng,
      };
    });
  }
  const sortableColumns: string[] = [];
  const sortFields = {
    code: "familyCode",
    houseName: "addressDetails.houseNameEng",
    familyHead: "familyHeadDetails.fullNameEng",
  };

  const titleBarNode = <SearchBox />;

  const titleBar = {
    title: "Families",
    headerTitle: "Families",
    buttonTitle: "Create",
    onClick: onButtonClick,
    titleBarNode: titleBarNode,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Family-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Family"
          data={familyList}
          displayColumns={familyDisplayColumns}
          sortableColumns={sortableColumns}
          sortableFields={sortFields}
          linkColumn="houseNameEng"
          linkUrl="/branchMembership/families"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/family"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("Family-Delete")
              : false
          }
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default FamilyList;
