import * as yup from "yup";
import { getValidationSchema } from "../../../../../shared/validations/schema";
export const customizeValidationSchema = () => {
  let defaultSchema: any = getValidationSchema([
    "fullNameEng",
    "fullNameMal",
    "gender",
    "dob",
    "familyID",
    "phone",
  ]);
  let customSchema = yup.object().shape({
    email: yup
      .string()
      .trim("Email address cannot include leading and trailing spaces")
      .strict(true)
      .email("Invalid Email address format")
      .min(2, "Email address must be more than 2 characters")
      .max(50, "Email address cannot exceed more than 30 characters")
      .nullable(),
  });
  return defaultSchema.concat(customSchema);
};
