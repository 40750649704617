/* eslint-disable react-hooks/exhaustive-deps */

import { useFormik } from "formik";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { FC, useState } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { CustomDatePicker } from "../../../../../shared/components/customDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";

const TenderCreate: FC = () => {
  const { loading: apiLoading, modifyData } = useMutation(
    "/tender-management/tenders"
  );
  const [editorInitialized, setEditorInitialized] = useState<boolean>(false);
  const [textContent, setTextContent] = useState<{ type: String; value: any }>({
    type: "",
    value: "",
  });

  const today = moment().startOf("day");

  const tenderForm = useFormik({
    initialValues: {
      title: "",
      startDate: null,
      endDate: null,
      description: "",
      tenderStatus: "Draft",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "title",
      "startDate",
      "endDate",
      "description",
    ]),
    validate: (values) => {
      const errors: any = {};
      if (values.startDate && moment(values.startDate).isBefore(today)) {
        errors.startDate = "Start date cannot be in the past";
      }
      if (values.endDate && moment(values.endDate).isBefore(today)) {
        errors.endDate = "End date cannot be in the past";
      }
      if (values.endDate && moment(values.endDate).isBefore(values.startDate)) {
        errors.endDate = "End date cannot be before start date";
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("endDate", formData.endDate);
      formDataToSend.append("startDate", formData.startDate);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("tenderStatus", formData.tenderStatus);
      formDataToSend.append("file", textContent.value);
      modifyData(`/admin/tender`, "post", formDataToSend, config);
    },
  });
  const handleDateChange = (newValue: string | null, fieldName: string) => {
    const newDate = newValue ? moment(newValue).format("YYYY-MM-DD") : null;
    tenderForm.setFieldValue(fieldName, newDate);
  };

  const closeTenderEdit = () => {
    tenderForm.resetForm();
    window.history.back();
  };
  const titleBar = {
    title: "Create Tender",
    buttonTitle: "Save",
    headerTitle: "Tender",
    onClick: () => tenderForm.handleSubmit(),
    dirty: tenderForm.dirty,
    buttonLoading: apiLoading,
  };

  const titleBarNode = (
    <IconButton
      className="TitleBarButton"
      size="medium"
      id={`tender-edit`}
      onClick={closeTenderEdit}
      aria-label={`tender-edit`}
    >
      <CloseIcon />
    </IconButton>
  );

  const handleEditorChange = (content: any) => {
    if (editorInitialized) {
      tenderForm.setValues((prev: any) => ({
        ...prev,
        description: content,
      }));
    }
  };

  return (
    <FormContainer titleBar={titleBar} titleBarNode={titleBarNode}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12} key="tender-title">
            <InputTextfield
              form={tenderForm}
              fieldProps={{
                id: "tender-title",
                name: "title",
                label: "Title",
                type: "text",
                placeholder: "Title",
                required: true,
              }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} key="tender-description">
            <Editor
              apiKey={process.env.REACT_APP_EDITOR_API_KEY}
              init={{
                skin: "borderless",
                height: 400,
                min_height: 200,
                statusbar: true,
                branding: false,
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "blocks bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              onInit={() => setEditorInitialized(true)}
              value={tenderForm?.values?.description}
              onEditorChange={(content: any) => handleEditorChange(content)}
              onBlur={() => tenderForm.setFieldTouched("description", true)}
            />
            {/* <InputTextfield
              form={tenderForm}
              multiline={true}
              fieldProps={{
                id: "description",
                name: "description",
                label: "Description",
                type: "text",
                placeholder: "Description",
                capitalizedFirst: true,
                maxLength: 255,
                required: true,
              }}
            /> */}
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="tender-startDate">
            <CustomDatePicker
              onChange={(date: any) => handleDateChange(date, "startDate")}
              placeholder="Start Date"
              label="Start Date"
              name="startDate"
              required={true}
              value={
                tenderForm.values.startDate
                  ? new Date(tenderForm.values.startDate)
                  : null
              }
              form={tenderForm}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="tender-endDate">
            <CustomDatePicker
              onChange={(date: any) => handleDateChange(date, "endDate")}
              placeholder="End Date"
              label="End Date"
              required={true}
              name="endDate"
              value={
                tenderForm.values.endDate
                  ? new Date(tenderForm.values.endDate)
                  : null
              }
              form={tenderForm}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="tender-file">
            <InputLabel>Upload File</InputLabel>
            <OutlinedInput
              size="small"
              fullWidth
              id="outlined-basic"
              inputProps={{
                accept: [".pdf, image/*"],
              }}
              type={"file"}
              className="fileInput"
              onChange={(event: any) => {
                setTextContent((prev: any) => ({
                  ...prev,
                  value: event.target.files[0],
                }));
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </FormContainer>
  );
};
export default TenderCreate;
