import React, { useState } from "react";
import { Alert, Box, Grid, IconButton, Tooltip } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useFormik } from "formik";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { previewValidationSchema } from "./customSchema";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import { LoadingButton } from "@mui/lab";
import { CertificateRequestStatus } from "../../../../../shared/enum";
import CloseIcon from "@mui/icons-material/Close";
import InputTextfield from "../../../../../shared/components/textField";
import { dateInputFromatHelper } from "../../../../../shared/utils/date-helper";
import { ErrorMessage } from "../../../../../shared/components/errorMessage";

type QuillEditorProps = {
  fetchFieldData: Function;
  confirmDetailStatus?: boolean;
  fieldDataLoading?: boolean;
  previewContent: {
    certificateNumber: string;
    issueDate: string;
    editorValue: any;
    status?: boolean;
  };
  id?: string;
  setOpenConfirmationModalData: Function;
  setTextContent: Function;
  certificateTypeName?: string;
  certificatePrintPermission?: boolean;
  certificateUploadPermission?: boolean;
  certificateLanguage?: string;
  setPreviewContent: Function;
};

const CertificatePreview: React.FC<QuillEditorProps> = ({
  confirmDetailStatus,
  previewContent,
  fieldDataLoading,
  fetchFieldData,
  id,
  setOpenConfirmationModalData,
  setTextContent,
  certificateTypeName,
  certificatePrintPermission,
  certificateUploadPermission,
  certificateLanguage,
  setPreviewContent,
}) => {
  const {
    loading: saveApiLoading,
    error: saveError,
    modifyData,
  } = useMutation();
  const [editorInitialized, setEditorInitialized] = useState<boolean>(false);
  const certificatePreviewForm = useFormik({
    initialValues: previewContent,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: previewValidationSchema(Object.keys(previewContent)),
    onSubmit: async () => {
      handleTemplateSave();
    },
  });

  const handleTemplateSave = async () => {
    const inputDate = certificatePreviewForm?.values?.issueDate;
    const [day, month, year] = inputDate?.split("/");
    const date = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
    const response = await modifyData(`/admin/certificate/${id}`, "put", {
      editorValue: certificatePreviewForm?.values?.editorValue,
      certificateNumber: certificatePreviewForm?.values?.certificateNumber,
      issueDate: date,
    });
    if (saveError) {
      return;
    }
    if (response) {
      fetchFieldData();
    }
  };
  const handlePrintCertificate = async () => {
    const response = await modifyData(`/admin/certificate/print/${id}`, "put");
    if (saveError) {
      return;
    }
    const byteArray = new Uint8Array(response.data.data);
    const arrayBuffer = byteArray.buffer;
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "certificate.pdf");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    fetchFieldData();
  };

  const previewTitleBarNode = (
    <>
      {certificatePrintPermission && (
        <LoadingButton
          className="TitleBarButton"
          loading={false}
          variant="contained"
          size="small"
          id="member-request-reject"
          disabled={
            confirmDetailStatus ||
            Object.keys(certificatePreviewForm.errors).length !== 0 ||
            certificatePreviewForm.dirty
          }
          onClick={() => handlePrintCertificate()}
        >
          Print
        </LoadingButton>
      )}
      {certificateUploadPermission && (
        <LoadingButton
          className="TitleBarButton"
          loading={false}
          variant="contained"
          size="small"
          id="member-request-approve"
          disabled={
            confirmDetailStatus ||
            Object.keys(certificatePreviewForm.errors).length !== 0 ||
            certificatePreviewForm.dirty
          }
          onClick={() => {
            setOpenConfirmationModalData({
              status: true,
              message: "Are you sure you want to upload this application ?",
              requestStatus: CertificateRequestStatus.Generated,
            });
            setTextContent((prev: any) => ({
              ...prev,
              type: "file",
            }));
          }}
        >
          Confirm
        </LoadingButton>
      )}
      <Tooltip arrow title="Cancel">
        <IconButton
          className="TitleBarButton"
          size="medium"
          id={`request-edit`}
          onClick={() => {
            setPreviewContent((prev: any) => ({
              ...prev,
              status: false,
            }));
          }}
          aria-label={`request-edit`}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );
  const editPreviewTitlebar: TitleBarProps = {
    title: `Generate Certificate: ${certificateTypeName} (${certificateLanguage})`,
    headerTitle: "Applications",
    buttonTitle: "Save",
    onClick: () => {
      certificatePreviewForm.handleSubmit();
    },
    dirty: certificatePreviewForm.dirty,
    buttonLoading: fieldDataLoading,
  };

  const handleEditorChange = (content: any) => {
    if (editorInitialized) {
      certificatePreviewForm.setValues((prev: any) => ({
        ...prev,
        editorValue: content,
      }));
    }
  };

  const handleDateChange = (newValue: string) => {
    const formattedDate = dateInputFromatHelper(
      newValue,
      certificatePreviewForm
    );
    certificatePreviewForm.setFieldValue("issueDate", formattedDate);
  };
  return (
    <>
      <ViewContainer>
        <FormContainer
          loading={saveApiLoading}
          titleBar={editPreviewTitlebar}
          titleBarNode={previewTitleBarNode}
        >
          <Alert severity="warning" icon={false}>
            Certificate Number:{" "}
            {certificatePreviewForm.values.certificateNumber}
          </Alert>
          <Box className="border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={2}
            >
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={certificatePreviewForm}
                  value={certificatePreviewForm?.values?.issueDate || ""}
                  fieldProps={{
                    label: "Issue date",
                    id: "issueDate",
                    name: "issueDate",
                    type: "text",
                    required: true,
                    placeholder: "DD/MM/YYYY",
                  }}
                  onChange={(newValue: any) => handleDateChange(newValue)}
                />
              </Grid>
            </Grid>
            <Box mt={1} mb={1.5}>
              <Editor
                apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                init={{
                  skin: "borderless",
                  height: 400,
                  min_height: 200,
                  statusbar: true,
                  branding: false,
                  menubar: true,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "blocks bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                onInit={() => setEditorInitialized(true)}
                value={certificatePreviewForm?.values?.editorValue}
                onEditorChange={(content) => handleEditorChange(content)}
                onBlur={() =>
                  certificatePreviewForm.setFieldTouched("editorValue", true)
                }
              />
            </Box>
            <ErrorMessage
              message={
                (certificatePreviewForm?.touched as any)?.editorValue &&
                (certificatePreviewForm?.errors as any)?.editorValue
                  ? (certificatePreviewForm?.errors as any)?.editorValue
                  : null
              }
            />
          </Box>
        </FormContainer>
      </ViewContainer>
    </>
  );
};

export default CertificatePreview;
