import MembershipList from "../components/membershipList";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import GroupsIcon from "@mui/icons-material/Groups";
const membershipRoute = {
  icon: GroupsIcon,
  name: "Yogam Membership",
  link: "/yogam-membership",
  path: "yogam-membership",
  element: "",
  index: 110,
  children: [
    {
      icon: LibraryBooksIcon,
      name: "Eligible Members",
      path: "/yogam-membership/eligible-members",
      link: "yogam-membership/eligible-members",
      index: 1,
      permission: "Eligible Members-Read",
      element: <MembershipList />,
    },
  ],
};
export default membershipRoute;
