import { FC, ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./style.css";

interface SidebarLayoutProps {
  children?: ReactNode;
}
const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Sidebar />
        <Box
          sx={{
            [theme.breakpoints.up("lg")]: {
              ml: `${theme.sidebar.width}`,
            },
          }}
        >
          <Box display="block" className="sticky-div">
            <Header />
          </Box>
          <Box display="block" className="content">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
