import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import { Member } from "../../../../../shared/interfaces/member";

const MemberUserList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const { loading, data, fetchData, error } = useQuery(
    "/admin/members/member-users",
    {
      params: {
        ...payload,
      },
    }
  );
  const memberData: any = data as any;
  const memberList = memberData?.items.map((item: Member) => {
    return {
      id: item.id,
      fullName: item?.fullNameEng,
      email: item?.email,
      phone: item?.phone,
    };
  });
  const displayColumns = ["fullName", "email", "phone"];
  const titleBarNode = <SearchBox />;
  const titleBar = {
    title: "Members",
    headerTitle: "Members",
    titleBarNode: titleBarNode,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Members"
          data={memberList}
          displayColumns={displayColumns}
          linkColumn="fullName"
          linkUrl="/userManagement/memberUsers"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default MemberUserList;
