import { FC, useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import moment from "moment";
import { useFormik } from "formik";

import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import { RequestStatus } from "../../../../../shared/enum";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import { useNavigate } from "react-router-dom";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { LoadingButton } from "@mui/lab";
// import CloseIcon from "@mui/icons-material/Close";
import {
  dateInputFromatHelper,
  dateToISOString,
} from "../../../../../shared/utils/date-helper";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { genderOptions } from "../../../../../shared/constants/selectOptions";
import { getMemberRequestFields } from "./memberRequestFields";
import { getFamilyFields } from "./familyFields";
import { customizeValidationSchema } from "./customSchema";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import { SnackBarAlertContext } from "../../../../../contexts/SnackbarContext";

const MemberRequestEditView: FC = () => {
  const snackBarAlertContext = useContext(SnackBarAlertContext);

  const navigate = useNavigate();
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);
  const [dialogBoxloading, setDialogBoxloading] = useState(false);
  const [openConfirmationModalData, setOpenConfirmationModalData] = useState({
    status: false,
    message: "",
    requestStatus: "",
  });
  const [textContent, setTextContent] = useState<{ type: String; value: any }>({
    type: "",
    value: "",
  });

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/membership-request/${id}`,
    null
  );

  const {
    loading: saveApiLoading,
    error: saveError,
    modifyData,
  } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Membership Request-Update")
    : true;

  const approvePermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Membership Request-Approve")
    : true;

  const memberRequestData = (data as any)?.data || {};

  const approvalStatus =
    memberRequestData?.requestStatus === RequestStatus.Approved ||
    memberRequestData?.requestStatus === RequestStatus.Rejected;

  const familyFields = getFamilyFields(memberRequestData);
  const memberRequestFields = getMemberRequestFields(memberRequestData);

  const handleOpenConfirmDialog = (message: string, requestStatus: string) => {
    if (!memberRequestForm.values.fullNameMal) {
      snackBarAlertContext.set({
        status: true,
        type: "error",
        message: "Name in malayalam is required",
      });
    } else {
      setOpenConfirmationModalData({
        status: true,
        message: message,
        requestStatus: requestStatus,
      });
      setTextContent((prev: any) => ({
        ...prev,
        type: "text",
      }));
    }
  };
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  const formatDate = (date: any) => {
    return isoRegex.test(date);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmationModalData({
      status: false,
      message: "",
      requestStatus: "",
    });
    setDialogBoxloading(false);
    setTextContent((prev: any) => ({
      type: "",
      value: "",
    }));
  };

  const memberRequestForm = useFormik({
    initialValues: {
      familyID: memberRequestData?.familyID,
      fullNameEng: memberRequestData?.fullNameEng,
      fullNameMal: memberRequestData?.fullNameMal ?? "",
      relationWithFamilyHead: memberRequestData?.relationWithFamilyHead,
      dob: formatDate(memberRequestData?.dob)
        ? moment(memberRequestData.dob).format("DD/MM/YYYY")
        : moment(memberRequestData.dob, "YYYY-MM-DD").format("DD/MM/YYYY"),
      email: memberRequestData?.email,
      phone: memberRequestData?.phone,
      gender: memberRequestData?.gender,
      remarks: memberRequestData?.remarks,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: customizeValidationSchema,
    onSubmit: async (formData: any, { resetForm }) => {
      const response = await modifyData(
        `/admin/membership-request/${id}`,
        "put",
        {
          ...formData,
          dob: dateToISOString(formData.dob),
        },
        memberRequestForm
      );
      if (saveError) {
        return;
      }
      if (response) {
        resetForm();
        fetchData();

        setEditStatus(false);
      }
    },
  });

  const handleEdit = () => {
    setEditStatus(!editStatus);
  };

  const handleDobChange = (newValue: string) => {
    const formatedDate = dateInputFromatHelper(newValue, memberRequestForm);
    memberRequestForm.setFieldValue("dob", formatedDate);
  };

  const viewTitleBar: TitleBarProps = {
    title: "Verification",
    headerTitle: "Membership Request",
  };

  const editTitlebar: TitleBarProps = {
    title: "Edit Membership Request",
    headerTitle: "Membership Request - Edit",
    buttonTitle: "Save",
    onClick: () => memberRequestForm.handleSubmit(),
    dirty: memberRequestForm.dirty,
    buttonLoading: loading,
  };

  const titleBarNode = approvePermission && !approvalStatus && (
    <>
      <LoadingButton
        className="TitleBarButton"
        loading={false}
        variant="contained"
        size="small"
        id="member-request-approve"
        onClick={() =>
          handleOpenConfirmDialog(
            "Are you sure you want to approve the member request?",
            RequestStatus.Approved
          )
        }
      >
        Approve
      </LoadingButton>
      <LoadingButton
        className="TitleBarButton"
        loading={false}
        variant="contained"
        size="small"
        id="member-request-reject"
        onClick={() =>
          handleOpenConfirmDialog(
            "Are you sure you want to reject the member request?",
            RequestStatus.Rejected
          )
        }
      >
        Reject
      </LoadingButton>
    </>
  );

  const handleStatusChange = async () => {
    try {
      const response = await modifyData(
        `/admin/membership-request/approval/${id}`,
        "put",
        {
          ...memberRequestForm.values,
          dob: dateToISOString(memberRequestForm.values.dob),
          requestStatus: openConfirmationModalData.requestStatus,
          remarks: textContent.value,
        },
        memberRequestForm
      );
      if (response) {
        memberRequestForm.resetForm();
        fetchData();
        navigate("/branchMembership/membership-requests");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmAction = async (responseStatus: boolean) => {
    if (responseStatus) {
      await handleStatusChange();
    }
    handleCloseConfirmDialog();
  };

  useEffect(() => {
    if (memberRequestForm.errors.email) {
      snackBarAlertContext.set({
        status: true,
        type: "error",
        message: memberRequestForm.errors.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRequestForm.errors.email]);

  useEffect(() => {
    if (memberRequestForm.errors.fullNameMal) {
      snackBarAlertContext.set({
        status: true,
        type: "error",
        message: memberRequestForm.errors.fullNameMal,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRequestForm.errors.fullNameMal]);

  const requestTooltipNode = editPermission && !approvalStatus && (
    <IconButton
      className="TitleBarIcon"
      size="medium"
      id={`request-edit`}
      onClick={handleEdit}
      aria-label={`request-edit`}
    >
      <EditIcon />
    </IconButton>
  );

  return (
    <>
      {editStatus ? (
        <>
          <FormContainer loading={saveApiLoading} titleBar={editTitlebar}>
            <Box className="border-custom">
              <Grid
                container
                alignItems="stretch"
                columnSpacing={4}
                rowSpacing={2}
              >
                {memberRequestFields.map(
                  (field: any, index: number) =>
                    (field?.formField === undefined ||
                      field.formField === true) && (
                      <Grid item md={6} sm={6} xs={12} key={field.id}>
                        <InputTextfield
                          form={memberRequestForm}
                          fieldProps={field}
                          key={index}
                        />
                      </Grid>
                    )
                )}
                <Grid item md={6} sm={6} xs={12} key="Dob">
                  <InputTextfield
                    form={memberRequestForm}
                    fieldProps={{
                      id: "dob",
                      label: "Date of Birth",
                      name: "dob",
                      type: "text",
                      placeholder: "Enter your date of birth",
                      required: true,
                    }}
                    onChange={handleDobChange}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} key="gender">
                  <ZSSelectBox
                    fieldProps={{
                      label: "Gender",
                      required: true,
                      id: "gender",
                      name: "gender",
                      placeholder: "Select Gender",
                    }}
                    options={genderOptions}
                    form={memberRequestForm}
                    value={memberRequestForm.values?.gender}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormContainer>
        </>
      ) : (
        <ViewContainer
          loading={loading}
          titleBar={viewTitleBar}
          titleBarNode={titleBarNode}
        >
          <SingleEntityContainer
            entityFields={memberRequestFields}
            entityTitle="Membership Request Details"
            tooltips={requestTooltipNode}
          ></SingleEntityContainer>
          <SingleEntityContainer
            entityFields={familyFields}
            entityTitle="Family Details"
          ></SingleEntityContainer>
          <ConfirmDialog
            title={
              openConfirmationModalData?.requestStatus ===
              RequestStatus.Rejected
                ? "Confirm Rejection"
                : "Confirmation"
            }
            openConfirmDialog={openConfirmationModalData?.status}
            setOpenConfirmDialog={handleCloseConfirmDialog}
            message={openConfirmationModalData.message}
            handleSubmit={handleConfirmAction}
            loading={dialogBoxloading}
            setTextContent={setTextContent}
            label={"Remarks"}
            required={true}
            buttonText={
              openConfirmationModalData?.requestStatus ===
              RequestStatus.Rejected
                ? "Reject"
                : "Approve"
            }
            textContent={textContent}
          />
        </ViewContainer>
      )}
    </>
  );
};

export default MemberRequestEditView;
