/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import { FC } from "react";
import { Member } from "../../../../../shared/interfaces/member";

interface VehicleDetails {
  memberData: Member;
}
const VehicleList: FC<VehicleDetails> = ({ memberData }) => {
  return (
    <Container className="tableListContainer">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Registration Number</TableCell>
              <TableCell>Vehicle Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberData?.vehicles?.map((data: any) => (
              <TableRow>
                <TableCell>{data.regNumber ?? "No Data"}</TableCell>
                <TableCell>{data.vehicleType ?? "No Data"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default VehicleList;
