import "./styles.css";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import useMutation from "../../../../shared/components/hooks/useMutation";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../../../../shared/utils/localStorage";
import { useNavigate } from "react-router-dom";
const OtpVerification = (props: any) => {
  const numberOfDigits = 6;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference: any = useRef([]);
  const navigate = useNavigate();
  const { loading, modifyData } = useMutation();
  // const [timer, setTimer] = useState({ minutes: 2, seconds: 0 });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (timer.seconds > 0) {
  //       setTimer((prevData: any) => {
  //         return { ...prevData, seconds: prevData.seconds - 1 };
  //       });
  //     }

  //     if (timer.seconds === 0) {
  //       if (timer.minutes === 0) {
  //         clearInterval(interval);
  //       } else {
  //         setTimer((prevData: any) => {
  //           return { minutes: prevData.minutes - 1, seconds: 59 };
  //         });
  //       }
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [timer.seconds]);

  const handleChange = (value: any, index: any) => {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const handleBackspaceAndEnter = (e: any, index: any) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "ArrowRight" && otpBoxReference.current[index + 1]) {
      otpBoxReference.current[index + 1].focus();
    }
    if (e.key === "ArrowLeft" && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !(
        (event.key >= "0" && event.key <= "9") ||
        ["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key)
      )
    ) {
      event.preventDefault();
    }
  };

  const handleSubmit = async (value: string) => {
    const response = await modifyData(`/auth/verify-otp`, "post", {
      otp: value,
    });
    if (response) {
      setLocalStorageData("userData", response?.data);
      navigate("dashboard");
    }
  };

  const key = getLocalStorageData("token");

  return (
    // <Container>
    <Grid container>
      <Grid item xs={6} className="otpVerificationImage">
        <img src={props?.qrCodeSrc} alt="otpVerificationImage" />
        <Typography>{key}</Typography>
      </Grid>
      <Grid item xs={6} className="otpVerification">
        <Box>
          <Grid container spacing={3}>
            {otp.map((digit, index) => {
              return (
                <Grid item xs={2} key={index}>
                  <TextField
                    autoComplete="off"
                    value={digit}
                    // type="number"
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                    onKeyDown={handleKeyDown}
                    inputRef={(reference) => {
                      return (otpBoxReference.current[index] = reference);
                    }}
                    InputProps={{
                      inputProps: { maxLength: 1 },
                      sx: {
                        "& input": {
                          textAlign: "center",
                        },
                      },
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <LoadingButton
                className="submitButton"
                loading={loading}
                variant="contained"
                onClick={() => handleSubmit(otp.join(""))}
                fullWidth
                size="small"
                disabled={otp.every((digit) => digit !== "") ? false : true}
              >
                Verify OTP
              </LoadingButton>
            </Grid>
            {/* <Grid item md={6}>
                <InputLabel className="otpTimerLabel">
                  {timer.seconds > 0 || timer.minutes > 0
                    ? `Time Remaining: ${
                        timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes
                      }:${
                        timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds
                      }`
                    : "Didn't receive code?"}
                </InputLabel>
              </Grid>
              <Grid item md={6} className="resendLinkContainer">
                {timer.seconds > 0 || timer.minutes > 0 ? (
                  ""
                ) : (
                  <Link
                    onClick={resendOtp}
                    variant="body2"
                    className="resendLink"
                  >
                    Resend OTP
                  </Link>
                )}
              </Grid> */}
          </Grid>
        </Box>
      </Grid>
    </Grid>
    // </Container>
  );
};

export default OtpVerification;
