import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import { FC, Fragment } from "react";
import { Member } from "../../../../../shared/interfaces/member";

interface FamilyDetails {
  family: any;
  familyMembers: Member[];
}
const FamilyMembersList: FC<FamilyDetails> = ({ family, familyMembers }) => {
  const members = familyMembers;

  return (
    <Container className="tableListContainer">
      <TableContainer component={Paper}>
        <Table size="small" sx={{ "& td": { border: 0 } }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Member Code</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>House Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>DOB</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.map((member: any, index: any) => (
              <Fragment key={index}>
                <TableRow>
                  <TableCell>{++index}</TableCell>
                  <TableCell> {member.code} </TableCell>
                  <TableCell> {member.fullNameEng} </TableCell>
                  <TableCell> {family?.houseNameEng} </TableCell>
                  <TableCell> {member.gender} </TableCell>
                  <TableCell>
                    {" "}
                    {member?.dob
                      ? new Date(member.dob).toLocaleDateString("en-GB")
                      : ""}
                    {/* {} */}
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default FamilyMembersList;
