import { FC, useState } from "react";
import { useFormik } from "formik";
import { Box, Grid, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import InputTextfield from "../../../../../shared/components/textField";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
// import { getValidationSchema } from "../../../../../shared/validations/schema";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { Facility } from "../../../../../shared/interfaces/facility";
import { getFacilityEditFields } from "./facilityEditFields";
import {
  availabiltyOptions,
  facilityOptions,
} from "../../../../../shared/constants/selectOptions";
import SingleEntityImageViewer from "../../../../../shared/components/singleEntityImageViewer";
import ImageInputField from "../../../../../shared/components/imageInputField";
import { customizeValidationSchema } from "./customSchema";

const FacilityEditView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const { modifyData } = useMutation();

  const { loading, data, fetchData } = useQuery(`/admin/facility/${id}`, null);

  const facilityData: Facility = (data as any)?.data || {};

  const facilityEditFields = getFacilityEditFields(facilityData);

  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Facilities-Update")
    : true;

  const facilityForm = useFormik({
    initialValues: {
      name: facilityData?.name,
      dailyRate: facilityData?.dailyRate,
      description: facilityData?.description,
      reducedRateFromSecondDay: facilityData?.reducedRateFromSecondDay,
      formImage: facilityData?.imageURL,
      availabilityStatus: facilityData?.availabilityStatus,
      typeOfFacility: facilityData?.typeOfFacility,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: customizeValidationSchema(),

    onSubmit: async (formData: any, { resetForm }) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("name", formData.name);
      formDataToSubmit.append("dailyRate", formData.dailyRate);
      formDataToSubmit.append(
        "reducedRateFromSecondDay",
        formData.reducedRateFromSecondDay
      );
      formDataToSubmit.append("description", formData.description);
      formDataToSubmit.append(
        "availabilityStatus",
        formData.availabilityStatus
      );
      formDataToSubmit.append("typeOfFacility", formData.typeOfFacility);
      formDataToSubmit.append("file", formData.formImage);

      try {
        const response = await modifyData(
          `admin/facility/${id}`,
          "put",
          formDataToSubmit,
          config
        );

        if (response) {
          await fetchData();
        }
      } catch (error) {
        console.error("Error updating facility:", error);
      } finally {
        setEditStatus(false);
      }
    },
  });

  const showFacilityEdit = () => {
    setEditStatus(true);
  };

  const closeFacilityEdit = () => {
    fetchData();
    facilityForm.resetForm();
    setEditStatus(false);
  };

  const viewTitleBar: TitleBarProps = {
    title: "Facility Edit",
    headerTitle: "Facility - Edit",
  };

  const editTitlebar: TitleBarProps = {
    headerTitle: "Facility - Edit",
    title: "Facility Edit",
    buttonTitle: "Save",
    onClick: () => facilityForm.handleSubmit(),
    dirty: facilityForm.dirty,
    buttonLoading: loading,
  };

  const titleBarNode = (
    <Box>
      <IconButton
        className="TitleBarButton"
        size="medium"
        id={`request-close`}
        onClick={closeFacilityEdit}
        aria-label={`request-close`}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );

  const requestTooltipNode = editPermission && (
    <IconButton
      className="TitleBarIcon"
      size="medium"
      id={`Facility-edit`}
      onClick={() => showFacilityEdit()}
      aria-label={`Facility-edit`}
      disabled={
        (data as any)?.entityPermissions
          ? !(data as any)?.entityPermissions?.includes("Facilities-Update")
          : false
      }
    >
      <EditIcon />
    </IconButton>
  );

  return (
    <>
      {!editStatus && (
        <ViewContainer loading={loading} titleBar={viewTitleBar}>
          <SingleEntityContainer
            entityFields={facilityEditFields}
            entityTitle="Facility Details"
            tooltips={requestTooltipNode}
          >
            {facilityData?.imageURL ? (
              <SingleEntityImageViewer
                src={previewUrl ? previewUrl : facilityData?.imageURL}
                alt="Facility"
              />
            ) : null}
          </SingleEntityContainer>
        </ViewContainer>
      )}

      {editStatus && (
        <FormContainer titleBar={editTitlebar} titleBarNode={titleBarNode}>
          <Box className="border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={2}
            >
              <Grid item md={6} sm={6} xs={12} key="typeOfFacility">
                <ZSSelectBox
                  fieldProps={{
                    id: "facility-typeOfFacility",
                    name: "typeOfFacility",
                    label: "Facility Type",
                    required: true,
                    placeholder: "Select Facility Type",
                  }}
                  options={facilityOptions}
                  form={facilityForm}
                  value={facilityForm.values?.typeOfFacility}
                  errorMessage={
                    (facilityForm.touched as any)?.typeOfFacility &&
                    (facilityForm.errors as any)?.typeOfFacility
                      ? (facilityForm.errors as any)?.typeOfFacility
                      : null
                  }
                />
              </Grid>

              {facilityEditFields.map(
                (field: any, index: number) =>
                  (field?.formField === undefined ||
                    field.formField === true) && (
                    <Grid item md={6} sm={6} xs={12} key={field.id}>
                      <InputTextfield
                        form={facilityForm}
                        fieldProps={field}
                        key={index}
                      />
                    </Grid>
                  )
              )}

              <Grid item md={12} sm={12} xs={12} key="description">
                <InputTextfield
                  form={facilityForm}
                  multiline
                  fieldProps={{
                    id: "description",
                    name: "description",
                    type: "text",
                    required: true,
                    maxLength: 255,
                    label: "Description",
                    placeholder: "Description",
                    capitalizedFirst: true,
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12} key="availabilityStatus">
                <ZSSelectBox
                  fieldProps={{
                    id: "facility-availabilityStatus",
                    name: "availabilityStatus",
                    label: "Availability Status",
                    required: true,
                    placeholder: "Select Availability Status",
                  }}
                  options={availabiltyOptions}
                  form={facilityForm}
                  value={facilityForm.values?.availabilityStatus}
                  errorMessage={
                    (facilityForm.touched as any)?.availabilityStatus &&
                    (facilityForm.errors as any)?.availabilityStatus
                      ? (facilityForm.errors as any)?.availabilityStatus
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12} key="facility-imageURL">
                <ImageInputField
                  id="facility-image"
                  name="formImage"
                  label="Facility Image"
                  setPreviewUrl={setPreviewUrl}
                  form={facilityForm}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12} key="facility-imagePreview">
                <SingleEntityImageViewer
                  src={previewUrl ? previewUrl : facilityData?.imageURL}
                  alt="Event"
                />
              </Grid>
            </Grid>
          </Box>
        </FormContainer>
      )}
    </>
  );
};

export default FacilityEditView;
