export const getAdminEditFields = (adminData: any) => [
  {
    id: "fullName",
    name: "fullName",
    label: "Full Name:",
    value: adminData?.fullName,
    type: "text",
    placeholder: "Enter Your Full Name",
    required: true,
  },
  {
    id: "phone",
    name: "phone",
    label: "Phone:",
    value: adminData?.phone,
    type: "text",
    placeholder: "Enter Phone Number",
    required: true,
  },
  {
    label: "Gender:",
    value: adminData?.gender,
    formField: false,
  },
  {
    label: "Email:",
    value: adminData?.email,
    formField: false,
  },
  {
    label: "Role:",
    value: adminData?.user?.role?.name,
    formField: false,
  },
];
