/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import { FC, useEffect } from "react";
import { Box, Grid, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import ToggleSwitch from "../../../../../shared/components/switch";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import Permissions from "../../../../../shared/components/permissionList";

const RoleCreateEdit: FC = () => {
  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation(
    "/user-management/roles"
  );

  const { data, fetchData } = useLazyQuery(`/admin/roles/${id}`, null);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const rolesForm = useFormik({
    initialValues: {
      name: (data as any)?.data?.name ?? "",
      rolePermissions: (data as any)?.data?.permissions ?? [],
      enabled: (data as any)?.data?.enabled ?? true,
    },

    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(["name", "rolePermissions"]),
    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/roles/${id}`,
          "patch",
          {
            ...formData,
            permissions: rolesForm.values.rolePermissions,
          },
          rolesForm
        );
      } else {
        modifyData(
          `/admin/roles`,
          "post",
          {
            ...formData,
            permissions: rolesForm.values.rolePermissions,
          },
          rolesForm
        );
      }
    },
  });

  const editPermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Roles-Update")
    : false;

  const titleBar = {
    headerTitle: "Role - Create/Edit",
    title: "Create/Edit Role",
    buttonTitle: "Save",
    onClick: () => rolesForm.handleSubmit(),
    dirty: rolesForm.dirty,
    buttonLoading: apiLoading,
    disabled: editPermission,
  };

  return (
    <>
      <FormContainer titleBar={titleBar}>
        <div className={editPermission ? "disabledGrid" : ""}>
          <Box className="border-custom">
            <Grid container alignItems="stretch">
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={rolesForm}
                  fieldProps={{
                    id: "role-name",
                    name: "name",
                    label: "Role",
                    type: "text",
                    placeholder: "Enter Role",
                    required: true,
                    capitalizedFirst: true,
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12} sx={{ marginTop: 4 }}>
                <ToggleSwitch
                  status={rolesForm?.values?.enabled}
                  handleChange={() =>
                    rolesForm.setFieldValue(
                      "enabled",
                      !rolesForm?.values?.enabled
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="allPermissionLabel">
                  Grant Permissions
                </InputLabel>
              </Grid>
              <Permissions
                rolePermissions={rolesForm?.values?.rolePermissions}
                rolesForm={rolesForm}
              />
            </Grid>
          </Box>
        </div>
      </FormContainer>
    </>
  );
};
export default RoleCreateEdit;
