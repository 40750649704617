import CertificateList from "../components/certificateList";
import ApprovalIcon from "@mui/icons-material/Approval";
import FeedIcon from "@mui/icons-material/Feed";
import { Navigate } from "react-router-dom";
const certificateRoute = {
  icon: ApprovalIcon,
  name: "Certificate Management",
  link: "/certificate-management",
  path: "/certificate-management",
  element: "",
  index: 20,
  children: [
    {
      path: "certificates-issued",
      element: <CertificateList />,
      name: "Certificates Issued",
      index: 1,
      permission: "Certificate-Read",
      link: "/certificate-management/certificates-issued",
      icon: FeedIcon,
    },
    {
      path: "",
      element: <Navigate to="certificates-issued" replace />,
    },
  ],
};
export default certificateRoute;
