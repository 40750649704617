import * as Yup from "yup";
import regEx from "../../../../../shared/validations/regularExpression";

let externalSchema = Yup.object().shape({
  jobType: Yup.string().required("Job Type is required"),
  jobTitle: Yup.string().required("Job Title is required"),
  location: Yup.string().required("Location is required"),
  salaryRange: Yup.string().required("Salary Range is required"),
  description: Yup.string().required("Description is required"),
  email: Yup.string()
    .nullable()
    .trim("Email address cannot include leading and trailing spaces")
    .email("Invalid Email address format")
    .matches(regEx.email, "Invalid Email address format")
    .min(2, "Email address must be more than 2 characters")
    .max(50, "Email address cannot exceed more than 30 characters"),
  phone: Yup.string()
    .strict(true)
    .min(10, "Phone must be 10 characters")
    .max(10, "Phone must be 10 characters")
    .matches(regEx.numbersOnly, "Only numbers are allowed"),
  applicationEndDate: Yup.string()
    .strict(true)
    .required("Application end date is a required field"),
});

export default externalSchema;
