import RoleCreateEdit from "../components/roleCreateEdit";
import RoleList from "../components/roleList";
import ShieldIcon from "@mui/icons-material/Shield";
import PersonIcon from "@mui/icons-material/Person";
import { Navigate } from "react-router-dom";

const roleRoute = {
  icon: PersonIcon,
  name: "User Management",
  link: "/user-management",
  path: "/user-management",
  element: "",
  index: 990,
  children: [
    {
      path: "roles",
      element: <RoleList />,
      name: "Roles",
      permission: "Roles-Read",
      index: 10,
      link: "user-management/roles",
      icon: ShieldIcon,
    },
    {
      path: "roles/create",
      element: <RoleCreateEdit />,
    },
    {
      path: "roles/:id",
      element: <RoleCreateEdit />,
    },
    {
      path: "",
      element: <Navigate to="roles" replace />,
    },
  ],
};
export default roleRoute;
