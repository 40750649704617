import moment from "moment";
const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const formatDate = (date: any) => {
  return isoRegex.test(date);
};

export const getFamilyEditFields = (familyData: any) => [
  {
    label: "Family code:",
    value: familyData?.familyCode,
    formField: false,
  },
  {
    label: "Head:",
    value: familyData?.familyHeadDetails?.fullNameEng ?? "Not Available",
    formField: false,
  },
  {
    label: "Ration Card Number:",
    value: familyData?.rationCardNumber ?? "Not Available",
    formField: false,
  },
  {
    label: "Ration Card Type:",
    value: familyData?.rationCardType ?? "Not Available",
    formField: false,
  },
  {
    label: "Annual Income:",
    value: familyData?.annualIncome ?? "Not Available",
    formField: false,
  },
  {
    label: "Date Of Joining:",
    value: formatDate(familyData?.joinedDate)
      ? moment(familyData?.joinedDate).format("DD/MM/YYYY")
      : moment(familyData?.joinedDate, "DD/MM/YYYY").format("DD/MM/YYYY"),
    formField: false,
  },
  {
    label: "Kudumba Unit:",
    value: familyData?.kudumbaUnitNameMal ?? "Not Available",
    formField: false,
  },
  {
    label: "Micro Finance:",
    value: familyData?.microFinanceNameMal ?? "Not Available",
    formField: false,
  },
];
