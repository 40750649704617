import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext } from "react";
import TitleBar from "../../../../../shared/components/titleBar";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { EventType } from "../../../../../shared/interfaces/event-type";

const EventTypeList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/event-type", {
    params: { ...payload },
  });

  let responseData = data as any;
  const tableList = responseData?.items.map((item: EventType) => {
    return {
      id: item.id,
      name: item?.eventTypeName,
    };
  });

  const eventTypeDisplayColumns = ["name"];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Event Types"
        buttonTitle="Create"
        onClick={() => navigate("/event-management/event-type/create")}
        headerTitle="Event Type"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("EventType-Create")
            : false
        }
      >
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Event Type"
          data={tableList}
          displayColumns={eventTypeDisplayColumns}
          linkColumn="eventTypeName"
          linkUrl="/event-management/event-type"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/event-type"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("EventType-Delete")
              : false
          }
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default EventTypeList;
