import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { FC, useState } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import {
  availabiltyOptions,
  facilityOptions,
} from "../../../../../shared/constants/selectOptions";
import SingleEntityImageViewer from "../../../../../shared/components/singleEntityImageViewer";
import ImageInputField from "../../../../../shared/components/imageInputField";

const FacilityCreate: FC = () => {
  const { loading: apiLoading, modifyData } = useMutation(
    "/facility-management/facilities"
  );
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const textFields = [
    {
      id: "facility-name",
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Facility Name",
      required: true,
    },
    {
      id: "facility-dailyRate",
      name: "dailyRate",
      label: "Daily Rate",
      type: "text",
      placeholder: "Daily Rate",
      required: true,
    },
    {
      id: "facility-reducedRateFromSecondDay",
      name: "reducedRateFromSecondDay",
      label: "Discount Rate",
      type: "text",
      placeholder: "Discount Rate",
    },
  ];
  const facilityForm = useFormik({
    initialValues: {
      name: "",
      dailyRate: "",
      description: "",
      reducedRateFromSecondDay: "",
      formImage: "",
      availabilityStatus: null,
      typeOfFacility: null,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "name",
      "dailyRate",
      "reducedRateFromSecondDay",
      "description",
      "availabilityStatus",
      "typeOfFacility",
      "formImage",
    ]),
    onSubmit: async (formData) => {
      const formDataToSend = new FormData();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== undefined && key !== "formImage") {
          formDataToSend.append(key, value);
        }
      });
      formDataToSend.append("file", formData.formImage);
      modifyData(`admin/facility`, "post", formDataToSend, config);
    },
  });

  const titleBar = {
    headerTitle: "Facility - Create",
    title: "Create Facility",
    buttonTitle: "Save",
    onClick: () => facilityForm.handleSubmit(),
    dirty: facilityForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
          <Grid item md={6} sm={6} xs={12} key="facility-typeOfFacility">
            <ZSSelectBox
              fieldProps={{
                id: "facility-typeOfFacility",
                name: "typeOfFacility",
                placeholder: "Select Facility Type",
                label: "Facility Type",
                required: true,
              }}
              options={facilityOptions}
              form={facilityForm}
              value={facilityForm.values?.typeOfFacility}
            />
          </Grid>
          {textFields.map((field, index: number) => (
            <Grid item md={6} sm={6} xs={12} key={field.id}>
              <InputTextfield
                form={facilityForm}
                fieldProps={field}
                key={index}
              />
            </Grid>
          ))}

          <Grid item md={12} sm={12} xs={12} key="facility-description">
            <InputTextfield
              form={facilityForm}
              multiline
              fieldProps={{
                id: "description",
                name: "description",
                label: "Description",
                type: "text",
                required: true,
                placeholder: "Description",
                maxLength: 255,
                capitalizedFirst: true,
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="facility-availabilityStatus">
            <ZSSelectBox
              fieldProps={{
                id: "facility-availabilityStatus",
                name: "availabilityStatus",
                placeholder: "Select Availability Status",
                label: "Availability Status",
                required: true,
              }}
              options={availabiltyOptions}
              form={facilityForm}
              value={facilityForm.values?.availabilityStatus}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="facility-imageURL">
            <ImageInputField
              id="facility-image"
              name="formImage"
              label="Facility Image"
              setPreviewUrl={setPreviewUrl}
              form={facilityForm}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} key="facility-imagePreview">
            <SingleEntityImageViewer src={previewUrl} alt="Facility" />
          </Grid>
        </Grid>
      </Box>
    </FormContainer>
  );
};
export default FacilityCreate;
