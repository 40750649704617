import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { JobStatus } from "../../../../../shared/enum";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { LoadingButton } from "@mui/lab";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import InputTextfield from "../../../../../shared/components/textField";
import { getJobFields } from "./jobFields";
import internalSchema from "../jobCreate/internalSchema";
import externalSchema from "../jobCreate/externalSchema";
// import { dateInputFromatHelper } from "../../../../../shared/utils/date-helper";
import moment from "moment";
import { CustomDatePicker } from "../../../../../shared/components/datePicker";

const JobEditView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);
  const [openConfirmationModalData, setOpenConfirmationModalData] = useState({
    status: false,
    message: "",
    jobStatus: "",
  });

  const { loading, data, fetchData } = useLazyQuery(`/admin/job/${id}`, null);

  const { error: saveError, modifyData } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const jobData = (data as any)?.data || {};

  const jobFields = getJobFields(jobData);

  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  const formatDate = (date: any) => {
    return isoRegex.test(date);
  };

  const handleStatusChange = async () => {
    await modifyData(`/admin/job/update-job-status/${id}`, "put", {
      jobStatus: openConfirmationModalData.jobStatus,
    });
    if (!saveError) {
      fetchData();
    }
    handleCloseConfirmDialog();
  };

  const handleOpenConfirmDialog = (message: string, jobStatus: string) => {
    setOpenConfirmationModalData({
      status: true,
      message: message,
      jobStatus: jobStatus,
    });
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmationModalData({
      status: false,
      message: "",
      jobStatus: "",
    });
  };

  const handleConfirmAction = async (responseStatus: boolean) => {
    if (responseStatus) {
      handleStatusChange();
    }
    handleCloseConfirmDialog();
  };
  const today = moment().startOf("day");

  const jobForm = useFormik({
    initialValues: {
      jobTitle: jobData?.jobTitle,
      location: jobData?.location,
      jobType: jobData?.jobType,
      name: jobData?.contactDetails?.name,
      email: jobData?.contactDetails?.email,
      phone: jobData?.contactDetails?.phone,
      url: jobData?.contactDetails?.url,
      employmentType: jobData?.employmentType,
      salaryRange: jobData?.salaryRange,
      noOfOpenings: jobData?.noOfOpenings,
      description: jobData?.description,
      applicationEndDate: formatDate(jobData?.applicationEndDate)
        ? moment(jobData?.applicationEndDate).format("DD/MM/YYYY")
        : moment(jobData?.applicationEndDate, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ),
      jobStatus: jobData?.jobStatus,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: () => {
      return jobForm.values.jobType === "Internal"
        ? internalSchema
        : externalSchema;
    },
    validate: (values) => {
      const errors: any = {};
      if (
        values.applicationEndDate &&
        moment(values.applicationEndDate).isBefore(today)
      ) {
        errors.applicationEndDate =
          "Application end date cannot be in the past";
      }

      if (!moment(values.applicationEndDate, true).isValid()) {
        errors.applicationEndDate =
          "Application end date  must be a valid date";
      }

      return errors;
    },
    onSubmit: async (formData: any, { resetForm }) => {
      modifyData(
        `/admin/job/${id}`,
        "put",
        {
          ...formData,
        },
        jobForm
      );
      if (saveError) {
        return;
      }
      resetForm();
      fetchData();

      setEditStatus(false);
    },
  });

  const showJobEdit = () => {
    setEditStatus(true);
  };
  const handleapplicationDateChange = (newValue: any) => {
    jobForm.setFieldTouched("applicationEndDate", true);
    jobForm.setFieldValue(
      "applicationEndDate",
      moment(newValue).format("yyyy-MM-DD")
    );
  };
  const closeJobEdit = () => {
    fetchData();
    jobForm.resetForm();
    setEditStatus(false);
  };

  const viewTitleBar: TitleBarProps = {
    title: "Job Edit",
    headerTitle: "Job - Edit",
  };

  const editTitlebar: TitleBarProps = {
    title: "Job Edit",
    headerTitle: "Job Edit",
    buttonTitle: "Save",
    onClick: () => jobForm.handleSubmit(),
    dirty: jobForm.dirty,
    buttonLoading: loading,
  };
  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Job-Update")
    : true;
  const publishPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Job-Publish")
    : true;
  const titleBarNode = (
    <>
      {!editStatus && publishPermission ? (
        jobData?.jobStatus === JobStatus.Published ? (
          <LoadingButton
            className="TitleBarButton"
            loading={false}
            variant="contained"
            size="small"
            id="job-cancel"
            onClick={() =>
              handleOpenConfirmDialog(
                "Are you sure you want to close this job?",
                JobStatus.Closed
              )
            }
          >
            Close
          </LoadingButton>
        ) : jobData?.jobStatus === JobStatus.Draft ? (
          <>
            <LoadingButton
              className="TitleBarButton"
              loading={false}
              variant="contained"
              size="small"
              id="job-publish"
              onClick={() =>
                handleOpenConfirmDialog(
                  "Are you sure you want to publish this job?",
                  JobStatus.Published
                )
              }
            >
              Publish
            </LoadingButton>
            <LoadingButton
              className="TitleBarButton"
              loading={false}
              variant="contained"
              size="small"
              id="job-cancel"
              onClick={() =>
                handleOpenConfirmDialog(
                  "Are you sure you want to close this job?",
                  JobStatus.Closed
                )
              }
            >
              Close
            </LoadingButton>
          </>
        ) : null
      ) : null}

      {editStatus ? (
        <Tooltip arrow title="Close">
          <IconButton
            className="TitleBarButton"
            size="medium"
            id={`job-edit`}
            onClick={closeJobEdit}
            aria-label={`job-close`}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );

  const requestTooltipNode = jobData?.jobStatus === JobStatus.Draft &&
    editPermission && (
      <Tooltip arrow title="Edit">
        <IconButton
          className="TitleBarIcon"
          size="medium"
          id={`job-edit`}
          onClick={() => showJobEdit()}
          aria-label={`job-edit`}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );

  return (
    <>
      {editStatus ? (
        <>
          <FormContainer titleBar={editTitlebar} titleBarNode={titleBarNode}>
            <Box className="border-custom">
              <Grid
                container
                alignItems="stretch"
                columnSpacing={4}
                rowSpacing={0}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="job"
                      name="jobType"
                      value={jobForm.values.jobType}
                      onChange={(event) => {
                        jobForm.setFieldValue("jobType", event.target.value);
                      }}
                      onBlur={jobForm.handleBlur}
                      row
                    >
                      <FormControlLabel
                        value="Internal"
                        control={<Radio />}
                        label="Internal"
                      />
                      <FormControlLabel
                        value="External"
                        control={<Radio />}
                        label="External"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {" "}
                      {((jobForm.touched as any)?.jobType &&
                        (jobForm.errors as any)?.jobType) ??
                        null}{" "}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box className="border-custom">
              <Grid
                container
                alignItems="stretch"
                columnSpacing={4}
                rowSpacing={2}
              >
                {jobFields.map(
                  (field: any, index: number) =>
                    (field?.formField === undefined ||
                      field.formField === true) && (
                      <Grid item md={6} sm={6} xs={12} key={field.id}>
                        {(["jobTitle", "location", "salaryRange"]?.includes(
                          field.name
                        ) &&
                          jobForm.values.jobType === "External") ||
                        ([
                          "jobTitle",
                          "location",
                          "salaryRange",
                          "name",
                          "phone",
                          "noOfOpenings",
                        ]?.includes(field.name) &&
                          jobForm.values.jobType === "Internal") ? (
                          <InputTextfield
                            form={jobForm}
                            fieldProps={{
                              ...field,
                              required: true,
                            }}
                            key={index}
                          />
                        ) : (
                          <InputTextfield
                            form={jobForm}
                            fieldProps={{
                              ...field,
                              required: false,
                            }}
                            key={index}
                          />
                        )}
                      </Grid>
                    )
                )}
                <Grid item md={6} sm={6} xs={12}>
                  <CustomDatePicker
                    label="Application End Date"
                    required={jobForm?.values?.jobType === "Internal"}
                    onChange={(date: any) => handleapplicationDateChange(date)}
                    placeholder="Application End Date"
                    value={
                      jobForm.values.applicationEndDate
                        ? new Date(jobForm.values.applicationEndDate)
                        : null
                    }
                    errorMessage={
                      ((jobForm?.touched as any)?.applicationEndDate &&
                        (jobForm?.errors as any)?.applicationEndDate) ??
                      ""
                    }
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12} key="job-description">
                  <InputTextfield
                    form={jobForm}
                    multiline
                    fieldProps={{
                      id: "description",
                      name: "description",
                      label: "Job Description",
                      type: "text",
                      maxLength: 255,
                      placeholder: "Job Description",
                      required: true,
                      capitalizedFirst: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormContainer>
        </>
      ) : (
        <ViewContainer
          titleBar={viewTitleBar}
          titleBarNode={titleBarNode}
          loading={loading}
        >
          <SingleEntityContainer
            entityFields={jobFields}
            entityTitle="Job Details"
            tooltips={requestTooltipNode}
          />
        </ViewContainer>
      )}
      <ConfirmDialog
        title={"Confirm"}
        openConfirmDialog={openConfirmationModalData?.status}
        setOpenConfirmDialog={handleCloseConfirmDialog}
        message={openConfirmationModalData.message}
        handleSubmit={handleConfirmAction}
      />
    </>
  );
};

export default JobEditView;
