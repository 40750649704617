import { FC, useContext, useEffect, useState } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import { welfareRequest } from "../../../../../shared/interfaces/welfare-requests";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const OngoingSchemeList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [welfareStatus, setStatusFilter] = useState("Active");
  const { loading, data, fetchData, error } = useQuery(
    "/admin/welfare-request/scheme/ongoing-scheme/",
    {
      params: {
        ...payload,
        welfareStatus: welfareStatus,
      },
    }
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welfareStatus]);

  const displayColumns = [
    "category",
    "schemeName",
    "fullNameEng",
    "houseNameEng",
    "welfareStatus",
  ];

  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    category: `"welfare"."welfareCategory"`,
    schemeName: `"welfare"."schemeName"`,
    fullNameEng: `"applicant"."fullNameEng"`,
    welfareStatus: "welfareStatus",
    createdOn: "createdAt",
  };
  const sortableColumns = ["fullNameEng"];
  let ongoingWelfareList: welfareRequest[] = [];
  if (data && (data as any)?.items) {
    ongoingWelfareList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        category: item?.welfare?.welfareCategory,
        schemeName: item?.welfare?.schemeName,
        fullNameEng: item?.applicant?.fullNameEng,
        houseNameEng: item?.applicant?.addressDetails?.houseNameEng,
        welfareStatus: item?.welfareStatus,
      };
    });
  }

  return (
    <TableViewContainer>
      <TitleBar
        title="Ongoing Requests"
        headerTitle="Ongoing Requests"
        children={
          <>
            <TitleBarSelectBox
              fieldProps={{
                id: "status-log-filter",
                name: "status-log-filter",
              }}
              value={welfareStatus ?? "All"}
              onChange={(value: string) => setStatusFilter(value)}
              options={[
                { value: "All", label: "All" },
                { value: "Active", label: "Active" },
                { value: "Onhold", label: "Onhold" },
                { value: "Cancel", label: "Cancel" },
              ]}
            />
            <SearchBox />
          </>
        }
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes(
                "welfare request-Create"
              )
            : false
        }
      ></TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Ongoing Requests"
          data={ongoingWelfareList}
          displayColumns={displayColumns}
          sortableColumns={sortableColumns}
          sortableFields={sortFields}
          linkColumn="fullNameEng"
          linkUrl="/welfare-schemes/ongoing-schemes"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/welfare-request/delete-ongoing-scheme"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes(
                  "Welfare request-Delete"
                )
              : false
          }
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default OngoingSchemeList;
