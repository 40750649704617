import "./styles.css";
import { Container } from "@mui/material";
import { FC } from "react";
import TitleBar from "../titleBar";
import Loader from "../loader/linearProgress";
export const TableViewContainer: FC<{
  children: React.ReactNode;
  titleBar?: any;
  loading?: boolean;
}> = ({ children, titleBar, loading = false }) => {
  return (
    <Container className="tableContainer">
      <>
        {titleBar && (
          <TitleBar
            title={titleBar?.title}
            buttonTitle={titleBar?.buttonTitle}
            onClick={titleBar?.onClick}
            disabled={titleBar.disabled}
            headerTitle={titleBar?.headerTitle}
            buttonLoading={titleBar?.buttonLoading}
          >
            {titleBar?.titleBarNode}
          </TitleBar>
        )}
        {loading && <Loader />}
        {!loading && <>{children}</>}
      </>
    </Container>
  );
};
