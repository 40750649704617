import { Container } from "@mui/material";
import { FC, useContext } from "react";
import TitleBar from "../../../../shared/components/titleBar";
import DataTable from "../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import useQuery from "../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../shared/components/noPermissionBanner";
import Loader from "../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../layouts/SidebarLayout/Header/SearchBox";
import moment from "moment";
// import { Banner } from "../../../../shared/interfaces/banner";

const LogList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/logger", {
    params: { ...payload },
  });

  let responseData = data as any;
  const tableList = responseData?.items.map((item: any) => {
    return {
      id: item.id,
      method: item?.method,
      status: item?.status,
      url: item?.url,
      user: item?.user,
      description: item?.description,
      createdAt: moment((item as any)?.createdAt).format("MMM DD, hh:mm A"),
    };
  });

  const logsDisplayColumns = [
    "method",
    "url",
    "user",
    "description",
    "createdAt",
  ];

  const renderCell = (row: any, col: string) => {
    if (col === "user") {
      if (row?.user?.userType === "Member") {
        return <>{row.user?.member?.fullNameEng}</>;
      } else {
        return <>{row.user?.admin?.fullName}</>;
      }
    }
    return row[col];
  };

  return (
    <Container className="tableListContainer">
      <TitleBar title="Logs">
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Logs"
          data={tableList}
          displayColumns={logsDisplayColumns}
          linkColumn="name"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          renderCell={renderCell}
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default LogList;
