import { useFormik } from "formik";
import { Box, Checkbox, Grid } from "@mui/material";
import { FC, useContext } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import { CustomDatePicker } from "../../../../../shared/components/customDatePicker";
import { CustomTimePicker } from "../../../../../shared/components/timePicker";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import moment from "moment";
// import SingleEntityImageViewer from "../../../../../shared/components/singleEntityImageViewer";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { customizeValidationSchema } from "../customSchema";
// import ImageInputField from "../../../../../shared/components/imageInputField";
const CreateEvent: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  // const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const { loading: apiLoading, modifyData } = useMutation(
    "/event-management/events"
  );
  const { data: eventTypeData } = useQuery("/admin/event-type", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
    },
  });

  const selectedEventType: any = null;
  const eventTypes = (eventTypeData as any)?.items?.map((eventType: any) => ({
    label: eventType?.eventTypeName,
    value: eventType?.id,
  }));
  const today = moment().startOf("day");

  const eventForm = useFormik({
    initialValues: {
      eventTypeId: "",
      name: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: null,
      location: "",
      description: "",
      eventImage: "",
      notify: false,
      astroNotify: false,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {};
      if (values.startDate && moment(values.startDate).isBefore(today)) {
        errors.startDate = "Start date cannot be in the past";
      }
      if (values.endDate && moment(values.endDate).isBefore(today)) {
        errors.endDate = "End  date cannot be in the past";
      }
      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate);
        const endDate = moment(values.endDate);

        if (endDate.isBefore(startDate)) {
          errors.endDate = "End Date cannot be earlier than Start Date";
        }
      }
      if (values.startDate === values.endDate) {
        const isValid = checkTimeOnSameDate(values.startTime, values.endTime);
        if (!isValid) {
          errors.endTime = "End time cannot be earlier than Start time";
        }
      }
      return errors;
    },

    validationSchema: customizeValidationSchema(),
    onSubmit: async (formData: any) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formDataToSend = new FormData();
      formDataToSend.append("eventTypeId", formData.eventTypeId);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("startDate", formData.startDate);
      formDataToSend.append("endDate", formData.endDate);
      formDataToSend.append("location", formData.location);
      formDataToSend.append("notify", formData.notify.toString());
      formDataToSend.append("astroNotify", formData.astroNotify.toString());
      formDataToSend.append("description", formData.description);
      formDataToSend.append("file", formData.eventImage);
      formDataToSend.append(
        "startTime",
        moment(formData.startTime).format("HH:mm")
      );
      formDataToSend.append(
        "endTime",
        moment(formData.endTime).format("HH:mm")
      );
      modifyData(`/admin/event/`, "post", formDataToSend, config);
    },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    eventForm.setFieldValue(name, checked);
  };
  const checkTimeOnSameDate = (startTime: any, endTime: any) => {
    let date1 = new Date(startTime);
    let date2 = new Date(endTime);

    // Function to extract time in seconds since midnight (ignores the date)

    let time1 = getTimeInSeconds(date1);
    let time2 = getTimeInSeconds(date2);

    if (time1 < time2) {
      return true;
    } else {
      return false;
    }
  };
  function getTimeInSeconds(date: any) {
    return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  }
  const titleBar = {
    headerTitle: "Event - Create",
    title: "Create Event",
    buttonTitle: "Save",
    onClick: () => eventForm.handleSubmit(),
    dirty: eventForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12}>
            <ZSAutocomplete
              options={eventTypes}
              placeholder="Select Event Type"
              label="Event Type"
              required
              setValue={(selectedOption: any) => {
                eventForm.setFieldTouched("eventTypeId", true);
                if (selectedOption?.value) {
                  eventForm.setFieldValue("eventTypeId", selectedOption?.value);
                } else {
                  eventForm.setFieldValue("eventTypeId", "");
                }
              }}
              defaultValue={selectedEventType}
              setBlur={() => {
                eventForm.setFieldTouched("eventTypeId", true);
              }}
              errorMessage={
                ((eventForm.touched as any)?.eventTypeId &&
                  (eventForm.errors as any)?.eventTypeId) ??
                null
              }
              refetchFunction={(searchString: string) => {
                setPayload((prevData: any) => {
                  return { ...prevData, search: searchString };
                });
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="event-name">
            <InputTextfield
              form={eventForm}
              fieldProps={{
                id: "name",
                name: "name",
                label: "Event Name",
                type: "text",
                required: true,
                placeholder: "Event Name",
                capitalizedFirst: true,
              }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} key="event-description">
            <InputTextfield
              form={eventForm}
              multiline
              fieldProps={{
                id: "description",
                name: "description",
                label: "Description",
                type: "text",
                required: true,
                placeholder: "Description",
                capitalizedFirst: true,
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <CustomDatePicker
              label="Start Date"
              required
              placeholder="Start Date"
              name="startDate"
              value={
                eventForm.values.startDate
                  ? new Date(eventForm.values.startDate)
                  : null
              }
              form={eventForm}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <CustomTimePicker
              label="Start Time"
              name="startTime"
              placeholder="Start Time"
              required
              value={
                eventForm?.values?.startTime
                  ? new Date(eventForm?.values?.startTime)
                  : null
              }
              form={eventForm}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <CustomDatePicker
              label="End Date"
              name="endDate"
              placeholder="End Date"
              required
              value={
                eventForm.values.endDate
                  ? new Date(eventForm.values.endDate)
                  : null
              }
              form={eventForm}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <CustomTimePicker
              label="End Time"
              name="endTime"
              placeholder="End Time"
              required
              value={
                eventForm?.values?.endTime
                  ? new Date(eventForm?.values?.endTime)
                  : null
              }
              form={eventForm}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={6} key="event-location">
            <InputTextfield
              form={eventForm}
              fieldProps={{
                id: "location",
                name: "location",
                label: "Venue",
                type: "text",
                required: true,
                placeholder: "Venue",
                capitalizedFirst: true,
              }}
            />
          </Grid>
          {/* <Grid item md={6} sm={6} xs={12} key="event-imageURL">
            <ImageInputField
              id="event-image"
              name="eventImage"
              label="Event Image"
              setPreviewUrl={setPreviewUrl}
              form={eventForm}
            />
          </Grid> */}
          <Grid item md={6}>
            <Grid item md={12} sm={12} xs={12}>
              <Checkbox
                id="notify"
                name="notify"
                checked={eventForm.values?.notify}
                onChange={handleCheckboxChange}
              />{" "}
              Send notifications to all members
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Checkbox
                id="astroNotify"
                name="astroNotify"
                checked={eventForm.values?.astroNotify}
                onChange={handleCheckboxChange}
              />{" "}
              Send astro notifications to all members
            </Grid>
          </Grid>
          {/* <Grid item md={6} sm={6} xs={6} key="event-imagePreview">
            <SingleEntityImageViewer src={previewUrl} alt="Event" />
          </Grid> */}
        </Grid>
      </Box>
    </FormContainer>
  );
};
export default CreateEvent;
