import * as yup from "yup";
import { getValidationSchema } from "../../../../shared/validations/schema";
import { validateImageTypes } from "../../../../shared/utils/image-helper";
export const customizeValidationSchema = () => {
  let defaultSchema: any = getValidationSchema([
    "eventTypeId",
    "name",
    "startDate",
    "startTime",
    "endTime",
    "endDate",
    "description",
    "location",
  ]);
  let customSchema = yup.object().shape({
    name: yup
      .string()
      .trim("Name cannot include leading and trailing spaces")
      .strict(true)
      .min(2, "Name must be more than 2 characters")
      .max(30, "Name cannot exceed more than 30 characters")
      .required("Name is a required field"),
    eventImage: yup
      .mixed()
      .nullable()
      .test(
        "FILE_SIZE",
        "Uploaded image is too big. Max size is 2MB",
        (value) => {
          if (value && typeof value !== "string") {
            const file = value as File;
            return file.size <= 2000000;
          }
          return true;
        }
      )
      .test(
        "file-type",
        "Invalid file type. Please select a valid image.",
        (value) => {
          if (value && typeof value !== "string") {
            return validateImageTypes(value as File);
          }
          return true;
        }
      ),
  });

  return defaultSchema.concat(customSchema);
};
