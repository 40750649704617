/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackBarAlertContext } from "../../../contexts/SnackbarContext";
import { SidebarContext } from "../../../contexts/SidebarContext";
import request from "../../../request";

const useQuery = (
  endPoint: string,
  variables?: any,
  reDirectTo?: string | null
) => {
  const snackBarAlertContext = useContext(SnackBarAlertContext);
  const { payload } = useContext(SidebarContext);
  const navigate = useNavigate();
  const [response, setResponse] = useState({
    loading: true,
    error: null,
    data: null,
  });
  const fetchData = async () => {
    try {
      let apiResponse = await request.get(endPoint, variables);

      if (response) {
        setResponse({
          loading: false,
          data: apiResponse?.data,
          error: null,
        });
      }
    } catch (error: any) {
      setResponse({ loading: false, data: null, error: error });
      if (reDirectTo) {
        navigate(reDirectTo);
      }
      snackBarAlertContext.set({
        status: true,
        type: "error",
        message: error.message,
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, [
    endPoint,
    payload.search,
    payload.skip,
    payload.take,
    payload.sortBy,
    payload.sortOrder,
  ]);
  return { ...response, fetchData };
};

export default useQuery;
