import JobList from "../components/jobList";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import WorkIcon from "@mui/icons-material/Work";
import { Navigate } from "react-router-dom";
import JobEditView from "../components/jobEditView";
import JobCreate from "../components/jobCreate";

const jobRoute = {
  icon: WorkIcon,
  name: "Job Management",
  link: "/job-management",
  path: "job-management",
  element: "",
  index: 80,
  children: [
    {
      path: "jobs",
      element: <JobList />,
      name: "Jobs",
      index: 0,
      permission: "Job-Read",
      link: "job-management/jobs",
      icon: WorkOutlineIcon,
    },
    {
      path: "jobs/create",
      element: <JobCreate />,
    },
    {
      path: "jobs/:id",
      element: <JobEditView />,
    },

    {
      path: "",
      element: <Navigate to="job" replace />,
    },
  ],
};
export default jobRoute;
