import Member from "../components/memberCreate";
import MembersList from "../components/membersList";
import MemberView from "../components/memberEditView";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Navigate } from "react-router-dom";
const familyRoute = {
  icon: PeopleAltIcon,
  name: "Branch Membership",
  link: "/branchMembership",
  path: "branchMembership",
  index: 10,
  element: "",
  children: [
    {
      path: "members",
      element: <MembersList />,
      name: "Members",
      permission: "Member-Read", // Permission
      index: 1,
      link: "branchMembership/members",
      icon: SupervisedUserCircleIcon,
    },
    {
      path: "members/create",
      element: <Member />,
    },
    {
      path: "members/:id",
      element: <MemberView />,
    },
    {
      path: "",
      element: <Navigate to="members" replace />,
    },
  ],
};
export default familyRoute;
