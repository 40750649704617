export const getMemberRequestFields = (memberRequestData: any) => [
  {
    id: "fullNameEng",
    name: "fullNameEng",
    label: "Name in English:",
    value: memberRequestData?.fullNameEng,
    type: "text",
    placeholder: "Enter your fullname in English",
    required: true,
  },
  {
    id: "fullNameMal",
    name: "fullNameMal",
    label: "Name in Malayalam:",
    value: memberRequestData?.fullNameMal ?? "Not Available",
    type: "text",
    placeholder: "Enter your fullname in Malayalam",
    required: true,
  },
  {
    id: "relationWithFamilyHead",
    name: "relationWithFamilyHead",
    label: "Relation with Family Head:",
    value: memberRequestData?.relationWithFamilyHead,
    type: "text",
    placeholder: "Enter relationship with Head of the Family",
    required: true,
  },
  {
    id: "phone",
    name: "phone",
    label: "Phone Number:",
    value: memberRequestData?.phone ?? "Not Available",
    type: "text",
    placeholder: "Enter your phone number",
  },
  {
    id: "email",
    name: "email",
    label: "Email:",
    value: memberRequestData?.email ?? "Not Available",
    type: "text",
    placeholder: "Enter your email address",
  },
  {
    label: "Date of Birth:",
    value: memberRequestData?.dob
      ? new Date(memberRequestData.dob).toLocaleDateString("en-GB")
      : "",
    formField: false,
  },
  {
    label: "Gender:",
    value: memberRequestData?.gender,
    formField: false,
  },
  {
    label: "Request Status:",
    value: memberRequestData?.requestStatus,
    formField: false,
  },
  {
    label: "Approved By:",
    value: memberRequestData?.approvedByUser?.admin?.fullName ?? "Not Approved",
    formField: false,
  },
  {
    id: "remarks",
    name: "remarks",
    label: "Remarks:",
    value: memberRequestData?.remarks ?? "Not Available",
    type: "text",
  },
];
