import {
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Box,
} from "@mui/material";
import "./styles.css";
import { FC, useEffect, useState } from "react";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import PaginationComponent from "../pagination";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoDataFound from "../noDataFoundBanner";
import DeletePermissionDialog from "../deletePermissionModal";
import useMutation from "../hooks/useMutation";
import { Link, useNavigate } from "react-router-dom";
import { takeLastElement } from "./helper";
import { Dict } from "../../interfaces/generics";
type DataTableProps = {
  data: Object[];
  entityName?: string;
  linkColumn?: string;
  linkUrl?: string;
  pagination: any;
  setPagination: Function;
  displayColumns: string[];
  sortableColumns?: string[];
  sortableFields?: Dict;
  totalItems?: number;
  deleteApiUrl?: string;
  disabled?: boolean;
  refetchFunction?: Function;
  showCheckboxes?: boolean;
  extraAction?: any;
  newAction?: any;
  onUpdateStatus?: (ids: any[]) => void;
  renderCell?: (row: any, col: string) => React.ReactNode;
  icon?: any;
  linkIconColumn?: string;
};

const DataTable: FC<DataTableProps> = ({
  data,
  entityName,
  linkColumn,
  linkUrl,
  pagination,
  setPagination,
  displayColumns,
  sortableColumns,
  sortableFields = {},
  totalItems,
  deleteApiUrl,
  disabled,
  refetchFunction = () => {},
  showCheckboxes = false,
  extraAction,
  newAction,
  onUpdateStatus = () => {},
  renderCell,
  icon,
  linkIconColumn,
}) => {
  const { modifyData } = useMutation(linkUrl);
  const [deletedRecord, setDeletedRecord] = useState<any>();
  const [checkedIds, setCheckedIds] = useState<any[]>([]);
  const navigate = useNavigate();
  const invertOrderDirection: any = {
    ASC: "DESC",
    DESC: "ASC",
  };

  const handleDelete = async () => {
    await modifyData(`${deleteApiUrl}/${deletedRecord.id}`, "delete");
    setDeletedRecord(null);
    refetchFunction();
  };
  const handleCheckboxChange = (id: number) => {
    const updatedCheckedIds = checkedIds.includes(id)
      ? checkedIds.filter((item) => item !== id)
      : [...checkedIds, id];
    setCheckedIds(updatedCheckedIds);
    onUpdateStatus(updatedCheckedIds);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allIds = event.target.checked
      ? data.map((item: any) => {
          return { id: item.id, memberID: item.memberID };
        })
      : [];
    setCheckedIds(allIds?.map((data) => data?.id));
    onUpdateStatus(allIds);
  };
  const handleSort = (columnName: any) => {
    const fieldName = sortableFields?.[columnName];

    if (fieldName) {
      setPagination((current: any) => {
        return {
          ...current,
          sortBy: fieldName,
          tableHeaderName: columnName,
          sortOrder:
            current.sortBy === fieldName
              ? invertOrderDirection[current.sortOrder]
              : "ASC",
        };
      });
    }
  };
  useEffect(() => {
    setPagination((current: any) => {
      return {
        ...current,
        skip: 0,
        take: 25,
        sortOrder: "DESC",
        sortBy: "createdAt",
        search: "",
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const booleanStatus: any = {
    verified: {
      true: "Verified",
      false: "Not Verified",
    },
    status: {
      true: "Success",
      false: "Failed",
    },
    enabled: {
      true: "Enabled",
      false: "Disabled",
    },
  };
  const sortColumns = Object.keys(sortableFields) || [];

  return (
    <>
      {data?.length <= 0 && <NoDataFound />}
      {data?.length > 0 && (
        <>
          {deletedRecord && (
            <DeletePermissionDialog
              open={deletedRecord ? true : false}
              handleClose={() => {
                setDeletedRecord(null);
              }}
              deletedData={{
                name: linkColumn ? deletedRecord[linkColumn] : "",
                content: entityName ?? "",
              }}
              handleDelete={handleDelete}
            />
          )}
          <div className="tableScrollbar">
            <Table
              className={linkUrl ? "dataTable editableDataTable" : "dataTable"}
            >
              <TableHead>
                <TableRow className="headerRow">
                  {showCheckboxes && (
                    <TableCell align="left" className="columnName">
                      <Checkbox
                        checked={
                          checkedIds.length > 0 &&
                          checkedIds.length === data.length
                        }
                        indeterminate={
                          checkedIds.length > 0 &&
                          checkedIds.length < data.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                  )}

                  <TableCell align="left" className="columnName">
                    #
                  </TableCell>
                  {displayColumns.map((title, columnIndex: number) => (
                    <TableCell
                      align={title in booleanStatus ? "center" : "left"}
                      className="columnName"
                      key={columnIndex}
                      onClick={() => handleSort(title)}
                    >
                      <div className="boxSort">
                        {title === "createdAt"
                          ? "Date & Time"
                          : takeLastElement(title)
                              ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                              .replace("Eng", "")}
                        {sortColumns?.includes(title) ? (
                          <Box>
                            {pagination?.sortOrder === "ASC" &&
                            pagination?.tableHeaderName === title ? (
                              <KeyboardArrowUpIcon
                                className="sortIcon"
                                fontSize="small"
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                className="sortIcon"
                                fontSize="small"
                              />
                            )}
                          </Box>
                        ) : null}
                      </div>
                    </TableCell>
                  ))}
                  {(deleteApiUrl || extraAction) && (
                    <TableCell align="center" className="columnName">
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((dataItem: any, dataIndex: number) => (
                  <TableRow
                    key={dataIndex}
                    onClick={() => {
                      linkUrl && navigate(`${linkUrl}/${dataItem.id}`);
                    }}
                  >
                    {showCheckboxes && (
                      <TableCell align="left" className="tableCell">
                        <Checkbox
                          checked={checkedIds.includes(dataItem.id)}
                          onChange={() => handleCheckboxChange(dataItem.id)}
                        />
                      </TableCell>
                    )}

                    <TableCell align="left" className="tableCell">
                      {pagination?.skip * pagination?.take + dataIndex + 1}
                    </TableCell>
                    {displayColumns.map((title, columnIndex: number) => (
                      <TableCell
                        align={"left"}
                        className="tableCell"
                        key={columnIndex}
                      >
                        {renderCell ? (
                          renderCell(dataItem, title)
                        ) : linkColumn === title ? (
                          <Link
                            className="link"
                            to={linkUrl + `/${dataItem.id}`}
                          >
                            {dataItem[title]}
                          </Link>
                        ) : typeof dataItem[title] == "boolean" ? (
                          <>
                            <strong
                              className={
                                dataItem[title] ? "chipEnabled" : "chipDisabled"
                              }
                            >
                              {booleanStatus?.[title]?.[dataItem[title as any]]}
                            </strong>
                            {/* <Chip
                              label={
                                booleanStatus?.[title]?.[dataItem[title as any]]
                              }
                              variant="outlined"
                              className={
                                dataItem[title] ? "chipEnabled" : "chipDisabled"
                              }
                            /> */}
                          </>
                        ) : title === "createdAt" ? (
                          new Date(dataItem[title]).toLocaleString()
                        ) : linkIconColumn && title === linkIconColumn ? (
                          <span>
                            <IconButton
                              onClick={() => {
                                window.open(dataItem[title], "_blank");
                              }}
                            >
                              {icon}
                            </IconButton>
                          </span>
                        ) : (
                          <span>
                            {dataItem[title]?.length > 35 ? (
                              <span>
                                {dataItem[title].slice(0, 35)}
                                <Tooltip title={dataItem[title]} arrow>
                                  <span className="readMore">
                                    {"....."}
                                    Read more
                                  </span>
                                </Tooltip>
                              </span>
                            ) : (
                              dataItem[title]
                            )}
                          </span>
                        )}
                      </TableCell>
                    ))}
                    {deleteApiUrl || extraAction ? (
                      <TableCell align="center" className="tableCell">
                        {deleteApiUrl ? (
                          <IconButton
                            id={`delete-${entityName}-${dataItem.id}`}
                            className={
                              dataItem?.deleteButtonStatus || disabled
                                ? ""
                                : "deleteIcon"
                            }
                            disabled={dataItem?.deleteButtonStatus || disabled}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeletedRecord(dataItem);
                            }}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        ) : null}
                        {extraAction ? (
                          <IconButton
                            id={`delete-${entityName}-${dataItem.id}`}
                            className={disabled ? "" : "deleteIcon"}
                            disabled={disabled}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeletedRecord(dataItem);
                            }}
                          >
                            {extraAction}
                          </IconButton>
                        ) : null}
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Grid className="paginationGrid">
            <PaginationComponent
              totalItems={totalItems}
              pagination={pagination}
              setPagination={setPagination}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default DataTable;
