export const getCertificateRequestFields = (
  certificateRequestData: any,
  fieldDetail: string[],
  certificateLanguage?: string
) => {
  const pascalToSpace = (text: string) => {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/^./, (str: any) => str.toUpperCase());
  };

  const dateFields = [
    "certificateDate",
    "marriageDate",
    "date",
    "fatherDeathDate",
    "motherDeathDate",
    "deathDate",
  ];

  const filteredFields = fieldDetail?.filter(
    (field) => field !== "heirDetails" && field !== "relationDetail"
  );

  return filteredFields?.map((field) => ({
    id: field,
    name: field,
    label: dateFields?.includes(field)
      ? `${pascalToSpace(field)}:`
      : `${pascalToSpace(field)}:`,
    value: certificateRequestData[field],
    type: "text",
    placeholder: dateFields?.includes(field)
      ? `"DD/MM/YYYY" `
      : `Enter ${pascalToSpace(field)}`,
    required: true,
  }));
};

export const getCertificateRequestSubFields = (
  certificateRequestData: any[],
  fieldDetail: string[],
  type?: string
) => {
  const pascalToSpace = (text: string) => {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/^./, (str: any) => str.toUpperCase());
  };

  const fields = certificateRequestData?.map((dataItem, index) =>
    fieldDetail.map((field) => ({
      id: `${index + 1}-${field}`,
      name: `${type}[${index}].${field}`,
      label:
        field === "age" || "memberId"
          ? `${pascalToSpace(field)}:`
          : `${pascalToSpace(field)}:`,
      value: dataItem[field],
      type: "text",
      placeholder: `Enter ${pascalToSpace(field)}`,
      required: true,
    }))
  );

  return fields;
};

export const pascalToSpace = (text: string) => {
  return text
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
    .replace(/^./, (str: any) => str.toUpperCase());
};
