import moment from "moment";
export const getTenderFields = (tenderData: any) => [
  {
    id: "tender-title",
    name: "title",
    label: "Title:",
    type: "text",
    value: tenderData?.title,
    placeholder: "Title",
    required: true,
  },

  {
    id: "tender-startDate",
    name: "startDate",
    label: "Start Date:",
    value: moment(tenderData?.startDate).format("DD/MM/YYYY"),
    formField: false,
  },
  {
    id: "tender-endDate",
    label: "End Date:",
    value: moment(tenderData?.endDate).format("DD/MM/YYYY"),
    formField: false,
  },
  {
    id: "tenderStatus",
    label: "Tender Status :",
    value: tenderData?.tenderStatus,
    formField: false,
  },
  {
    id: "publishedBy",
    label: "Published By :",
    value: tenderData?.publishedByUser?.admin?.fullName,
    formField: false,
  },
  // {
  //   id: "tender-description",
  //   name: "description",
  //   label: "Description :",
  //   value: (
  //     <div dangerouslySetInnerHTML={{ __html: tenderData?.description }} />
  //   ),
  //   formField: false,
  // },
];
