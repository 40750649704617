import { FC, useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { useFormik } from "formik";

import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";

import { TitleBarProps } from "../../../../../shared/components/titleBar";

import useMutation from "../../../../../shared/components/hooks/useMutation";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";

import CloseIcon from "@mui/icons-material/Close";

import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { getWelfareFields } from "./welfareEditFields";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import {
  PayoutTypeOptions,
  WelfareCategoryOptions,
  WelfareStatusOptions,
  WelfareTypeOptions,
} from "../../../../../shared/constants/selectOptions";

const WelfareEditView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/welfare-schemes/${id}`,
    null
  );

  const {
    loading: saveApiLoading,
    error: saveError,
    modifyData,
  } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Welfare scheme-Update")
    : true;

  const welfareData = (data as any)?.data || {};

  const welfareFields = getWelfareFields(welfareData);

  const welfareForm = useFormik({
    initialValues: {
      welfareCategory: welfareData?.welfareCategory ?? null,
      schemeName: welfareData?.schemeName ?? "",
      type: welfareData?.type ?? null,
      allotedAmount: welfareData?.allotedAmount ?? "",
      payoutType: welfareData?.payoutType ?? null,
      nameOfMaterial: welfareData?.nameOfMaterial ?? "",
      count: welfareData?.count ?? "",
      description: welfareData?.description ?? "",
      status: welfareData?.status ?? "",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "welfareCategory",
      "type",
      "schemeName",
      "status",
      "allotedAmount",
    ]),
    onSubmit: async (formData: any, { resetForm }) => {
      modifyData(
        `/admin/welfare-schemes/${id}`,
        "put",
        {
          ...formData,
        },
        welfareForm
      );
      if (saveError) {
        return;
      }
      resetForm();
      fetchData();

      setEditStatus(false);
    },
  });

  const handleCloseEdit = () => {
    welfareForm.resetForm();
    setEditStatus(false);
  };

  const handleEdit = () => {
    setEditStatus(!editStatus);
  };

  const viewTitleBar: TitleBarProps = {
    title: "Welfare scheme ",
    headerTitle: "Welfare scheme",
  };

  const editTitlebar: TitleBarProps = {
    title: "Welfare scheme",
    headerTitle: "Welfare scheme",
    buttonTitle: "Save",
    onClick: () => welfareForm.handleSubmit(),
    dirty: welfareForm.dirty,
    buttonLoading: loading,
  };

  const titleBarNode = (
    <>
      <Tooltip arrow title="Cancel">
        <IconButton
          className="TitleBarButton"
          size="medium"
          id={`scheme-close`}
          onClick={handleCloseEdit}
          aria-label={`scheme-close`}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const requestTooltipNode = editPermission && (
    <Tooltip arrow title="Edit">
      <IconButton
        className="TitleBarButton"
        size="medium"
        id={`scheme-edit`}
        onClick={handleEdit}
        aria-label={`scheme-edit`}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {editStatus ? (
        <FormContainer
          loading={saveApiLoading}
          titleBar={editTitlebar}
          titleBarNode={titleBarNode}
        >
          <Box className="border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={2}
            >
              <Grid item md={6} sm={6} xs={12} key="welfare-category">
                <ZSSelectBox
                  fieldProps={{
                    label: "Category",
                    id: "welfareCategory",
                    name: "welfareCategory",
                    placeholder: "Select an option",
                    required: true,
                  }}
                  options={WelfareCategoryOptions}
                  form={welfareForm}
                  value={welfareForm.values?.welfareCategory}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={welfareForm}
                  fieldProps={{
                    id: "schemeName",
                    name: "schemeName",
                    label: "Scheme Name",
                    type: "text",
                    placeholder: "Enter Scheme Name",
                    required: true,
                    capitalizedFirst: true,
                  }}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12} key="welfare-type">
                <ZSSelectBox
                  fieldProps={{
                    label: "Welfare Type",
                    id: "type",
                    name: "type",
                    placeholder: "Select an option",
                    required: true,
                  }}
                  options={WelfareTypeOptions}
                  form={welfareForm}
                  value={welfareForm.values?.type}
                />
              </Grid>
              {welfareForm.values?.type === "Financial" ? (
                <>
                  <Grid item md={6} sm={6} xs={12}>
                    <InputTextfield
                      form={welfareForm}
                      fieldProps={{
                        id: "allotedAmount",
                        name: "allotedAmount",
                        label: "Alloted Amount",
                        type: "text",
                        placeholder: "Enter Alloted amount",
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} key="welfare-payout">
                    <ZSSelectBox
                      fieldProps={{
                        label: "Payout Type",
                        id: "payoutType",
                        name: "payoutType",
                        placeholder: "Select an option",
                        required: true,
                      }}
                      options={PayoutTypeOptions}
                      form={welfareForm}
                      value={welfareForm.values?.payoutType}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={6} sm={6} xs={12}>
                    <InputTextfield
                      form={welfareForm}
                      fieldProps={{
                        id: "nameOfMaterial",
                        name: "nameOfMaterial",
                        label: "Name of Material",
                        type: "text",
                        placeholder: "Enter Name of Material",
                        capitalizedFirst: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <InputTextfield
                      form={welfareForm}
                      fieldProps={{
                        id: "count",
                        name: "count",
                        label: "Count",
                        type: "text",
                        placeholder: "Enter Count",
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item md={6} sm={6} xs={12} key="welfare-status">
                <ZSSelectBox
                  fieldProps={{
                    label: "Status",
                    id: "status",
                    name: "status",
                    placeholder: "Select an option",
                    required: true,
                  }}
                  options={WelfareStatusOptions}
                  form={welfareForm}
                  value={welfareForm.values?.status}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={welfareForm}
                  multiline
                  fieldProps={{
                    id: "description",
                    name: "description",
                    label: "Description",
                    type: "text",
                    required: false,
                    maxLength: 255,
                    placeholder: "Enter description",
                    capitalizedFirst: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </FormContainer>
      ) : (
        <ViewContainer loading={loading} titleBar={viewTitleBar}>
          <SingleEntityContainer
            entityFields={welfareFields}
            entityTitle="Welfare scheme Details"
            tooltips={requestTooltipNode}
          ></SingleEntityContainer>
        </ViewContainer>
      )}
    </>
  );
};

export default WelfareEditView;
