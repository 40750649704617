export const getEventTypeEditFields = (eventTypeData: any) => [
  {
    id: "eventTypeName",
    name: "eventTypeName",
    type: "text",
    label: "Event Type",
    required: true,
    placeholder: " Enter Event Type Name",
    value: eventTypeData?.eventTypeName,
  },
];
