import FacilityBookingCreate from "../components/facilityBookingCreate";
import FacilityBookingList from "../components/facilityBookingList";
import FacilityBookingView from "../components/facilityBookingView";
import BusinessIcon from "@mui/icons-material/Business";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { Navigate } from "react-router-dom";
const facilityRoute = {
  icon: BusinessIcon,
  name: "Facility Management",
  link: "/facility-management",
  path: "facility-management",
  index: 40,
  element: "",
  children: [
    {
      path: "facility-bookings",
      element: <FacilityBookingList />,
      name: "Facility Bookings",
      permission: "Facility Bookings-Read",
      index: 1,
      link: "facility-management/facility-bookings",
      icon: AppRegistrationIcon,
    },
    {
      path: "facility-bookings/create",
      element: <FacilityBookingCreate />,
    },
    {
      path: "facility-bookings/:id",
      element: <FacilityBookingView />,
    },
    {
      path: "",
      element: <Navigate to="facility-bookings" replace />,
    },
  ],
};
export default facilityRoute;
