import { useNavigate } from "react-router-dom";
import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import { Administrator } from "../../../../../shared/interfaces/administrator";

const AdministratorList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery(
    "/admin/administrators",
    {
      params: {
        ...payload,
      },
    }
  );

  const onCreate = () => {
    navigate("/userManagement/administrators/create");
  };

  let adminList: Administrator[] = [];
  if (data && (data as any)?.items) {
    adminList = (data as any)?.items.map((item: Administrator) => {
      return {
        id: item.id,
        fullName: item?.fullName,
        email: item?.email,
        phone: item?.phone,
      };
    });
  }
  const adminDisplayColumns = ["fullName", "email", "phone"];

  const titleBarNode = <SearchBox />;
  const titleBar = {
    title: "Administrators",
    headerTitle: "Administrators",
    buttonTitle: "Create",
    titleBarNode: titleBarNode,
    onClick: onCreate,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Administrators-Create")
      : false,
  };

  return (
    <>
      <TableViewContainer titleBar={titleBar}>
        {loading && <Loader />}
        {(error as any)?.statusCode === 403 && <NoPermissionContent />}
        {!loading && !error && (
          <DataTable
            entityName="Users"
            data={adminList}
            displayColumns={adminDisplayColumns}
            linkColumn="firstName"
            linkUrl="/userManagement/administrators"
            pagination={{ take: payload.take, skip: payload.skip }}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            deleteApiUrl="/admin/administrators"
            refetchFunction={fetchData}
            disabled={
              (data as any)?.entityPermissions
                ? !(data as any)?.entityPermissions?.includes(
                    "Administrators-Delete"
                  )
                : false
            }
          />
        )}
      </TableViewContainer>
    </>
  );
};

export default AdministratorList;
