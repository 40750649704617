import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Navigate } from "react-router-dom";
import AdministratorList from "../components/administratorList";
import AdministratorCreate from "../components/administratorCreate";
import AdministratorEditView from "../components/administratorEditView";
const administratorRoute = {
  icon: PersonIcon,
  name: "User Management",
  link: "/userManagement",
  path: "userManagement",
  element: "",
  index: 990,
  children: [
    {
      path: "administrators",
      element: <AdministratorList />,
      name: "Administrators",
      permission: "Administrators-Read",
      link: "/userManagement/administrators",
      icon: ManageAccountsIcon,
      index: 0,
    },
    {
      path: "administrators/create",
      element: <AdministratorCreate />,
    },
    {
      path: "administrators/:id",
      element: <AdministratorEditView />,
    },
    {
      path: "",
      element: <Navigate to="administrators" replace />,
    },
  ],
};
export default administratorRoute;
