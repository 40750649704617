import moment from "moment";

export const getEventEditFields = (eventData: any) => [
  {
    label: "Event Type:",
    value: eventData?.eventType?.eventTypeName,
    formField: false,
  },
  {
    id: "name",
    name: "name",
    label: "Event Name",
    type: "text",
    placeholder: "Event Name:",
    required: true,
    value: eventData?.name,
    columnPreference: 2,
  },
  {
    label: "End Date:",
    value: moment(eventData?.endDate).format("DD/MM/YYYY"),
    formField: false,
  },
  {
    label: "Start Date:",
    value: moment(eventData?.startDate).format("DD/MM/YYYY"),
    formField: false,
  },
  {
    label: "End Time:",
    value: moment.utc(eventData.endTime, "HH:mm:ss").format("hh:mm A"),
    formField: false,
  },
  {
    label: "Start Time:",
    value: moment.utc(eventData.startTime, "HH:mm:ss").format("hh:mm A"),
    formField: false,
  },

  {
    label: "Sent notifications:",
    value: eventData?.notify ? "Yes" : "No",
    formField: false,
  },
  {
    label: "Sent astro notifications:",
    value: eventData?.astroNotify ? "Yes" : "No",
    formField: false,
  },
  {
    label: "Status:",
    value: eventData?.eventState,
    formField: false,
  },
  {
    id: "location",
    name: "location",
    label: "Venue:",
    type: "text",
    placeholder: "Venue",
    required: true,
    value: eventData?.location,
  },

  {
    id: "description",
    name: "description",
    label: "Description:",
    type: "text",
    placeholder: "Description",
    value: eventData?.description,
    multiline: true,
  },
];
