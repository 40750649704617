import { FC, useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { LoadingButton } from "@mui/lab";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";

import { TitleBarProps } from "../../../../../shared/components/titleBar";
import { getJobRequestFields } from "./jobRequestFields";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import { JobApplicationStatus } from "../../../../../shared/enum";

const JobRequestEditView: FC = () => {
  const { id } = useParams();

  const [textContent, setTextContent] = useState<{ type: String; value: any }>({
    type: "",
    value: "",
  });
  const [openConfirmationModalData, setOpenConfirmationModalData] = useState<{
    status: boolean;
    message: string;
    applicationStatus: string;
  }>({ status: false, message: "", applicationStatus: "" });

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/job-requests/${id}`,
    null
  );

  const { error: saveError, modifyData } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const jobRequestData = (data as any)?.data || {};
  const jobRequestFields = getJobRequestFields(jobRequestData);

  const jobRequestApprovePermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Job Request-Shortlist")
    : true;

  const handleClose = () => {
    setOpenConfirmationModalData({
      status: false,
      message: "",
      applicationStatus: "",
    });
    setTextContent((prev: any) => ({
      type: "",
      value: "",
    }));
  };
  const handleSubmit = async (applicationStatus: boolean) => {
    if (applicationStatus === false) {
      handleClose();
      setTextContent((prev: any) => ({
        type: "",
        value: "",
      }));
    } else {
      const response = await modifyData(
        `/admin/job-requests/update-job-status/${id}`,
        "put",
        {
          applicationStatus: openConfirmationModalData.applicationStatus,
          remarks: textContent.value || "",
        }
      );
      if (saveError) {
        return;
      }
      if (response) {
        fetchData();
        handleClose();
      }
    }
  };
  const handleDocView = (fileUrl: string | URL) => {
    console.log("fileUrl  >>>>>>>>>  ", fileUrl);
    window.open(fileUrl);
  };

  const viewTitleBar: TitleBarProps = {
    title: "Verification",
    headerTitle: "Job Request",
  };
  const documentView = (
    <Box className="border-custom">
      <Typography sx={{ mb: 1 }} component="span">
        Resume Url: &nbsp;&nbsp;
        <Tooltip arrow title="View Document">
          <Link
            onClick={() => handleDocView(jobRequestData?.resumeUrl)}
            to={""}
          >
            <DocumentScannerIcon sx={{ mb: -0.4 }} />
          </Link>
        </Tooltip>
      </Typography>
    </Box>
  );
  const titleBarNode = (
    <>
      {jobRequestData?.applicationStatus === "Pending" &&
        jobRequestApprovePermission && (
          <>
            <LoadingButton
              className="TitleBarButton"
              loading={false}
              variant="contained"
              size="small"
              id="job-request-approve"
              onClick={() => {
                setOpenConfirmationModalData({
                  status: true,
                  message:
                    "Are you sure you want to shortlist this application ?",
                  applicationStatus: JobApplicationStatus.Shortlisted,
                });
                setTextContent((prev: any) => ({
                  ...prev,
                  type: "text",
                }));
              }}
            >
              Shortlist
            </LoadingButton>

            <LoadingButton
              className="TitleBarButton"
              loading={false}
              variant="contained"
              size="small"
              id="job-request-reject"
              onClick={() => {
                setOpenConfirmationModalData({
                  status: true,
                  message: "Are you sure you want to reject this application ?",
                  applicationStatus: JobApplicationStatus.Rejected,
                });
                setTextContent((prev: any) => ({
                  ...prev,
                  type: "text",
                }));
              }}
            >
              Reject
            </LoadingButton>
          </>
        )}
    </>
  );

  return (
    <>
      <ViewContainer
        loading={loading}
        titleBar={viewTitleBar}
        titleBarNode={titleBarNode}
      >
        <SingleEntityContainer
          children={jobRequestData?.resumeUrl ? documentView : null}
          entityFields={jobRequestFields}
          entityTitle="Job Request Details"
        />
      </ViewContainer>

      <ConfirmDialog
        title={
          openConfirmationModalData?.applicationStatus ===
          JobApplicationStatus.Rejected
            ? "Confirm Rejection"
            : "Confirm Shortlist "
        }
        openConfirmDialog={openConfirmationModalData.status}
        setOpenConfirmDialog={handleClose}
        message={openConfirmationModalData.message}
        handleSubmit={handleSubmit}
        setTextContent={setTextContent}
        label={
          openConfirmationModalData?.applicationStatus ===
          JobApplicationStatus.Rejected
            ? "Rejection Reason"
            : "Reason for Shortlist"
        }
        textContent={textContent}
      />
    </>
  );
};

export default JobRequestEditView;
