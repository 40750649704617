/* eslint-disable react-hooks/exhaustive-deps */

import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { FC, useContext } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { CustomDatePicker } from "../../../../../shared/components/datePicker";
import { AvailabilityStatus } from "../../../../../shared/enum";

import moment from "moment";

const FacilityBookingCreate: FC = () => {
  const { id } = useParams();
  const { payload, setPayload } = useContext(SidebarContext);
  const { loading: apiLoading, modifyData } = useMutation(
    "/facility-management/facility-bookings"
  );
  const { data } = useLazyQuery(`/admin/facility/${id}`);
  const { data: faciltyData } = useQuery("/admin/facility", {
    params: {
      ...payload,
      availabilityStatus: AvailabilityStatus.Available,
      take: 10,
      sortOrder: "ASC",
    },
  });
  const today = moment().startOf("day");

  const selectedFacilityName: any =
    data && (data as any)?.data?.facilityName
      ? {
          value: (data as any)?.data?.facilityID,
          label: (data as any)?.data?.facility?.name,
        }
      : null;

  const eventTypes = (faciltyData as any)?.items?.map((facilityName: any) => ({
    label: facilityName?.name,
    value: facilityName?.id,
  }));
  const facilityForm = useFormik({
    initialValues: {
      facilityID: "",
      memberID: null,
      bookingStartDate: "",
      bookingEndDate: "",
      bookingStatus: "Approved",
      remarks: "",
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "facilityID",
      "bookingStartDate",
      "bookingEndDate",
    ]),
    validate: (values) => {
      const errors: any = {};
      if (
        values.bookingStartDate &&
        moment(values.bookingStartDate).isBefore(today)
      ) {
        errors.bookingStartDate = "Start date cannot be in the past";
      }
      if (
        values.bookingEndDate &&
        moment(values.bookingEndDate).isBefore(today)
      ) {
        errors.bookingEndDate = "End date cannot be in the past";
      }
      if (
        values.bookingEndDate &&
        moment(values.bookingEndDate).isBefore(values.bookingStartDate)
      ) {
        errors.bookingEndDate = "End date cannot be before start date";
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `admin/facility-booking/${id}`,
          "put",
          {
            ...formData,
          },
          facilityForm
        );
      } else {
        modifyData(
          `admin/facility-booking`,
          "post",
          {
            ...formData,
          },
          facilityForm
        );
      }
    },
  });
  const handleStartDateChange = (newValue: any) => {
    const newDate = newValue ? moment(newValue).format("yyyy-MM-DD") : null;
    facilityForm.setFieldTouched("bookingStartDate", true);
    facilityForm.setFieldValue("bookingStartDate", newDate);
  };

  const handleEndDateChange = (newValue: any) => {
    const newDate = newValue ? moment(newValue).format("yyyy-MM-DD") : null;
    facilityForm.setFieldTouched("bookingEndDate", true);
    facilityForm.setFieldValue("bookingEndDate", newDate);
  };
  const titleBar = {
    headerTitle: "Facility Booking - Create",
    title: "Create Facility Booking",
    buttonTitle: "Save",
    onClick: () => facilityForm.handleSubmit(),
    dirty: facilityForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12} key="facilityBooking-facilityID">
            <ZSAutocomplete
              options={eventTypes}
              placeholder="Select Facility Name"
              label="Facility Name"
              required
              setValue={(selectedOption: any) => {
                facilityForm.setFieldTouched("facilityID", true);
                if (selectedOption?.value) {
                  facilityForm.setFieldValue(
                    "facilityID",
                    selectedOption?.value
                  );
                } else {
                  facilityForm.setFieldValue("facilityID", "");
                }
              }}
              defaultValue={selectedFacilityName}
              setBlur={() => {
                facilityForm.setFieldTouched("facilityID", true);
              }}
              errorMessage={
                ((facilityForm.touched as any)?.facilityID &&
                  (facilityForm.errors as any)?.facilityID) ??
                null
              }
              refetchFunction={(searchString: string) => {
                setPayload((prevData: any) => {
                  return { ...prevData, search: searchString };
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            key="facilityBooking-bookingStartDate"
          >
            <CustomDatePicker
              onChange={(date: any) => handleStartDateChange(date)}
              placeholder="Start Date"
              label="Start Date"
              name="bookingStartDate"
              // minDate={today.toDate()}
              required
              value={
                facilityForm.values.bookingStartDate
                  ? new Date(facilityForm.values.bookingStartDate)
                  : null
              }
              errorMessage={
                ((facilityForm?.touched as any)?.bookingStartDate &&
                  (facilityForm?.errors as any)?.bookingStartDate) ??
                ""
              }
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="facilityBooking-bookingEndDate">
            <CustomDatePicker
              onChange={(date: any) => handleEndDateChange(date)}
              placeholder="End Date"
              label="End Date"
              name="bookingEndDate"
              required
              value={
                facilityForm.values.bookingEndDate
                  ? new Date(facilityForm.values.bookingEndDate)
                  : null
              }
              errorMessage={
                ((facilityForm?.touched as any)?.bookingEndDate &&
                  (facilityForm?.errors as any)?.bookingEndDate) ??
                ""
              }
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} key="facility-remarks">
            <InputTextfield
              form={facilityForm}
              multiline
              fieldProps={{
                id: "remarks",
                name: "remarks",
                maxLength: 255,
                label: "remarks",
                type: "text",
                placeholder: "Remarks",
                capitalizedFirst: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </FormContainer>
  );
};
export default FacilityBookingCreate;
