import "./styles.css";
import { FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { InputLabel } from "@mui/material";
import { ErrorMessage } from "../errorMessage";
import moment from "moment";

type TimePickerProps = {
  id?: string;
  name: string;
  format?: string;
  views?: any;
  label?: string;
  value?: Date | null;
  onChange?: (date: string) => void;
  onClose?: Function;
  errorMessage?: string;
  required?: boolean;
  disableFuture?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  readOnly?: boolean;
  form?: any;
};
export const CustomTimePicker: FC<TimePickerProps> = ({
  id,
  name,
  views = ["hours", "minutes"],
  label,
  value,
  onChange,
  onClose,
  errorMessage,
  required,
  disableFuture,
  placeholder,
  form,
}) => {
  const handleTimeChange = (newTime: any) => {
    const formattedTime = newTime ? moment(newTime, "HH:mm:ss").toDate() : null;
    form.setFieldValue(name, formattedTime);
    if (onChange) {
      onChange(newTime);
    }
  };
  return (
    <div className="timePickerContainer" id={id}>
      {label && (
        <InputLabel>
          {label}
          {required ? <span style={{ color: "red" }}> * </span> : ""}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          value={value}
          views={views}
          disableFuture={disableFuture}
          onChange={handleTimeChange}
          onClose={() => (onClose ? onClose() : {})}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
          }}
          slotProps={{
            field: { clearable: true },
            textField: {
              error:
                (form?.touched as any)?.[name] && (form?.errors as any)?.[name],
              InputProps: {
                size: "medium",
                placeholder: placeholder,
                name: name,
              },
            },
          }}
        />
      </LocalizationProvider>
      <ErrorMessage
        message={
          ((form?.touched as any)?.[name] && (form?.errors as any)?.[name]) ??
          ""
        }
      />
    </div>
  );
};
