export const getMemberEditFields = (memberData: any) => [
  {
    id: "fullNameEng",
    name: "fullNameEng",
    label: "Full Name English:",
    value: memberData?.fullNameEng,
    type: "text",
    placeholder: "Enter your Name in English",
    required: true,
  },

  {
    label: "Member Code:",
    value: memberData?.code,
    formField: false,
  },
  {
    label: "DOB:",
    value: memberData?.dob
      ? new Date(memberData.dob).toLocaleDateString("en-GB")
      : "",
    formField: false,
  },

  {
    label: "Gender:",
    value: memberData?.gender,
    formField: false,
  },
  {
    id: "fullNameMal",
    name: "fullNameMal",
    label: "Full Name Malayalam:",
    value: memberData?.fullNameMal,
    type: "text",
    placeholder: "Enter your Name in Malayalam",
    required: true,
  },
  {
    id: "email",
    name: "email",
    label: "Email:",
    value: memberData?.email ?? "Not Available",
    type: "text",
    placeholder: "Enter your Email",
    required: false,
  },
  {
    id: "phone",
    name: "phone",
    label: "Phone:",
    value: memberData?.phone,
    type: "text",
    placeholder: "Enter your Phone",
    required: false,
  },

  {
    id: "sndpMemberRegNo",
    name: "sndpMemberRegNo",
    label: "SNDP Member Reg No:",
    value:
      memberData?.sndpMemberRegNo === "" || memberData?.sndpMemberRegNo === null
        ? "Not Available"
        : memberData?.sndpMemberRegNo,
    type: "text",
    placeholder: "Enter your SNDP Member Reg No",
    required: false,
  },
  {
    label: "DOB in Malyalam:",
    value: getDobMal(memberData?.dobMal),
    formField: false,
  },
  {
    id: "joinedSangamNo",
    name: "joinedSangamNo",
    label: "Joined Sangam No:",
    value:
      memberData?.joinedSangamNo === "" || memberData?.joinedSangamNo === null
        ? "Not Available"
        : memberData?.joinedSangamNo,
    type: "text",
    placeholder: "Enter your Joined Sangam No",
    required: false,
  },
  {
    label: "ID Proof Type:",
    value: memberData?.idProofType ?? "Not Available",
    formField: false,
  },
  {
    id: "idProofNumber",
    name: "idProofNumber",
    label: "ID Proof Number",
    value:
      memberData?.idProofNumber === "" || memberData?.idProofNumber === null
        ? "Not Available"
        : memberData?.idProofNumber,
    type: "text",
    placeholder: "Enter ID Proof Number",
    required: false,
  },
  {
    label: "Annual Income:",
    value: memberData?.annualIncome ?? "Not Available",
    formField: false,
  },
  {
    label: "Union Membership Status:",
    value: memberData?.unionMembershipStatus,
    formField: false,
  },

  // {
  //   id: "unionMembershipStatus",
  //   name: "unionMembershipStatus",
  //   label: "",
  //   value: memberData?.unionMembershipStatus,
  //   type: "text",
  //   placeholder: "Enter your Union Membership Status",
  //   required: false,
  // },
];

const getYear = (dob: any): string => {
  return dob?.kollavarsham || "";
};

const getMonth = (dob: any): string => {
  return dob?.month || "";
};

const getDate = (dob: any): string => {
  return dob?.date || "";
};

const getDobMal = (dob: any): string => {
  const date = getYear(dob) + " - " + getMonth(dob) + " - " + getDate(dob);
  if (!getYear(dob) && !getMonth(dob) && !getDate(dob)) {
    return "Not Available";
  }
  return date;
};
