import { Navigate } from "react-router-dom";
import OngoingSchemeList from "../components/ongoingSchemeList";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import OngoingSchemeEdit from "../components/ongoingSchemeEdit";

const welfareRoute = {
  icon: Diversity3Icon,
  name: "Welfare Scheme",
  link: "/welfare-schemes",
  path: "welfare-schemes",
  element: "",
  index: 70,
  children: [
    {
      path: "ongoing-schemes",
      element: <OngoingSchemeList />,
      name: "Ongoing Schemes",
      index: 2,
      permission: "Welfare request-Read",
      link: "welfare-schemes/ongoing-schemes",
      icon: Diversity3Icon,
    },

    {
      path: "ongoing-schemes/:id",
      element: <OngoingSchemeEdit />,
    },
    {
      path: "",
      element: <Navigate to="welfare" replace />,
    },
  ],
};
export default welfareRoute;
