import { FC, useContext, useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { useFormik } from "formik";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import CloseIcon from "@mui/icons-material/Close";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { getEventTypeEditFields } from "./eventTypeFields";
import { EntityContext } from "../../../../../contexts/EntityContext";

const EventTypeEditView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState(false);
  const { setEntityName } = useContext(EntityContext);
  const { loading, data, fetchData } = useLazyQuery(
    `/admin/event-type/${id}`,
    null
  );

  const {
    loading: saveApiLoading,
    error: saveError,
    modifyData,
  } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setEntityName({
      entityName: ["Edit"],
      entityTitle: "Edit Event Type",
    });
  }, [setEntityName]);

  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("EventType-Update")
    : true;

  const eventTypeData = (data as any)?.data || {};
  const eventTypeFields = getEventTypeEditFields(eventTypeData);
  const eventTypeForm = useFormik({
    initialValues: {
      eventTypeName: eventTypeData?.eventTypeName,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema(["eventTypeName"]),
    onSubmit: async (formData: any, { resetForm }) => {
      modifyData(
        `/admin/event-type/${id}`,
        "put",
        {
          ...formData,
        },
        eventTypeForm
      );
      if (saveError) {
        return;
      }
      fetchData();
      resetForm();

      setEditStatus(false);
    },
  });

  const handleCloseEdit = () => {
    eventTypeForm.resetForm();
    setEditStatus(false);
  };

  const handleEdit = () => {
    setEditStatus(!editStatus);
  };

  const viewTitleBar: TitleBarProps = {
    title: "Event Type",
    headerTitle: " Edit",
  };

  const editTitlebar: TitleBarProps = {
    title: "Event Type",
    headerTitle: "Event Type Edit",
    buttonTitle: "Save",
    onClick: () => eventTypeForm.handleSubmit(),
    dirty: eventTypeForm.dirty,
    buttonLoading: loading,
  };

  const titleBarNode = (
    <>
      <Tooltip arrow title="Cancel">
        <IconButton
          className="TitleBarButton"
          size="medium"
          id={`event-type-edit`}
          onClick={handleCloseEdit}
          aria-label={`event-type-edit`}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const requestTooltipNode = editPermission && (
    <Tooltip arrow title="Edit">
      <IconButton
        className="TitleBarButton"
        size="medium"
        id={`event-type-edit`}
        onClick={handleEdit}
        aria-label={`event-type-edit`}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {editStatus ? (
        <FormContainer
          loading={saveApiLoading}
          titleBar={editTitlebar}
          titleBarNode={titleBarNode}
        >
          <Box className="border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={2}
            >
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={eventTypeForm}
                  fieldProps={{
                    id: "eventTypeName",
                    name: "eventTypeName",
                    type: "text",
                    required: true,
                    placeholder: "Event Type Name",
                    capitalizedFirst: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </FormContainer>
      ) : (
        <ViewContainer loading={loading} titleBar={viewTitleBar}>
          <SingleEntityContainer
            entityFields={eventTypeFields}
            entityTitle="Event Type  Details"
            tooltips={requestTooltipNode}
          ></SingleEntityContainer>
        </ViewContainer>
      )}
    </>
  );
};
export default EventTypeEditView;
