import { useFormik } from "formik";
import { Box, Checkbox, Grid, InputLabel } from "@mui/material";
import { FC, useState } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { CheckBoxOutlined } from "@mui/icons-material";
import {
  dateInputFromatHelper,
  dateToISOString,
  validateDateFormat,
  validateNotFutureDate,
} from "../../../../../shared/utils/date-helper";
import { MicroFinanceUnit, KudumbaUnit } from "../../../../../shared/enum";
import {
  residenceTypeOptions,
  rationCardOptions,
} from "../../../../../shared/constants/selectOptions";
import { Lsgd } from "../../../../../shared/interfaces/lsgd";
import { getAddressFields } from "./addressFields";

const CreateFamily: FC = () => {
  const { loading: apiLoading, modifyData } = useMutation(
    "/branchMembership/families"
  );
  const [selectedMicroFinance, setSelectedMicroFinance] = useState(null);
  const [selectedKudumbaUnit, setSelectedKudumbaUnit] = useState(null);

  const { data: lsgdsData } = useQuery(`/admin/lsgds`);

  const lsgdsList = (lsgdsData as any)?.items.map((lsgds: Lsgd) => ({
    value: lsgds?.id,
    label: `${lsgds?.districtEng}, ${lsgds?.talukEng},  ${lsgds?.panchayathEng},  Ward No - ${lsgds?.wardNo}`,
  }));

  let microFinanceList = Array.from(
    Object.entries(MicroFinanceUnit),
    ([key, value]) => ({
      label: value,
      value: value,
    })
  );

  let KudumbaUnitList = Array.from(
    Object.entries(KudumbaUnit),
    ([key, value]) => ({
      label: value,
      value: value,
    })
  );

  const addressFields = getAddressFields();
  const familyForm = useFormik({
    initialValues: {
      houseNo: "",
      houseNameEng: "",
      houseNameMal: "",
      streetNameEng: "",
      streetNameMal: "",
      rationCardNumber: "",
      rationCardType: null,
      kudumbaUnitNameEng: null,
      kudumbaUnitNameMal: null,
      microFinanceNameEng: "",
      microFinanceNameMal: null,
      residenceType: null,
      currentAddress: true,
      dateOfResidence: "",
      joinedDate: "",
      annualIncome: "",
      addressID: null,
      memberID: null,
      wardNo: null,
      ownLand: false,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "houseNo",
      "houseNameEng",
      "streetNameEng",
      "wardNo",
      "joinedDate",
      // "dateOfResidence",
      "residenceType",
      "houseNameMal",
      "streetNameMal",
      "rationCardNumber",
      "kudumbaUnitNameEng",
      "annualIncome",
    ]),
    validate: (values: any) => {
      const errors: any = {};

      if (values?.joinedDate) {
        const isValidDateFormat = validateDateFormat(
          values.joinedDate,
          "dd/MM/yyyy"
        );
        if (!isValidDateFormat) {
          errors.joinedDate = "Date is not valid";
        } else {
          const isValidAndNotFuture = validateNotFutureDate(
            values.joinedDate,
            "dd/MM/yyyy"
          );
          if (!isValidAndNotFuture) {
            errors.joinedDate = "Date Of Joining cannot be a future date";
          }
        }
      }

      if (values?.dateOfResidence) {
        const isValidDate = validateDateFormat(
          values?.dateOfResidence,
          "dd/MM/yyyy"
        );
        if (!isValidDate) {
          errors.dateOfResidence = "Invalid Date format ( DD/MM/YYYY )";
        } else {
          const isValidAndNotFuture = validateNotFutureDate(
            values?.dateOfResidence,
            "dd/MM/yyyy"
          );
          if (!isValidAndNotFuture) {
            errors.dateOfResidence =
              "Date of residance cannot be a future date";
          }
        }
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      if (!formData.dateOfResidence) {
        formData.dateOfResidence = null;
      }
      modifyData(
        `admin/family`,
        "post",
        {
          ...formData,
          dateOfResidence: dateToISOString(formData.dateOfResidence),
          joinedDate: dateToISOString(formData.joinedDate),
        },
        familyForm
      );
    },
  });

  const onSelectMicrpFinance = (value: any) => {
    setSelectedMicroFinance(value);
    let parts = value.split(" - ");
    let eng = parts[0];
    let mal = parts[1];
    familyForm.setFieldValue("microFinanceNameEng", eng);
    familyForm.setFieldValue("microFinanceNameMal", mal);
  };

  const onSelectKudumbaUnit = (value: any) => {
    setSelectedKudumbaUnit(value);
    let parts = value.split(" - ");
    let eng = parts[0];
    let mal = parts[1];
    familyForm.setFieldValue("kudumbaUnitNameEng", eng);
    familyForm.setFieldValue("kudumbaUnitNameMal", mal);
  };

  const handledateOfResidenceChange = (newValue: string) => {
    const formatedDate = dateInputFromatHelper(newValue, familyForm);
    familyForm.setFieldValue("dateOfResidence", formatedDate);
  };
  const handledateOfJoinChange = (newValue: string) => {
    const formatedDate = dateInputFromatHelper(newValue, familyForm);
    familyForm.setFieldValue("joinedDate", formatedDate);
  };

  const onCheckChange = () => {
    familyForm.setFieldValue("ownLand", !familyForm.values?.ownLand);
  };

  const titleBar = {
    title: "Create Family",
    headerTitle: "Families - Create",
    buttonTitle: "Save",
    onClick: () => familyForm.handleSubmit(),
    dirty: familyForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={familyForm}
              value={familyForm?.values?.joinedDate || ""}
              fieldProps={{
                label: "Date of Joining",
                id: "family-joinedDate",
                name: "joinedDate",
                type: "text",
                required: true,
                placeholder: "DD/MM/YYYY",
              }}
              onChange={handledateOfJoinChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={familyForm}
              value={familyForm?.values?.houseNo || ""}
              fieldProps={{
                id: "houseNo",
                name: "houseNo",
                label: "House No",
                type: "text",
                required: true,
                placeholder: "House No",
              }}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          {addressFields.map((field, index: number) => (
            <Grid item md={6} sm={6} xs={12} key={field.id}>
              <InputTextfield
                form={familyForm}
                fieldProps={field}
                key={index}
              />
            </Grid>
          ))}
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={familyForm}
              value={familyForm?.values?.dateOfResidence || ""}
              fieldProps={{
                id: "family-dateOfResidence",
                name: "dateOfResidence",
                label: "Date Of Residence",
                type: "text",
                required: true,
                placeholder: "DD/MM/YYYY",
              }}
              onChange={handledateOfResidenceChange}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="family-residenceType">
            <ZSSelectBox
              fieldProps={{
                id: "family-residenceType",
                label: "Residence Type",
                name: "residenceType",
                placeholder: "Select an option",
                required: true,
              }}
              options={residenceTypeOptions}
              form={familyForm}
              value={familyForm.values?.residenceType}
              errorMessage={
                (familyForm.touched as any)?.residenceType &&
                (familyForm.errors as any)?.residenceType
                  ? (familyForm.errors as any)?.residenceType
                  : null
              }
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="family-wardNo">
            <ZSSelectBox
              fieldProps={{
                id: "family-wardNo",
                name: "wardNo",
                label: "Ward No",
                placeholder: "Select an option",
                required: true,
              }}
              options={lsgdsList}
              form={familyForm}
              value={familyForm.values?.wardNo}
              errorMessage={
                ((familyForm.touched as any)?.wardNo &&
                  (familyForm.errors as any)?.wardNo) ??
                null
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12}>
            <ZSSelectBox
              fieldProps={{
                id: "family-kudumbaUnitNameEng",
                name: "kudumbaUnitNameEng",
                label: "Kudumba Unit",
                placeholder: "Select an option",
                required: true,
              }}
              // form={familyForm}
              options={KudumbaUnitList}
              value={selectedKudumbaUnit}
              onChange={onSelectKudumbaUnit}
              errorMessage={
                ((familyForm.touched as any)?.kudumbaUnitNameEng &&
                  (familyForm.errors as any)?.kudumbaUnitNameEng) ??
                null
              }
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ZSSelectBox
              fieldProps={{
                id: "family-microFinanceNameMal",
                name: "microFinanceNameMal",
                label: "Micro Finance Name",
                placeholder: "Select an option",
              }}
              options={microFinanceList}
              onChange={onSelectMicrpFinance}
              value={selectedMicroFinance}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={familyForm}
              value={familyForm?.values?.rationCardNumber || ""}
              fieldProps={{
                id: "family-rationCardNumber",
                name: "rationCardNumber",
                label: "Ration Card Number",
                type: "text",
                placeholder: "Ration Card Number",
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ZSSelectBox
              fieldProps={{
                id: "family-rationCardType",
                name: "rationCardType",
                label: "Ration Card Type",
                placeholder: "Select an option",
              }}
              options={rationCardOptions}
              form={familyForm}
              value={familyForm.values?.rationCardType}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={familyForm}
              value={familyForm?.values?.annualIncome || ""}
              fieldProps={{
                id: "family-annualIncome",
                name: "annualIncome",
                label: "Annual Income",
                type: "text",
                placeholder: "Annual Income",
              }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputLabel>Own Land</InputLabel>
            <Checkbox
              checkedIcon={<CheckBoxOutlined />}
              disableRipple
              checked={familyForm.values?.ownLand}
              onChange={onCheckChange}
            />
          </Grid>
        </Grid>
      </Box>
    </FormContainer>
  );
};
export default CreateFamily;
