import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";

const RoleList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/roles", {
    params: {
      ...payload,
    },
  });

  const navigateToDetails = () => {
    navigate("/user-management/roles/create");
  };

  const roleDisplayColumns = ["name", "enabled"];
  const titleBarNode = <SearchBox />;
  const titleBar = {
    title: "Roles",
    headerTitle: "Roles",
    buttonTitle: "Create",
    titleBarNode: titleBarNode,
    onClick: navigateToDetails,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Roles-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Roles"
          data={(data as any)?.items ?? []}
          displayColumns={roleDisplayColumns}
          linkColumn="name"
          linkUrl="/user-management/roles"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/roles"
          refetchFunction={fetchData}
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("Roles-Delete")
              : false
          }
        />
      )}
    </TableViewContainer>
  );
};

export default RoleList;
