import Course from "../components/course";
import { Navigate } from "react-router-dom";
import CourseList from "../components/courseList";
import SettingsIcon from "@mui/icons-material/Settings";
import SchoolIcon from "@mui/icons-material/School";
const courseRoute = {
  icon: SettingsIcon,
  name: "Settings",
  link: "/settings",
  path: "settings",
  element: "",
  index: 1000,
  children: [
    {
      path: "course",
      element: <CourseList />,
      name: "Course",
      index: 2,
      link: "/settings/course",
      icon: SchoolIcon,
    },
    {
      path: "course/create",
      element: <Course />,
    },
    {
      path: "course/:id",
      element: <Course />,
    },
    {
      path: "",
      element: <Navigate to="course" replace />,
    },
  ],
};
export default courseRoute;
