import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormContainer } from "../../../../../shared/components/formContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import InputTextfield from "../../../../../shared/components/textField";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import { CertificateRequestStatus } from "../../../../../shared/enum";
import CertificatePreview from "./CertificatePreview";
import {
  getCertificateRequestFields,
  getCertificateRequestSubFields,
  pascalToSpace,
} from "./certificateRequestFields";
import { customizeValidationSchema } from "./customSchema";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import { dateInputFromatHelper } from "../../../../../shared/utils/date-helper";
import moment from "moment";
import "./styles.css";

const MemberRequestEditView: FC = () => {
  const { id } = useParams();
  const [editStatus, setEditStatus] = useState<boolean>(false);
  const [textContent, setTextContent] = useState<{ type: String; value: any }>({
    type: "",
    value: "",
  });
  const [openConfirmationModalData, setOpenConfirmationModalData] = useState<{
    status: boolean;
    message: string;
    requestStatus: string;
  }>({ status: false, message: "", requestStatus: "" });
  const [previewContent, setPreviewContent] = useState<{
    certificateNumber: string;
    issueDate: string;
    editorValue: any;
    status: boolean;
  }>({
    certificateNumber: "",
    editorValue: null,
    status: false,
    issueDate: "",
  });
  const [certificateRequestSubEntityData, setCertificateRequestSubEntityData] =
    useState<{ subEntityData: any; type: string }>({
      subEntityData: null,
      type: "",
    });
  const {
    loading: fieldDataLoading,
    data: fieldDetail,
    fetchData: fetchFieldData,
  } = useLazyQuery(`/admin/certificate-request/${id}`, null);
  const navigate = useNavigate();
  const {
    loading: saveApiLoading,
    error: saveError,
    modifyData,
  } = useMutation();
  const certificateTypeData = (fieldDetail as any)?.data || {};

  const certificateApprovePermission = (fieldDetail as any)?.entityPermissions
    ? (fieldDetail as any)?.entityPermissions?.includes(
        "Certificate Request-Approve"
      )
    : true;
  const certificatePrintPermission = (fieldDetail as any)?.entityPermissions
    ? (fieldDetail as any)?.entityPermissions?.includes(
        "Certificate Request-Print"
      )
    : true;
  const certificateUploadPermission = (fieldDetail as any)?.entityPermissions
    ? (fieldDetail as any)?.entityPermissions?.includes(
        "Certificate Request-Upload"
      )
    : true;
  const editPermission = (fieldDetail as any)?.entityPermissions
    ? (fieldDetail as any)?.entityPermissions?.includes(
        "Certificate Request-Update"
      )
    : true;
  useEffect(() => {
    if (id) {
      fetchFieldData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (certificateTypeData) {
      let formattedDate = "";
      if (certificateTypeData?.certificateTemplateData?.issueDate) {
        formattedDate = moment(
          certificateTypeData?.certificateTemplateData?.issueDate
        ).format("DD/MM/YYYY");
      }
      setPreviewContent((prev) => ({
        ...prev,
        editorValue:
          certificateTypeData?.certificateTemplateData?.certificateString,
        certificateNumber:
          certificateTypeData?.certificateTemplateData?.certificateNumber ?? "",
        issueDate: formattedDate,
      }));
    }
    if (
      certificateTypeData?.certificateRequest?.certificateData?.heirDetails ||
      certificateTypeData?.certificateRequest?.certificateData?.relationDetail
    ) {
      const isTransferCertificate =
        certificateTypeData?.certificateRequest?.certificateType
          .certificateTypeName === "Transfer Certificate";
      const fields = isTransferCertificate
        ? ["name", "memberId", "relation"]
        : ["name", "age", "Son/Daughter"];
      const certificateRequestData = isTransferCertificate
        ? certificateTypeData?.certificateRequest?.certificateData
            ?.relationDetail
        : certificateTypeData?.certificateRequest?.certificateData?.heirDetails;
      const requestSubEntityData = getCertificateRequestSubFields(
        certificateRequestData,
        fields,
        isTransferCertificate ? "relationDetail" : "heirDetails"
      );
      setCertificateRequestSubEntityData({
        subEntityData: requestSubEntityData,
        type: isTransferCertificate ? "relationDetail" : "heirDetails",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateTypeData?.certificateRequest]);

  const approvalStatus =
    certificateTypeData?.certificateRequest?.requestStatus ===
      CertificateRequestStatus.Approved ||
    certificateTypeData?.certificateRequest?.requestStatus ===
      CertificateRequestStatus.Rejected ||
    certificateTypeData?.certificateRequest?.requestStatus ===
      CertificateRequestStatus.Generated;

  const certificateRequestEntityData = getCertificateRequestFields(
    certificateTypeData?.certificateRequest?.certificateData,
    certificateTypeData?.certificateRequest?.certificateData
      ? Object.keys(certificateTypeData.certificateRequest.certificateData)
      : [],
    certificateTypeData?.certificateRequest?.certificateLanguage
  );

  const certificateRequestForm = useFormik({
    initialValues: certificateTypeData?.certificateRequest?.certificateData,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: certificateTypeData?.certificateRequest?.certificateData
      ? customizeValidationSchema(
          Object.keys(certificateTypeData.certificateRequest.certificateData)
        )
      : null,
    onSubmit: async (formData: any, { resetForm }) => {
      const responseData = await modifyData(
        `/admin/certificate-request/${id}`,
        "put",
        {
          ...formData,
        },
        certificateRequestForm
      );
      if (saveError) {
        return;
      }
      if (responseData) {
        resetForm();
        fetchFieldData();
        handleCloseEdit();
      }
    },
  });

  const handleViewPreview = () => {
    setPreviewContent((prev) => ({
      ...prev,
      status: true,
    }));
  };
  const handleCloseEdit = () => {
    certificateRequestForm.resetForm();
    setEditStatus(false);
  };

  const handleEdit = () => {
    setEditStatus(!editStatus);
  };

  const handleApproval = async (requestStatus: boolean) => {
    if (requestStatus === false) {
      handleClose();
      setTextContent((prev: any) => ({
        type: "",
        value: "",
      }));
    } else {
      let response: any;
      if (
        openConfirmationModalData.requestStatus ===
        CertificateRequestStatus.Generated
      ) {
        const formData = new FormData();
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        formData.append("file", textContent.value);
        response = await modifyData(
          `/admin/certificate-request/upload/${id}`,
          "put",
          formData,
          config
        );
      } else {
        response = await modifyData(
          `/admin/certificate-request/status/${id}`,
          "put",
          {
            requestStatus: openConfirmationModalData.requestStatus,
            certificateRejectionReason: textContent.value ?? null,
          }
        );
      }
      if (saveError) {
        return;
      }
      if (
        response &&
        openConfirmationModalData.requestStatus ===
          CertificateRequestStatus.Generated
      ) {
        navigate("/certificate-management/certificates-issued");
      } else {
        certificateRequestForm.resetForm();
        fetchFieldData();
        handleClose();
      }
    }
  };
  const headerTitleBarNode =
    certificateTypeData?.certificateRequest?.requestStatus ===
      CertificateRequestStatus.Applied &&
    certificateRequestEntityData?.length ? (
      <>
        {certificateApprovePermission && (
          <LoadingButton
            className="TitleBarButton"
            loading={false}
            variant="contained"
            size="small"
            id="member-request-reject"
            onClick={() => {
              setOpenConfirmationModalData({
                status: true,
                message: "Are you sure you want to reject this application ?",
                requestStatus: CertificateRequestStatus.Rejected,
              });
              setTextContent((prev: any) => ({
                ...prev,
                type: "text",
              }));
            }}
          >
            Reject
          </LoadingButton>
        )}
        {certificateApprovePermission && (
          <LoadingButton
            className="TitleBarButton"
            loading={false}
            variant="contained"
            size="small"
            id="member-request-approve"
            onClick={() =>
              setOpenConfirmationModalData({
                status: true,
                message: "Are you sure you want to approve this application ?",
                requestStatus: CertificateRequestStatus.Approved,
              })
            }
          >
            Approve
          </LoadingButton>
        )}
      </>
    ) : null;

  const viewTitleBar: TitleBarProps = {
    title: certificateTypeData?.certificateRequest
      ? `Certificate Request Verification: ${
          certificateTypeData?.certificateRequest?.certificateType
            ?.certificateTypeName
        } (${pascalToSpace(
          certificateTypeData?.certificateRequest?.certificateLanguage
        )})`
      : "",
    headerTitle: "Certificate Requests",
  };
  const editTitlebar: TitleBarProps = {
    title: "Process Application",
    headerTitle: "Certificate Requests",
    buttonTitle: "Save",
    onClick: () => certificateRequestForm.handleSubmit(),
    dirty: certificateRequestForm.dirty,
    buttonLoading: fieldDataLoading,
  };

  const titleBarNode = (
    <Tooltip arrow title="Cancel">
      <IconButton
        className="TitleBarButton"
        size="medium"
        id={`request-edit`}
        onClick={handleCloseEdit}
        aria-label={`request-edit`}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
  const requestTooltipNode = (
    <>
      {editPermission && !approvalStatus && (
        <Tooltip arrow title="Edit">
          <IconButton
            className="TitleBarButton"
            size="medium"
            id={`request-edit`}
            onClick={handleEdit}
            aria-label={`request-edit`}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {editPermission &&
        certificateTypeData?.certificateRequest?.requestStatus ===
          CertificateRequestStatus.Approved && (
          <Tooltip arrow title="View">
            <IconButton
              className="TitleBarButton"
              size="medium"
              id={`request-edit`}
              onClick={handleViewPreview}
              aria-label={`request-edit`}
            >
              <SkipNextIcon />
            </IconButton>
          </Tooltip>
        )}
    </>
  );

  const handleClose = () => {
    setOpenConfirmationModalData({
      status: false,
      message: "",
      requestStatus: "",
    });
    setTextContent((prev: any) => ({
      type: "",
      value: "",
    }));
  };
  const handleDateChange = (newValue: string, field: string) => {
    const formattedDate = dateInputFromatHelper(
      newValue,
      certificateRequestForm
    );
    certificateRequestForm.setFieldValue(field, formattedDate);
  };

  return (
    <>
      {openConfirmationModalData.status === true ? (
        openConfirmationModalData.requestStatus ===
        CertificateRequestStatus.Approved ? (
          <ConfirmDialog
            title={"Confirm Approval"}
            openConfirmDialog={openConfirmationModalData.status}
            setOpenConfirmDialog={handleClose}
            message={openConfirmationModalData.message}
            handleSubmit={handleApproval}
          />
        ) : (
          <ConfirmDialog
            title={
              openConfirmationModalData?.requestStatus ===
              CertificateRequestStatus.Rejected
                ? "Confirm Rejection"
                : "Confirm Certificate Upload"
            }
            openConfirmDialog={openConfirmationModalData.status}
            setOpenConfirmDialog={handleClose}
            message={
              openConfirmationModalData.requestStatus ===
              CertificateRequestStatus.Rejected
                ? openConfirmationModalData.message
                : null
            }
            handleSubmit={handleApproval}
            setTextContent={setTextContent}
            fileTypes={[".pdf, image/*"]}
            label={
              openConfirmationModalData?.requestStatus ===
              CertificateRequestStatus.Rejected
                ? "Rejection Reason"
                : "Upload Attested Document"
            }
            textContent={textContent}
          />
        )
      ) : null}
      {certificateTypeData?.certificateRequest?.requestStatus ===
        CertificateRequestStatus.Approved && previewContent.status ? (
        <CertificatePreview
          fieldDataLoading={fieldDataLoading}
          setPreviewContent={setPreviewContent}
          id={id}
          certificateTypeName={
            certificateTypeData?.certificateRequest?.certificateType
              .certificateTypeName
          }
          fetchFieldData={fetchFieldData}
          previewContent={previewContent}
          confirmDetailStatus={
            previewContent.certificateNumber?.length === 0 ||
            previewContent.issueDate?.length === 0 ||
            previewContent.editorValue?.length === 0
          }
          setOpenConfirmationModalData={setOpenConfirmationModalData}
          setTextContent={setTextContent}
          certificatePrintPermission={certificatePrintPermission}
          certificateUploadPermission={certificateUploadPermission}
          certificateLanguage={pascalToSpace(
            certificateTypeData?.certificateRequest?.certificateLanguage
          )}
        />
      ) : editStatus &&
        certificateTypeData?.certificateRequest?.requestStatus !==
          CertificateRequestStatus.Rejected ? (
        <FormContainer
          loading={saveApiLoading}
          titleBar={editTitlebar}
          titleBarNode={titleBarNode}
        >
          <Box className="box-border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={2}
            >
              {certificateRequestEntityData?.map(
                (field: any, index: number) =>
                  (field?.formField === undefined ||
                    field.formField === true) && (
                    <Grid item md={6} sm={6} xs={12} key={field.id}>
                      {[
                        "marriageDate",
                        "fatherDeathDate",
                        "motherDeathDate",
                        "deathDate",
                      ]?.includes(field.name) ? (
                        <InputTextfield
                          onChange={(newValue: any) =>
                            handleDateChange(newValue, field.name)
                          }
                          form={certificateRequestForm}
                          fieldProps={{
                            label: pascalToSpace(field?.name),
                            id: field.id,
                            name: field.name,
                            type: "text",
                            required: true,
                            placeholder: "DD/MM/YYYY",
                          }}
                          key={index}
                        />
                      ) : (
                        <InputTextfield
                          form={certificateRequestForm}
                          fieldProps={field}
                          key={index}
                        />
                      )}
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
          {certificateRequestSubEntityData?.subEntityData?.length ? (
            <Box className="box-border-custom">
              <FormContainer
                titleBar={{
                  title:
                    certificateRequestSubEntityData?.type === "heirDetails"
                      ? "Legal Heir Details"
                      : "Relation Details",
                }}
              >
                <Grid
                  container
                  alignItems="stretch"
                  columnSpacing={4}
                  rowSpacing={2}
                >
                  {certificateRequestSubEntityData?.subEntityData?.map(
                    (fieldGroup: any[], groupIndex: number) =>
                      fieldGroup.map((field: any, fieldIndex: number) => {
                        return (
                          (field?.formField === undefined ||
                            field.formField === true) && (
                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={12}
                              key={`${groupIndex}-${fieldIndex}-${field.name}`}
                            >
                              <InputTextfield
                                form={certificateRequestForm}
                                value={
                                  certificateRequestForm.values[
                                    certificateRequestSubEntityData?.type
                                  ]?.[groupIndex]?.[
                                    field?.name?.split(".")?.[1]
                                  ]
                                }
                                fieldProps={{ ...field, name: field.name }}
                                key={`${groupIndex}-${fieldIndex}-${field.name}`}
                                error={
                                  (certificateRequestForm.touched as any)?.[
                                    certificateRequestSubEntityData.type
                                  ]?.[groupIndex]?.[field.name.split(".")[1]] &&
                                  (certificateRequestForm.errors as any)?.[
                                    certificateRequestSubEntityData.type
                                  ]?.[groupIndex]?.[field.name.split(".")[1]]
                                }
                              />
                            </Grid>
                          )
                        );
                      })
                  )}
                </Grid>
              </FormContainer>
            </Box>
          ) : null}
        </FormContainer>
      ) : (
        <ViewContainer
          loading={fieldDataLoading}
          titleBar={viewTitleBar}
          titleBarNode={headerTitleBarNode}
        >
          {certificateTypeData?.certificateRequest?.requestStatus ===
          CertificateRequestStatus.Rejected ? (
            <Box className="box-border-custom">
              <Typography component="span" display="block" m={1}>
                This certificate request is rejected
              </Typography>
              <Typography component="span" color="error" display="block" m={1}>
                Rejection Reason:{" "}
                {
                  certificateTypeData?.certificateRequest
                    ?.certificateRejectionReason
                }
              </Typography>
            </Box>
          ) : null}
          {certificateRequestEntityData?.length ? (
            <SingleEntityContainer
              entityFields={certificateRequestEntityData ?? []}
              entityTitle="Application Details"
              tooltips={requestTooltipNode}
            ></SingleEntityContainer>
          ) : null}
          {certificateRequestSubEntityData?.subEntityData?.length ? (
            <Box className="custom-box-border">
              <Typography className="subTitleBar">
                {certificateRequestSubEntityData?.type === "relationDetail"
                  ? "Relation Details"
                  : "Legal Heir Details"}
              </Typography>
              {certificateRequestSubEntityData?.subEntityData?.map(
                (subEntityData: any, index: number) => {
                  return (
                    <SingleEntityContainer
                      entityFields={subEntityData ?? []}
                    ></SingleEntityContainer>
                  );
                }
              )}
            </Box>
          ) : null}
        </ViewContainer>
      )}
    </>
  );
};

export default MemberRequestEditView;
