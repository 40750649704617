import * as yup from "yup";
// import { getValidationSchema } from "../../../../shared/validations/schema";
import {
  validateImageTypes,
  getImageDimensions,
} from "../../../../shared/utils/image-helper";
export const customizeValidationSchema = () => {
  let customSchema = yup.object().shape({
    name: yup
      .string()
      .trim("Name cannot include leading and trailing spaces")
      .strict(true)
      .min(2, "Name must be more than 2 characters")
      .max(30, "Name cannot exceed more than 30 characters")
      .required("Name is a required field"),
    formImage: yup
      .mixed()
      .nullable()
      .test(
        "file-size-and-type",
        "Accepted Image Formats: JPG, PNG, GIF. Max File Size: 2MB",
        (value) => {
          if (!value) {
            return true;
          }

          if (typeof value !== "string") {
            const file = value as File;
            const isValidType = validateImageTypes(file);
            const isValidSize = file.size <= 2000000;
            if (isValidType && isValidSize) {
              return true;
            } else {
              return false;
            }
          }

          return false;
        }
      )
      .test(
        "file-resolution",
        "Image resolution must be 328x150 pixels.", // Custom message for resolution validation
        async (value) => {
          if (!value || typeof value === "string") {
            return true; // Let the 'required' validation handle empty values
          }

          // Function to check the resolution
          const file = value as File;
          const dimensions = await getImageDimensions(file);

          // Check if the resolution matches 328x150 pixels
          return dimensions.width === 328 && dimensions.height === 150;
        }
      ),
  });

  return customSchema;
};
