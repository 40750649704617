/* eslint-disable react-hooks/rules-of-hooks */
import { IconButton, Tooltip } from "@mui/material";
import { FC, useContext, useState } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { Membership } from "../../../../../shared/interfaces/membership";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
const MembershipList: FC = () => {
  const { modifyData } = useMutation("/yogam-membership/membershipList");
  const { payload, setPayload } = useContext(SidebarContext);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const { loading, data, fetchData, error } = useQuery(
    "/admin/yogam-membership-request/eligible-members",
    {
      params: {
        ...payload,
        // unionMembershipStatus: statusFilter === "all" ? null : statusFilter,
      },
    }
  );

  let members: Membership[] = [];
  if (data && (data as any)?.items) {
    members = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        code: item?.code,
        fullNameEng: item?.fullNameEng,
        houseNameEng: item?.addressDetails?.houseNameEng,
        familyHead: item?.familyHeadDetails?.fullNameEng,
        gender: item?.gender,
        sangamNo: item?.joinedSangamNo,
        unionMembershipStatus: item?.unionMembershipStatus,
      };
    });
  }

  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    code: `"code`,
    schemeName: `"welfare"."schemeName"`,
    fullNameEng: `fullNameEng`,
    houseNameEng: "addressDetails.houseNameEng",
    familyHead: "familyHeadDetails.fullNameEng",
    // unionMembershipStatus: "unionMembershipStatus",
    createdOn: "createdAt",
  };

  const renderCell = (row: any, col: string) => {
    if (col === "actions") {
      return (
        <>
          <IconButton
            onClick={() => handleModalOpen(row)}
            color="secondary"
            aria-label="send notification"
          >
            <NotificationsActiveIcon fontSize="small" />
          </IconButton>
        </>
      );
    }
    return row[col];
  };
  const notifyPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Eligible Members-Notify")
    : true;

  const membershipDisplayColumns = [
    "code",
    "fullNameEng",
    "houseNameEng",
    "gender",
    "familyHead",
    "unionMembershipStatus",
    notifyPermission ? "actions" : null,
  ].filter((item): item is string => item !== null);

  const handleModalOpen = (member: any) => {
    if (typeof member === "object" && !Array.isArray(member)) {
      setSelectedIds([member.id]);
      setMessage(
        `${member.fullNameEng} is eligible to apply for yogam membership. Please login to apply`
      );
    } else if (Array.isArray(member)) {
      const hasId = member.some((item) => item.id !== undefined);
      if (hasId) {
        const filteredIds = member.map((item) => item.id);
        setSelectedIds(filteredIds);
      } else {
        setSelectedIds(member);
      }
      setMessage(`You are eligible to apply for Yogam membership`);
    }

    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleNotificationSubmit = () => {
    modifyData(
      `/admin/yogam-membership-request/notify-eligible-members`,
      "post",
      {
        ...payload,
        memberIds: selectedIds,
        message: message,
      }
    );

    // Close the modal after sending the notification
    setOpenModal(false);
  };

  const titleBarNode = (
    <>
      <SearchBox />
      {notifyPermission ? (
        <Tooltip arrow title="Notify">
          <IconButton
            className="TitleBarButton"
            size="medium"
            onClick={() => handleModalOpen(selectedIds)}
          >
            <CircleNotificationsIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );

  const titleBar = {
    title: "Eligible Members",
    headerTitle: "Eligible Members",
    titleBarNode: titleBarNode,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Family-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <>
          <DataTable
            entityName="Eligible Members"
            data={members}
            sortableFields={sortFields}
            displayColumns={membershipDisplayColumns}
            linkColumn="name"
            pagination={payload}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            refetchFunction={fetchData}
            newAction={handleModalOpen}
            showCheckboxes={true}
            onUpdateStatus={setSelectedIds}
            renderCell={renderCell}
          />
          <>
            <ConfirmDialog
              title={"Notify Member"}
              openConfirmDialog={openModal}
              setOpenConfirmDialog={handleClose}
              message={message}
              showCancelButton={false}
              handleSubmit={handleNotificationSubmit}
            />
          </>
        </>
      )}
    </TableViewContainer>
  );
};

export default MembershipList;
