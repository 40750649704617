import RestoreIcon from "@mui/icons-material/Restore";
import LogList from "../components/logList";

const logsRoute = {
  icon: RestoreIcon,
  name: "Logs",
  link: "/logs",
  path: "logs",
  index: 1001,
  element: <LogList />,
  permission: "Logs-Read",
};
export default logsRoute;
