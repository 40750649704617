import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Navigate } from "react-router-dom";
import MemberUserList from "../components/memberUserList";
const memberUserRoute = {
  icon: PersonIcon,
  name: "User Management",
  link: "/userManagement",
  path: "userManagement",
  element: "",
  index: 990,
  children: [
    {
      path: "member-users",
      element: <MemberUserList />,
      name: "Members",
      permission: "Member User-Read",
      link: "/userManagement/member-users",
      icon: ManageAccountsIcon,
      index: 5,
    },
    {
      path: "",
      element: <Navigate to="memberUsers" replace />,
    },
  ],
};
export default memberUserRoute;
