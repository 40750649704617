import "./styles.css";
import { FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { InputLabel } from "@mui/material";
import { ErrorMessage } from "../errorMessage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

type DatePickerProps = {
  id?: string;
  name?: string;
  format?: string;
  views?: any;
  label?: string;
  value?: Date | null;
  onChange: (date: string | null) => void;
  errorMessage?: string;
  required?: boolean;
  disableFuture?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
};
export const CustomDatePicker: FC<DatePickerProps> = ({
  id,
  name,
  format = "dd/MM/yyyy",
  views = ["year", "month", "day"],
  label,
  value,
  onChange,
  errorMessage,
  required,
  disableFuture,
  placeholder,
  fullWidth,
}) => {
  const handleDateChange = (newValue: any) => {
    onChange(newValue);
  };
  return (
    <div className="datePickerContainer" id={id}>
      {label && (
        <InputLabel>
          {label}
          {required ? <span style={{ color: "red" }}> * </span> : ""}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={views}
          className="datePickerIcon"
          format={format}
          disableFuture={disableFuture}
          value={value}
          onChange={handleDateChange}
          slots={{ openPickerIcon: CalendarMonthIcon }}
          slotProps={{
            field: { clearable: true },
            textField: {
              error: errorMessage ? true : false,
              fullWidth,
              InputProps: {
                size: "medium",
                placeholder: placeholder,
                name: name,
              },
            },
          }}
        />
      </LocalizationProvider>
      {errorMessage !== undefined && (
        <ErrorMessage message={errorMessage ?? null} />
      )}
    </div>
  );
};
