import "./style.css";
import { Box, Badge, IconButton, Tooltip } from "@mui/material";
import TextsmsIcon from "@mui/icons-material/Textsms";
import LogoutIcon from "@mui/icons-material/Logout";

interface SidebarFooterProps {
  onLogout: () => void;
}

const SidebarFooter: React.FC<SidebarFooterProps> = ({ onLogout }) => {
  return (
    <Box className="sidebar-footer">
      <IconButton className="footer-icons">
        <Badge badgeContent={4} color="error" variant="dot" className="badge">
          <TextsmsIcon className="text-sms-icon" />
        </Badge>
      </IconButton>
      <Tooltip title={"Log out"}>
        <IconButton
          className="footer-icons"
          aria-label="Logout"
          onClick={onLogout}
        >
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SidebarFooter;
