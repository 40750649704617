export const getJobRequestFields = (JobRequestData: any) => [
  {
    id: "applicantName",
    name: "applicantName",
    label: "Applicant Name",
    value: JobRequestData?.member?.fullNameEng,
  },
  {
    id: "houseNameEng",
    name: "houseNameEng",
    label: "House Name",
    value: JobRequestData?.member?.addressDetails?.houseNameEng,
  },
  {
    id: "jobType",
    name: "jobType",
    label: "Job Type",
    value: JobRequestData?.job?.jobType,
  },
  {
    id: "location",
    name: "location",
    label: "Location",
    value: JobRequestData?.job?.location ?? "Not Available",
  },
  {
    id: "applicationStatus",
    name: "applicationStatus",
    label: "Application Status",
    value: JobRequestData?.applicationStatus,
  },
  {
    id: "description",
    name: "description",
    label: "Description",
    value: JobRequestData?.job?.description ?? "Not Available",
  },
  {
    id: "remarks",
    name: "remarks",
    label: "Remarks",
    value: JobRequestData?.remarks ?? "Not Available",
  },
];
