export const getJobFields = () => [
  {
    id: "jobTitle",
    name: "jobTitle",
    label: "Job Title",
    type: "text",
    placeholder: "Job Title",
  },
  {
    id: "location",
    name: "location",
    label: "Location",
    type: "text",
    placeholder: "Location",
  },
  {
    id: "name",
    name: "name",
    label: "Contact Name",
    type: "text",
    placeholder: "Contact Name",
  },
  {
    id: "email",
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "Email",
  },
  {
    id: "phone",
    name: "phone",
    label: "Phone",
    type: "text",
    placeholder: "Phone",
  },
  {
    id: "url",
    name: "url",
    label: "Url",
    type: "text",
    placeholder: "Url",
  },
  {
    id: "salaryRange",
    name: "salaryRange",
    label: "Salary Range",
    type: "text",
    placeholder: "Salary Range",
  },
  {
    id: "noOfOpenings",
    name: "noOfOpenings",
    label: "No Of Openings",
    type: "text",
    placeholder: "No Of Openings",
  },
];
