import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext } from "react";
import TitleBar from "../../../../../shared/components/titleBar";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import Loader from "../../../../../shared/components/loader/linearProgress";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { Banner } from "../../../../../shared/interfaces/banner";

const BannerList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/banner", {
    params: { ...payload },
  });

  let responseData = data as any;
  const tableList = responseData?.items.map((item: Banner) => {
    return {
      id: item.id,
      name: item?.name,
      status: item?.status,
    };
  });
  const renderCell = (row: any, col: string) => {
    if (col === "status") {
      if (row.status === true) {
        return (
          <>
            <strong className={"chipEnabled"}>{"Enabled"}</strong>
          </>
        );
      } else {
        return (
          <>
            <strong className={"chipDisabled"}>{"Disabled"}</strong>
          </>
        );
      }
    }

    return row[col];
  };
  const bannerDisplayColumns = ["name", "status"];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Banners"
        buttonTitle="Create"
        onClick={() => navigate("/banner-management/banners/create")}
        headerTitle="Banner"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Banner-Create")
            : false
        }
      >
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Banners"
          data={tableList}
          displayColumns={bannerDisplayColumns}
          linkColumn="name"
          linkUrl="/banner-management/banners"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/banner"
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("Banner-Delete")
              : false
          }
          renderCell={renderCell}
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default BannerList;
