const searchPlaceHolder: any = {
  families: "Search",
  members: "Search",
  membershipRequests: "Search",
  "job-requests": "Search",
  jobs: "Search",
  welfare: "Search",
  "welfare-requests": "Search",
  "ongoing-schemes": "Search",
  facilities: "Search",
  "eligible-members": "Search",
  "yogam-membership-requests": "Search",
  "facility-bookings": "Search",
  "union-approval": "Search",
  tenders: "Search",
  events: "Search",
  "certificate-management": "Search",
  "certificate-requests": "Search",
  "certificates-issued": "Search",
  "membership-requests": "Search",
  administrators: "Search",
  "member-users": "Search",
  role: "Search",
};
export default searchPlaceHolder;
