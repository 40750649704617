import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { TenderStatus } from "../../../../../shared/enum";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { LoadingButton } from "@mui/lab";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { getTenderFields } from "./tenderFields";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { useFormik } from "formik";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { CustomDatePicker } from "../../../../../shared/components/customDatePicker";
import InputTextfield from "../../../../../shared/components/textField";
import { Editor } from "@tinymce/tinymce-react";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

const TenderView: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editStatus, setEditStatus] = useState(false);
  const [openConfirmationModalData, setOpenConfirmationModalData] = useState({
    status: false,
    message: "",
    tenderStatus: "",
  });
  const [editorInitialized, setEditorInitialized] = useState<boolean>(false);
  const [textContent, setTextContent] = useState<{ type: String; value: any }>({
    type: "",
    value: "",
  });

  const today = moment().startOf("day");
  const { loading, data, fetchData } = useLazyQuery(
    `/admin/tender/${id}`,
    null
  );

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: saveApiLoading,
    error: saveError,
    modifyData,
  } = useMutation();

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const tenderData = (data as any)?.data || {};

  const tenderFields = getTenderFields(tenderData);

  const tenderForm = useFormik({
    initialValues: {
      title: (data as any)?.data?.title ?? "",
      startDate: (data as any)?.data?.startDate ?? null,
      endDate: (data as any)?.data?.endDate ?? null,
      description: (data as any)?.data?.description ?? null,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: getValidationSchema([
      "title",
      "startDate",
      "endDate",
      "description",
    ]),
    validate: (values) => {
      const errors: any = {};
      if (values.startDate && moment(values.startDate).isBefore(today)) {
        errors.startDate = "Start date cannot be in the past";
      }
      if (values.endDate && moment(values.endDate).isBefore(today)) {
        errors.endDate = "End date cannot be in the past";
      }
      if (values.endDate && moment(values.endDate).isBefore(values.startDate)) {
        errors.endDate = "End date cannot be before start date";
      }
      return errors;
    },
    onSubmit: async (formData: any, { resetForm }) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("endDate", formData.endDate);
      formDataToSend.append("startDate", formData.startDate);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("file", textContent.value);
      const response = await modifyData(
        `/admin/tender/${id}`,
        "put",
        formDataToSend,
        config
      );
      if (response) {
        resetForm();
        fetchData();
        setEditStatus(false);
      }
    },
  });

  const showTenderEdit = () => {
    setEditStatus(true);
  };

  const closeTenderEdit = () => {
    window.history.back();
  };

  const handleDateChange = (newValue: string | null, fieldName: string) => {
    const newDate = newValue ? moment(newValue).format("YYYY-MM-DD") : null;
    tenderForm.setFieldValue(fieldName, newDate);
  };

  const handleOpenConfirmDialog = (message: string, tenderStatus: string) => {
    setOpenConfirmationModalData({
      status: true,
      message: message,
      tenderStatus: tenderStatus,
    });
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmationModalData({
      status: false,
      message: "",
      tenderStatus: "",
    });
    setEditStatus(false);
  };

  const handleConfirmDialogSubmit = async (responseStatus: boolean) => {
    if (responseStatus) {
      const response = await modifyData(
        `/admin/tender/${id}/update-tender-status/`,
        "put",
        {
          tenderStatus: openConfirmationModalData.tenderStatus,
        }
      );
      if (saveError) {
        handleCloseConfirmDialog();
      }
      if (response) {
        navigate("/tender-management/tenders");
      }
    }
    handleCloseConfirmDialog();
  };

  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Tenders-Update")
    : true;

  const publishPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Tenders-Publish")
    : true;

  const viewTitleBar: TitleBarProps = {
    title: "Tender Details",
    headerTitle: "Tender Details",
  };

  const titleBarNode = (
    <>
      {publishPermission &&
        !editStatus &&
        tenderData?.tenderStatus === "Draft" && (
          <LoadingButton
            className="TitleBarButton"
            loading={saveApiLoading}
            variant="contained"
            size="small"
            id="tender-publish"
            onClick={() =>
              handleOpenConfirmDialog(
                "Are you sure you want to publish this tender?",
                TenderStatus.Published
              )
            }
          >
            Publish
          </LoadingButton>
        )}
      <IconButton
        className="TitleBarButton"
        size="medium"
        id={`tender-cancel`}
        onClick={closeTenderEdit}
        aria-label={`tender-cancel`}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  const editTitlebar: TitleBarProps = {
    title: "Tender Edit",
    headerTitle: "Tender Edit",
    buttonTitle: "Save",
    onClick: () => tenderForm.handleSubmit(),
    dirty: tenderForm.dirty,
    buttonLoading: loading,
  };

  const requestTooltipNode = tenderData?.tenderStatus === TenderStatus.Draft &&
    editPermission && (
      <>
        <IconButton
          className="TitleBarIcon"
          size="medium"
          id={`tender-edit`}
          onClick={() => showTenderEdit()}
          aria-label={`tender-edit`}
        >
          <EditIcon />
        </IconButton>
      </>
    );
  const handleDocView = (fileUrl: string | URL) => {
    window.open(fileUrl);
  };

  const description = (
    <>
      <p>Description :</p>
      <span>
        {tenderData?.description ? (
          <div dangerouslySetInnerHTML={{ __html: tenderData?.description }} />
        ) : (
          "Not Available"
        )}
      </span>
      <br />
      {tenderData?.fileUrl ? (
        <Box className="border-custom">
          <Typography sx={{ mb: 1 }} component="span">
            File Preview: &nbsp;&nbsp;
            <Tooltip arrow title="View Document">
              <Link onClick={() => handleDocView(tenderData?.fileUrl)} to={""}>
                <DocumentScannerIcon sx={{ mb: -0.4 }} />
              </Link>
            </Tooltip>
          </Typography>
        </Box>
      ) : null}
    </>
  );

  const handleEditorChange = (content: any) => {
    if (editorInitialized) {
      tenderForm.setValues((prev: any) => ({
        ...prev,
        description: content,
      }));
    }
  };

  return (
    <>
      {editStatus ? (
        <>
          <FormContainer titleBar={editTitlebar} titleBarNode={titleBarNode}>
            <Box className="border-custom">
              <Grid
                container
                alignItems="stretch"
                columnSpacing={4}
                rowSpacing={2}
              >
                <Grid item md={6} sm={6} xs={12} key="tender-title">
                  <InputTextfield
                    form={tenderForm}
                    fieldProps={{
                      id: "tender-title",
                      name: "title",
                      label: "Title",
                      type: "text",
                      placeholder: "Title",
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12} key="tender-description">
                  {/* <InputTextfield
                    form={tenderForm}
                    multiline={true}
                    minRows={4}
                    fieldProps={{
                      id: "description",
                      name: "description",
                      label: "Description",
                      type: "text",
                      placeholder: "Description",
                      capitalizedFirst: true,
                      maxLength: 255,
                      required: true,
                    }}
                  /> */}
                  <Editor
                    apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                    init={{
                      skin: "borderless",
                      height: 400,
                      min_height: 200,
                      statusbar: true,
                      branding: false,
                      menubar: true,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "blocks bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onInit={() => setEditorInitialized(true)}
                    value={tenderForm?.values?.description}
                    onEditorChange={(content: any) =>
                      handleEditorChange(content)
                    }
                    onBlur={() =>
                      tenderForm.setFieldTouched("description", true)
                    }
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} key="tender-startDate">
                  <CustomDatePicker
                    onChange={(date: any) =>
                      handleDateChange(date, "startDate")
                    }
                    placeholder="Start Date"
                    label="Start Date"
                    name="startDate"
                    required={true}
                    value={
                      tenderForm.values.startDate
                        ? new Date(tenderForm.values.startDate)
                        : null
                    }
                    form={tenderForm}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} key="tender-endDate">
                  <CustomDatePicker
                    onChange={(date: any) => handleDateChange(date, "endDate")}
                    placeholder="End Date"
                    label="End Date"
                    required={true}
                    name="endDate"
                    value={
                      tenderForm.values.endDate
                        ? new Date(tenderForm.values.endDate)
                        : null
                    }
                    form={tenderForm}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} key="tender-file">
                  <InputLabel>Upload File</InputLabel>
                  <OutlinedInput
                    size="small"
                    fullWidth
                    id="outlined-basic"
                    inputProps={{
                      accept: [".pdf, image/*"],
                    }}
                    type={"file"}
                    className="fileInput"
                    onChange={(event: any) => {
                      setTextContent((prev: any) => ({
                        ...prev,
                        value: event.target.files[0],
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormContainer>
        </>
      ) : (
        <ViewContainer
          loading={loading}
          titleBar={viewTitleBar}
          titleBarNode={titleBarNode}
        >
          <SingleEntityContainer
            entityFields={tenderFields}
            entityTitle="Tender Details"
            tooltips={requestTooltipNode}
            children={description}
          />
        </ViewContainer>
      )}
      <ConfirmDialog
        title="Confirm Tender"
        openConfirmDialog={openConfirmationModalData?.status}
        setOpenConfirmDialog={handleCloseConfirmDialog}
        message={openConfirmationModalData.message}
        handleSubmit={handleConfirmDialogSubmit}
      />
    </>
  );
};

export default TenderView;
