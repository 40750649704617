import { FC, useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { useFormik } from "formik";
import { FormContainer } from "../../../../../shared/components/formContainer";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { LoadingButton } from "@mui/lab";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { getWelfareRequestFields } from "./welfareRequestFields";
import {
  dateInputFromatHelper,
  dateToISOString,
} from "../../../../../shared/utils/date-helper";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import { ApplicationStatus } from "../../../../../shared/enum";

const WelfareRequestApproveView: FC = () => {
  const { id } = useParams();
  const [approvalStatus, setApprovalStatus] = useState<string | null>(null);

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/welfare-request/${id}`,
    null
  );

  const {
    loading: saveApiLoading,
    error: saveError,
    modifyData,
  } = useMutation();

  const approvePermission = (data as any)?.entityPermissions
    ? !(data as any)?.entityPermissions?.includes("Welfare scheme-Approve")
    : true;

  useEffect(() => {
    if (id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const validate = (values: any) => {
    const errors: any = {};
    const welfareStartDate = values.welfareStartDate
      ? new Date(values.welfareStartDate)
      : null;
    const welfareEndDate = values.welfareEndDate
      ? new Date(values.welfareEndDate)
      : null;

    if (
      welfareStartDate &&
      welfareEndDate &&
      welfareEndDate < welfareStartDate
    ) {
      errors.welfareEndDate = "End date cannot be before start date";
    }
    if (welfareStartDate) {
      const today = new Date();
      const dateToCheck = new Date(welfareStartDate);
      if (dateToCheck < today) {
        errors.welfareStartDate = "Start date cannot be a past date";
      }
    }

    return errors;
  };

  const welfareRequestData = (data as any)?.data || {};
  const welfareRequestFields = getWelfareRequestFields(welfareRequestData);

  const welfareRequestForm = useFormik({
    initialValues: {
      welfareStartDate: null,
      welfareEndDate: null,
      remarks: "",
      requestStatus: "",
    },
    enableReinitialize: true,
    validate,
    validationSchema: getValidationSchema(
      approvalStatus === ApplicationStatus.Approved &&
        welfareRequestData?.welfare?.type === "Financial"
        ? ["remarks", "welfareStartDate", "welfareEndDate"]
        : ["remarks"]
    ),

    onSubmit: async (formData: any, { resetForm }) => {
      try {
        await modifyData(
          `/admin/welfare-request/status/${id}`,
          "put",
          {
            ...formData,
            requestStatus: approvalStatus,
            welfareStartDate: dateToISOString(formData.welfareStartDate),
            welfareEndDate: dateToISOString(formData.welfareEndDate),
          },
          welfareRequestForm
        );
        if (saveError) {
          return;
        }
        resetForm();
        fetchData();
        setApprovalStatus(null);
      } catch (error) {
        console.error("Error updating the welfare request:", error);
      }
    },
  });

  const handleCloseApproval = () => {
    welfareRequestForm.resetForm();
    setApprovalStatus(null);
  };

  const handleApprove = () => {
    if (approvalStatus) {
      welfareRequestForm.handleSubmit();
    } else {
      setApprovalStatus(ApplicationStatus.Approved);
      welfareRequestForm.setFieldValue(
        "requestStatus",
        ApplicationStatus.Approved
      );
    }
  };
  const handleDocView = (fileUrl: string | URL) => {
    window.open(fileUrl);
  };
  const handleReject = () => {
    if (approvalStatus) {
      welfareRequestForm.handleSubmit();
    } else {
      setApprovalStatus(ApplicationStatus.Rejected);
      welfareRequestForm.setFieldValue(
        "requestStatus",
        ApplicationStatus.Rejected
      );
    }
  };

  const handlewelfareStartDateChange = (newValue: string) => {
    const formatedDate = dateInputFromatHelper(newValue, welfareRequestForm);
    welfareRequestForm.setFieldValue("welfareStartDate", formatedDate);
  };
  const handlewelfareEndDateChange = (newValue: string) => {
    const formatedDate = dateInputFromatHelper(newValue, welfareRequestForm);
    welfareRequestForm.setFieldValue("welfareEndDate", formatedDate);
  };

  const isFormDirty = welfareRequestForm.dirty;

  const viewTitleBar: TitleBarProps = {
    title: "Verification",
    headerTitle: "Welfare Request",
  };
  const documentView = (
    <Box className="border-custom">
      <Typography component="span">
        Document :&nbsp;&nbsp;
        <Tooltip arrow title="View Document">
          <Link
            onClick={() => handleDocView(welfareRequestData?.documents)}
            to={""}
          >
            <ArticleIcon sx={{ mb: -0.8 }} />
          </Link>
        </Tooltip>
      </Typography>
    </Box>
  );
  const titleBarNode = (
    <>
      {welfareRequestData?.requestStatus === ApplicationStatus.Pending &&
        approvePermission && (
          <>
            {(approvalStatus === ApplicationStatus.Approved ||
              approvalStatus === null) && (
              <LoadingButton
                className="TitleBarButton"
                loading={false}
                variant="contained"
                size="small"
                id="member-request-approve"
                onClick={handleApprove}
              >
                Approve
              </LoadingButton>
            )}
            {(approvalStatus === ApplicationStatus.Rejected ||
              approvalStatus === null) && (
              <LoadingButton
                className="TitleBarButton"
                loading={false}
                variant="contained"
                size="small"
                id="member-request-reject"
                onClick={handleReject}
              >
                Reject
              </LoadingButton>
            )}
          </>
        )}

      {approvalStatus && (
        <>
          <>
            <Tooltip arrow title="Cancel">
              <IconButton
                className="TitleBarButton"
                size="medium"
                id="request-close"
                onClick={handleCloseApproval}
                aria-label="request-close"
                disabled={!isFormDirty}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </>
        </>
      )}
    </>
  );

  return (
    <>
      {approvalStatus ? (
        <FormContainer
          loading={saveApiLoading}
          titleBar={{
            title: "Process Welfare Request",
            headerTitle: "Welfare Request",
          }}
          titleBarNode={titleBarNode}
        >
          <Box className="border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={2}
            >
              <Grid item md={6} sm={6} xs={12}>
                <InputTextfield
                  form={welfareRequestForm}
                  value={welfareRequestForm?.values?.remarks || ""}
                  multiline
                  fieldProps={{
                    id: "remarks",
                    name: "remarks",
                    label: "Remarks:",
                    type: "text",
                    required: true,
                    placeholder: "Enter Remarks",
                    maxLength: 255,
                  }}
                />
              </Grid>

              {welfareRequestData?.welfare?.type === "Financial" &&
              approvalStatus === ApplicationStatus.Approved ? (
                <>
                  <Grid item md={6} sm={6} xs={12}>
                    <InputTextfield
                      form={welfareRequestForm}
                      value={welfareRequestForm?.values?.welfareStartDate || ""}
                      fieldProps={{
                        id: "welfareStartDate",
                        name: "welfareStartDate",
                        label: "Welfare Start Date:",
                        type: "text",
                        required: true,
                        placeholder: "DD/MM/YYYY",
                      }}
                      onChange={handlewelfareStartDateChange}
                    />
                    {welfareRequestData?.welfare?.payoutType ? (
                      <>
                        {/* <Grid item md={6} sm={6} xs={12}> */}
                        <InputTextfield
                          form={welfareRequestForm}
                          value={
                            welfareRequestForm?.values?.welfareEndDate || ""
                          }
                          fieldProps={{
                            id: "welfareEndDate",
                            name: "welfareEndDate",
                            label: "Welfare End Date:",
                            type: "text",
                            required: false,
                            placeholder: "DD/MM/YYYY",
                          }}
                          onChange={handlewelfareEndDateChange}
                        />
                        {/* </Grid> */}
                      </>
                    ) : null}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </FormContainer>
      ) : (
        <ViewContainer
          loading={loading}
          titleBar={viewTitleBar}
          titleBarNode={titleBarNode}
        >
          <SingleEntityContainer
            children={documentView}
            entityFields={welfareRequestFields}
            entityTitle="Welfare Request Details"
          />
        </ViewContainer>
      )}
    </>
  );
};

export default WelfareRequestApproveView;
