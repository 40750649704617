/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import { FC } from "react";
import { Member } from "../../../../../shared/interfaces/member";
import moment from "moment";

interface MemberJobDetails {
  memberData: Member;
}

const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const formatDate = (date: any) => {
  return isoRegex.test(date);
};
const JobList: FC<MemberJobDetails> = ({ memberData }) => {
  return (
    <Container className="tableListContainer">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sector</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberData?.occupations?.map((data: any) => (
              <TableRow>
                <TableCell>{data.sector ?? "Not Available"}</TableCell>
                <TableCell>{data.companyName ?? "Not Available"}</TableCell>

                <TableCell>{data.jobTitle ?? "Not Available"}</TableCell>
                <TableCell>
                  {" "}
                  {formatDate(data.startDate)
                    ? moment(data.startDate).format("DD/MM/YYYY")
                    : moment(data.startDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      ) ?? "Not Available"}
                </TableCell>
                <TableCell>
                  {formatDate(data.endDate)
                    ? moment(data.endDate).format("DD/MM/YYYY")
                    : moment(data.endDate, "YYYY-MM-DD").format("DD/MM/YYYY") ??
                      "Current Date"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default JobList;
