import {
  Gender,
  ResidenceType,
  KudumbaUnit,
  RationCardType,
  WelfareCategory,
  WelfareType,
  PayoutType,
  Welfarestatus,
  AvailabilityStatus,
  FacilityType,
} from "../enum";

export const genderOptions = [
  {
    label: Gender.Male,
    value: Gender.Male,
  },
  {
    label: Gender.Female,
    value: Gender.Female,
  },
  {
    label: Gender.Other,
    value: Gender.Other,
  },
];

export const residenceTypeOptions = [
  {
    label: ResidenceType.Apartments,
    value: ResidenceType.Apartments,
  },
  {
    label: ResidenceType["Terrace"],
    value: ResidenceType["Terrace"],
  },
  {
    label: ResidenceType["Tiled"],
    value: ResidenceType["Tiled"],
  },
  {
    label: ResidenceType["Thatched"],
    value: ResidenceType["Thatched"],
  },
];

export const kudumbaUnitOptions = [
  {
    value: KudumbaUnit["Kumaranashan Memorial Family Unit"],
    label: "Kumaranashan Memorial Family Unit",
  },
  {
    value: KudumbaUnit["Dr. Palpu Memorial Family Unit"],
    label: "Dr. Palpu Memorial Family Unit",
  },
  {
    value: KudumbaUnit["TK Madhavan Memorial Family Unit"],
    label: "TK Madhavan Memorial Family Unit",
  },
  {
    value: KudumbaUnit["Kumaranashan Memorial Family Unit"],
    label: "C. Keshavan Memorial Family Unit",
  },
];

export const rationCardOptions = [
  {
    label: RationCardType["APL"],
    value: RationCardType["APL"],
  },
  {
    label: RationCardType["BPL"],
    value: RationCardType["BPL"],
  },
];

//Welfare-schemes

export const WelfareCategoryOptions = [
  {
    label: WelfareCategory.Pension,
    value: WelfareCategory.Pension,
  },
  {
    label: WelfareCategory.FinancialAid,
    value: WelfareCategory.FinancialAid,
  },
  {
    label: WelfareCategory.NonfinancialAid,
    value: WelfareCategory.NonfinancialAid,
  },
];

export const WelfareTypeOptions = [
  { label: WelfareType.Financial, value: WelfareType.Financial },
  {
    label: WelfareType.Nonfinancial,
    value: WelfareType.Nonfinancial,
  },
];

export const PayoutTypeOptions = [
  {
    label: PayoutType.SinglePayout,
    value: PayoutType.SinglePayout,
  },
  {
    label: PayoutType.MonthlyRecursive,
    value: PayoutType.MonthlyRecursive,
  },
  {
    label: PayoutType.YearlyRecursive,
    value: PayoutType.YearlyRecursive,
  },
];
export const WelfareStatusOptions = [
  {
    label: Welfarestatus.Active,
    value: Welfarestatus.Active,
  },
  {
    label: Welfarestatus.InActive,
    value: Welfarestatus.InActive,
  },
];
export const availabiltyOptions = [
  {
    label: AvailabilityStatus.Available,
    value: AvailabilityStatus.Available,
  },
  {
    label: AvailabilityStatus.Unavailable,
    value: AvailabilityStatus.Unavailable,
  },
];

export const facilityOptions = [
  {
    label: FacilityType.Ground,
    value: FacilityType.Ground,
  },
  {
    label: FacilityType.Hall,
    value: FacilityType.Hall,
  },
];
