import MembershipRequestList from "../components/memberRequestList";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MemberRequestEditView from "../components/memberRequesEditView";
const memberRequestRoute = {
  icon: PeopleAltIcon,
  name: "Branch Membership",
  link: "/branchMembership",
  path: "/branchMembership",
  element: "",
  index: 10,
  children: [
    {
      path: "membership-requests",
      element: <MembershipRequestList />,
      name: "Membership Requests",
      permission: "Membership Request-Read", // Permission
      index: 2,
      link: "/branchMembership/membership-requests",
      icon: ManageAccountsIcon,
    },
    {
      path: "membership-requests/:id",
      element: <MemberRequestEditView />,
    },
  ],
};
export default memberRequestRoute;
