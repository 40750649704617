// features and associated entities
export const Features = {
  Administrators: {
    specialPermissions: ["Read", "Create", "Update", "Delete", "Permission"],
  },
  Roles: {},
  Family: {
    Write: ["Member", "Lsgds"],
    specialPermissions: ["Read", "Create", "Update", "Delete"],
  },
  Member: {},
  "Membership Request": {
    specialPermissions: ["Read", "Update", "Approve", "Delete"],
  },

  "Yogam Membership Request": {
    specialPermissions: ["Read", "Notify", "Print", "Approve"],
  },
  "Eligible Members": { specialPermissions: ["Read", "Notify"] },
  "Union Approval": {
    specialPermissions: ["Read", "Notify", "Approve"],
  },
  "Certificate Request": {
    specialPermissions: [
      "Read",
      "Create",
      "Update",
      "Approve",
      "Upload",
      "Print",
    ],
  },
  "Certificate Type": { specialPermissions: ["Read"] },
  Certificate: { specialPermissions: ["Read", "Update"] },
  Facilities: {},
  "Facility Bookings": {
    specialPermissions: [
      "Read",
      "Create",
      "Update",
      "Delete",
      "Approve",
      "Reject",
      "Cancel",
    ],
  },
  Tenders: {
    specialPermissions: ["Read", "Create", "Update", "Delete", "Publish"],
  },
  EventType: {},
  Event: {
    specialPermissions: [
      "Read",
      "Create",
      "Update",
      "Publish",
      "Cancel",
      "Notify",
      "Delete",
    ],
  },
  "Reset Password": { specialPermissions: ["Update"] },
  "Welfare scheme": {
    specialPermissions: ["Read", "Create", "Update", "Delete"],
  },
  "Welfare request": { specialPermissions: ["Read", "Approve", "Delete"] },
  Application: {},
  Job: {
    specialPermissions: ["Read", "Create", "Update", "Delete", "Publish"],
  },
  "Job Request": {
    specialPermissions: ["Read", "Create", "Update", "Shortlist"],
  },
  Banner: {},
  Logs: { specialPermissions: ["Read"] },
};
