import { FC, useState, useContext } from "react";
import { useFormik } from "formik";
import { Box, Checkbox, Grid, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import InputTextfield from "../../../../../shared/components/textField";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import moment from "moment";
import { Events } from "../../../../../shared/interfaces/event";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import { CustomTimePicker } from "../../../../../shared/components/timePicker";
import { CustomDatePicker } from "../../../../../shared/components/customDatePicker";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import { getEventEditFields } from "./eventEditFields";
import { LoadingButton } from "@mui/lab";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
import { EventState } from "../../../../../shared/enum";
// import SingleEntityImageViewer from "../../../../../shared/components/singleEntityImageViewer";
import { customizeValidationSchema } from "../customSchema";
// import ImageInputField from "../../../../../shared/components/imageInputField";

const EventEditView: FC = () => {
  const { id } = useParams();
  const { payload, setPayload } = useContext(SidebarContext);
  const { error: saveError, modifyData } = useMutation();
  const [editStatus, setEditStatus] = useState(false);
  // const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const { loading, data, fetchData } = useQuery(`/admin/event/${id}`, null);
  const [openConfirmationModalData, setOpenConfirmationModalData] = useState({
    status: false,
    message: "",
    eventStatus: "",
  });
  const { data: eventTypeData } = useQuery("/admin/event-type", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
    },
  });

  const selectedEventType: any =
    data && (data as any)?.data?.eventType
      ? {
          value: (data as any)?.data?.eventTypeId,
          label: (data as any)?.data?.eventType?.eventTypeName,
        }
      : null;

  const eventTypes = (eventTypeData as any)?.items?.map((eventType: any) => ({
    label: eventType?.eventTypeName,
    value: eventType?.id,
  }));

  const eventData: Events = (data as any)?.data || {};
  const eventEditFields = getEventEditFields(eventData);
  const editPermission = eventData?.entityPermissions
    ? eventData?.entityPermissions?.includes("Event-Update")
    : true;

  const publishPermission = eventData?.entityPermissions
    ? eventData?.entityPermissions?.includes("Event-Publish")
    : true;

  const notifyPermission = eventData?.entityPermissions
    ? eventData?.entityPermissions?.includes("Event-Notify")
    : true;

  const today = moment().startOf("day");

  const checkTimeOnSameDate = (startTime: any, endTime: any) => {
    let date1 = new Date(startTime);
    let date2 = new Date(endTime);

    // Function to extract time in seconds since midnight (ignores the date)

    let time1 = getTimeInSeconds(date1);
    let time2 = getTimeInSeconds(date2);

    if (time1 < time2) {
      return true;
    } else {
      return false;
    }
  };
  function getTimeInSeconds(date: any) {
    return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  }

  const eventForm = useFormik({
    initialValues: {
      eventTypeId: eventData.eventTypeId,
      name: eventData?.name,
      location: eventData?.location,
      description: eventData?.description,
      notify: eventData?.notify,
      astroNotify: eventData?.astroNotify,
      startDate: eventData?.startDate,
      endDate: eventData?.endDate,
      startTime: moment(eventData.startTime, "HH:mm:ss").toDate(),
      endTime: moment(eventData.endTime, "HH:mm:ss").toDate(),
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validate: (values) => {
      const errors: any = {};
      if (values.startDate && moment(values.startDate).isBefore(today)) {
        errors.startDate = "Start date cannot be in the past";
      }
      if (values.endDate && moment(values.endDate).isBefore(today)) {
        errors.endDate = "End  date cannot be in the past";
      }
      if (values.startDate && values.endDate) {
        const startDate = moment(values.startDate);
        const endDate = moment(values.endDate);

        if (endDate.isBefore(startDate)) {
          errors.endDate = "End Date cannot be earlier than Start Date";
        }
      }
      if (values.startDate === values.endDate) {
        const isValid = checkTimeOnSameDate(values.startTime, values.endTime);
        if (!isValid) {
          errors.endTime = "End time cannot be earlier than Start time";
        }
      }
      return errors;
    },
    validationSchema: customizeValidationSchema(),
    onSubmit: async (formData: any, { resetForm }) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formDataToSend = new FormData();
      formDataToSend.append("eventTypeId", formData.eventTypeId);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("startDate", formData.startDate);
      formDataToSend.append("endDate", formData.endDate);
      formDataToSend.append("location", formData.location);
      formDataToSend.append("notify", formData.notify.toString());
      formDataToSend.append("astroNotify", formData.astroNotify.toString());
      formDataToSend.append("description", formData.description);
      if (formData.eventImage) {
        formDataToSend.append("file", formData.eventImage);
      }
      formDataToSend.append(
        "startTime",
        moment(formData.startTime).format("HH:mm")
      );
      formDataToSend.append(
        "endTime",
        moment(formData.endTime).format("HH:mm")
      );
      const responseData = await modifyData(
        `/admin/event/${id}`,
        "put",
        formDataToSend,
        config
      );
      if (responseData) {
        await fetchData();
        resetForm();
        setEditStatus(false);
      }
    },
  });

  function isEndDateAfterToday(endDateString: any) {
    const today = new Date();
    const dateToCheck = new Date(endDateString);

    return dateToCheck > today;
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    eventForm.setFieldValue(name, checked);
  };
  const showEventEdit = () => {
    setEditStatus(true);
  };
  const handleOpenConfirmDialog = (message: string, eventState: string) => {
    setOpenConfirmationModalData({
      status: true,
      message: message,
      eventStatus: eventState,
    });
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmationModalData({
      status: false,
      message: "",
      eventStatus: "",
    });
  };
  const closeEventEdit = () => {
    fetchData();
    eventForm.resetForm();
    setEditStatus(false);
  };

  const handleStatusChange = async () => {
    await modifyData(
      `/admin/event/event-state-update/${openConfirmationModalData.eventStatus}/${eventData.id}`,
      "put"
    );
    if (!saveError) {
      fetchData();
    }
    handleCloseConfirmDialog();
  };
  const handleConfirmAction = async (responseStatus: boolean) => {
    if (responseStatus) {
      handleStatusChange();
    }
    handleCloseConfirmDialog();
  };
  const viewTitleBar: TitleBarProps = {
    title: "Event Edit",
    headerTitle: "Event - Edit",
  };

  const editTitlebar: TitleBarProps = {
    title: "Event Edit",
    headerTitle: "Event Edit",
    buttonTitle: "Save",
    onClick: () => eventForm.handleSubmit(),
    dirty: eventForm.dirty,
    buttonLoading: loading,
  };
  const requestTooltipNode = eventData?.eventState === EventState.Draft &&
    editPermission && (
      <IconButton
        className="TitleBarIcon"
        size="medium"
        id={`event-edit`}
        onClick={() => showEventEdit()}
        aria-label={`event-edit`}
      >
        <EditIcon />
      </IconButton>
    );
  const titleBarNode = (
    <>
      {!editStatus && notifyPermission ? (
        eventData?.eventState === EventState.Published &&
        (eventForm.values.notify || eventForm.values.astroNotify) ? (
          <LoadingButton
            className="TitleBarButton"
            loading={false}
            variant="contained"
            size="small"
            id="event-notify"
          >
            Notify
          </LoadingButton>
        ) : eventData?.eventState === EventState.Draft && publishPermission ? (
          <>
            <LoadingButton
              className="TitleBarButton"
              loading={false}
              variant="contained"
              size="small"
              id="event-publish"
              onClick={() =>
                handleOpenConfirmDialog(
                  "Are you sure you want to publish this event?",
                  EventState.Published
                )
              }
              disabled={
                (data as any)?.entityPermissions
                  ? !(data as any)?.entityPermissions?.includes("Event-Publish")
                  : false
              }
            >
              Publish
            </LoadingButton>
          </>
        ) : null
      ) : (
        <IconButton
          className="TitleBarButton"
          size="medium"
          id={`request-edit`}
          onClick={closeEventEdit}
          aria-label={`request-edit`}
        >
          <CloseIcon />
        </IconButton>
      )}
      {!editStatus &&
      eventData?.eventState !== EventState.Cancelled &&
      isEndDateAfterToday(eventData?.startDate) ? (
        <LoadingButton
          className="TitleBarButton"
          loading={false}
          variant="contained"
          size="small"
          id="event-cancel"
          onClick={() =>
            handleOpenConfirmDialog(
              "Are you sure you want to cancel this event?",
              EventState.Cancelled
            )
          }
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("Event-Cancel")
              : false
          }
        >
          Cancel Event
        </LoadingButton>
      ) : null}
    </>
  );

  return (
    <>
      {!editStatus && (
        <ViewContainer
          loading={loading}
          titleBar={viewTitleBar}
          titleBarNode={titleBarNode}
        >
          <SingleEntityContainer
            entityFields={eventEditFields}
            entityTitle="Event Details"
            tooltips={requestTooltipNode}
          >
            {/* <SingleEntityImageViewer
              src={previewUrl ? previewUrl : eventData?.image}
              alt="Event"
            /> */}
          </SingleEntityContainer>
        </ViewContainer>
      )}
      {editStatus && (
        <FormContainer titleBar={editTitlebar} titleBarNode={titleBarNode}>
          <Box className="border-custom">
            <Grid
              container
              alignItems="stretch"
              columnSpacing={4}
              rowSpacing={2}
            >
              <Grid item md={6} sm={6} xs={12}>
                <ZSAutocomplete
                  options={eventTypes}
                  placeholder="Select Event Type"
                  label="Event Type"
                  required
                  setValue={(selectedOption: any) => {
                    eventForm.setFieldTouched("eventTypeId", true);
                    if (selectedOption?.value) {
                      eventForm.setFieldValue(
                        "eventTypeId",
                        selectedOption?.value
                      );
                    } else {
                      eventForm.setFieldValue("eventTypeId", "");
                    }
                  }}
                  defaultValue={selectedEventType}
                  setBlur={() => {
                    eventForm.setFieldTouched("eventTypeId", true);
                  }}
                  errorMessage={
                    ((eventForm.touched as any)?.eventTypeId &&
                      (eventForm.errors as any)?.eventTypeId) ??
                    null
                  }
                  refetchFunction={(searchString: string) => {
                    setPayload((prevData: any) => {
                      return { ...prevData, search: searchString };
                    });
                  }}
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12} key="event-name">
                <InputTextfield
                  form={eventForm}
                  fieldProps={{
                    id: "name",
                    name: "name",
                    label: "Event Name",
                    type: "text",
                    required: true,
                    placeholder: "Event Name",
                    capitalizedFirst: true,
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12} key="event-description">
                <InputTextfield
                  form={eventForm}
                  multiline
                  fieldProps={{
                    id: "description",
                    name: "description",
                    label: "Description",
                    type: "text",
                    required: true,
                    placeholder: "Description",
                    capitalizedFirst: true,
                    maxLength: 255,
                  }}
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <CustomDatePicker
                  label="Start Date"
                  name="startDate"
                  required
                  placeholder="Start Date"
                  value={
                    eventForm.values.startDate
                      ? new Date(eventForm.values.startDate)
                      : null
                  }
                  form={eventForm}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <CustomTimePicker
                  label="Start Time"
                  name="startTime"
                  placeholder="Start Time"
                  required
                  value={
                    eventForm?.values?.startTime
                      ? new Date(eventForm?.values?.startTime)
                      : null
                  }
                  form={eventForm}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <CustomDatePicker
                  label="End Date"
                  name="endDate"
                  placeholder="End Date"
                  required
                  value={
                    eventForm.values.endDate
                      ? new Date(eventForm.values.endDate)
                      : null
                  }
                  form={eventForm}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <CustomTimePicker
                  label="End Time"
                  name="endTime"
                  placeholder="End Time"
                  required
                  value={
                    eventForm?.values?.endTime
                      ? new Date(eventForm?.values?.endTime)
                      : null
                  }
                  form={eventForm}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={6} key="event-location">
                <InputTextfield
                  form={eventForm}
                  fieldProps={{
                    id: "location",
                    name: "location",
                    label: "Venue",
                    type: "text",
                    required: true,
                    placeholder: "Venue",
                    capitalizedFirst: true,
                  }}
                />
              </Grid>
              {/* <Grid item md={6} sm={6} xs={12} key="facility-imageURL">
                <ImageInputField
                  id="event-image"
                  name="eventImage"
                  label="Event Image"
                  setPreviewUrl={setPreviewUrl}
                  form={eventForm}
                />
              </Grid> */}
              <Grid item md={6}>
                <Grid item md={12} sm={12} xs={12}>
                  <Checkbox
                    id="notify"
                    name="notify"
                    checked={eventForm.values?.notify}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Sent notifications to all members
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <Checkbox
                    id="astroNotify"
                    name="astroNotify"
                    checked={eventForm.values?.astroNotify}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Sent astro notifications to all members
                </Grid>
              </Grid>
              {/* <Grid item md={6} sm={6} xs={6} key="event-imagePreview">
                {previewUrl || eventData?.image ? (
                  <SingleEntityImageViewer
                    src={previewUrl ? previewUrl : eventData?.image}
                    alt="Event"
                  />
                ) : null}
              </Grid> */}
            </Grid>
          </Box>
        </FormContainer>
      )}
      <ConfirmDialog
        title={"Confirm"}
        openConfirmDialog={openConfirmationModalData?.status}
        setOpenConfirmDialog={handleCloseConfirmDialog}
        message={openConfirmationModalData.message}
        handleSubmit={handleConfirmAction}
      />
    </>
  );
};

export default EventEditView;
