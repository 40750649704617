import MembershipRequestList from "../components/yogamMembershipRequestList";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupsIcon from "@mui/icons-material/Groups";
const memberRequestRoute = {
  icon: GroupsIcon,
  name: "Yogam Membership",
  link: "/yogam-membership",
  path: "yogam-membership",
  element: "",
  index: 110,
  children: [
    {
      path: "yogam-membership-requests",
      element: <MembershipRequestList />,
      name: "Yogam Membership Requests",
      index: 2,
      permission: "Yogam Membership Request-Read",
      link: "/yogam-membership/yogam-membership-requests",
      icon: ListAltIcon,
    },
  ],
};
export default memberRequestRoute;
