import "./styles.css";
import { Box, Grid, Typography } from "@mui/material";
import { FC, Fragment, useContext, useEffect } from "react";
import { EntityContext } from "../../../contexts/EntityContext";
import { LoadingButton } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

export type TitleBarProps = {
  title: string;
  headerTitle?: string;
  buttonTitle?: any;
  onClick?: Function;
  disabled?: boolean;
  children?: React.ReactNode;
  buttonLoading?: boolean;
  dirty?: boolean;
};
const TitleBar: FC<TitleBarProps> = ({
  title,
  headerTitle = null,
  buttonTitle,
  onClick,
  disabled,
  children,
  buttonLoading = false,
}) => {
  const { setEntityName } = useContext(EntityContext);

  useEffect(() => {
    if (title) setEntityName({ entityName: title, entityTitle: headerTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <Box className="titleBarBox">
      <Grid container justifyContent="space-between" className="titleBar">
        <Grid item alignItems={"left"} className="title">
          <Typography className="titleBarTitle">{title}</Typography>
        </Grid>

        <Grid item alignItems={"right"} display={"flex"} gap={1}>
          {buttonTitle && onClick && (
            <>
              {buttonTitle === "Create" ? (
                <IconButton
                  className="TitleBarIcon"
                  size="medium"
                  id={`${title}-${buttonTitle}`}
                  disabled={disabled ?? false}
                  onClick={() => onClick && onClick()}
                  aria-label={`${title}-${buttonTitle}`}
                >
                  <AddIcon />
                </IconButton>
              ) : (
                <LoadingButton
                  className="TitleBarButton"
                  loading={buttonLoading}
                  variant="contained"
                  size="small"
                  id={`${title}-${buttonTitle}`}
                  disabled={disabled ?? false}
                  onClick={() => onClick && onClick()}
                >
                  {buttonTitle}
                </LoadingButton>
              )}
            </>
          )}
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TitleBar;
