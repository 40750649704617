/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import { FC } from "react";
import { Member } from "../../../../../shared/interfaces/member";

interface CourseDetails {
  memberData: Member;
}
const CourseList: FC<CourseDetails> = ({ memberData }) => {
  return (
    <Container className="tableListContainer">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Course Name</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Specialization</TableCell>
              {/* <TableCell>Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {memberData?.memberCourse?.map((data: any) => (
              <TableRow>
                <TableCell>{data.course.courseName ?? "No Data"}</TableCell>
                <TableCell>{data.course.level ?? "No Data"}</TableCell>
                <TableCell>
                  {data.specialization ? data?.specialization : "No Data"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default CourseList;
