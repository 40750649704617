import "./styles.css";
import { useContext, useEffect } from "react";
import { EntityContext } from "../../../../contexts/EntityContext";
import {
  getLocalStorageData,
  clearLocalStorageData,
} from "../../../../shared/utils/localStorage";
import { Box, Grid, Typography } from "@mui/material";
import Membership from "../../../././../assets/img/membership.svg";
import Certificate from "../../../././../assets/img/certificate.svg";
import Jobs from "../../../././../assets/img/jobs.svg";
import Welfare from "../../../././../assets/img/welfare.svg";
import Yogam from "../../../././../assets/img/yogam.svg";
import Bookings from "../../../././../assets/img/bookings.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BarChart } from "@mui/x-charts";
import useQuery from "../../../../shared/components/hooks/useQuery";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { setEntityName } = useContext(EntityContext);
  useEffect(() => {
    getLocalStorageData("token");
    setEntityName({ entityName: "Dashboard", entityTitle: "Dashboard" });
  }, [setEntityName]);

  const { data } = useQuery("/admin/request-service-status/dashboard");
  const requestData = data as any;

  useEffect(() => {
    const token = getLocalStorageData("token");
    if (token) {
    } else {
      clearLocalStorageData();
      window.location.href = "/login";
    }
  }, []);
  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Membership", 159, 6.0, 24, 4.0),
    createData("Certificate", 237, 9.0, 37, 4.3),
    createData("Jobs", 262, 16.0, 24, 6.0),
    createData("Welfare Schemes", 305, 3.7, 67, 4.3),
    createData("Yogam membership", 356, 16.0, 49, 3.9),
    createData("Bookings", 356, 16.0, 49, 3.9),
  ];

  const navigate = useNavigate();

  const handleBoxClick = (url: string) => {
    // Redirect to the branchMembership/membership-requests route
    navigate(url, { replace: true });
  };
  return (
    <>
      <Box className="dashBoardMain">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box
              className="dashboardTiles"
              onClick={() =>
                handleBoxClick("/branchMembership/membership-requests")
              }
            >
              <img src={Membership} className="dashboardIcons" alt="" />
              <div className="countSection">
                {requestData?.membershipRequests}
              </div>
              <Typography className="dashboardTilesText">Membership</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              className="dashboardTiles"
              onClick={() =>
                handleBoxClick("/certificate-management/certificate-requests")
              }
            >
              <img src={Certificate} className="dashboardIcons" alt="" />
              <div className="countSection">
                {requestData?.certificateRequests}
              </div>
              <Typography className="dashboardTilesText">
                Certificate
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              className="dashboardTiles"
              onClick={() => handleBoxClick("/job-management/job-requests")}
            >
              <img src={Jobs} className="dashboardIcons" alt="" />
              <div className="countSection">{requestData?.jobRequests}</div>
              <Typography className="dashboardTilesText">Jobs</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              className="dashboardTiles"
              onClick={() =>
                handleBoxClick("/welfare-schemes/welfare-requests")
              }
            >
              <img src={Welfare} className="dashboardIcons" alt="" />
              <div className="countSection">{requestData?.welfareRequests}</div>
              <Typography className="dashboardTilesText">
                Welfare Schemes
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box
              className="dashboardTiles"
              onClick={() =>
                handleBoxClick("/yogam-membership/yogam-membership-requests")
              }
            >
              <img src={Yogam} className="dashboardIcons" alt="" />
              <div className="countSection">
                {requestData?.yogamMembershipReques}
              </div>
              <Typography className="dashboardTilesText">
                Yogam Membership
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box
              className="dashboardTiles"
              onClick={() =>
                handleBoxClick("/facility-management/facility-bookings")
              }
            >
              <img src={Bookings} className="dashboardIcons" alt="" />
              <div className="countSection">
                {requestData?.facilityBookingRequests}
              </div>
              <Typography className="dashboardTilesText">Bookings</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Box className="dashboardSection">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">January</TableCell>
                      <TableCell align="right">February</TableCell>
                      <TableCell align="right">March</TableCell>
                      <TableCell align="right">April</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.calories}</TableCell>
                        <TableCell align="right">{row.fat}</TableCell>
                        <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="dashboardSection">
              <Box className="graphHeader">
                <Typography variant="h4">Bookings</Typography>
              </Box>
              {/* SVG Gradients for Each Bar */}
              <svg width="0" height="0">
                <defs>
                  <linearGradient
                    id="yellowGradient1"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor="#FFA500" /> {/* Gold */}
                    <stop offset="100%" stopColor="#ffdc25" /> {/* Orange */}
                  </linearGradient>
                  <linearGradient
                    id="yellowGradient2"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor="#ffdc25" /> {/* Yellow */}
                    <stop offset="100%" stopColor="#FFFF00" /> {/* Gold */}
                  </linearGradient>
                  <linearGradient
                    id="yellowGradient3"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor="#ffdc25" />{" "}
                    {/* LemonChiffon */}
                    <stop offset="100%" stopColor="#FFFACD" /> {/* Gold */}
                  </linearGradient>
                </defs>
              </svg>

              {/* BarChart with Different Gradients */}
              <BarChart
                xAxis={[
                  {
                    scaleType: "band",
                    data: ["group A", "group B", "group C"],
                  },
                ]}
                series={[
                  { data: [4, 3, 5], color: "url(#yellowGradient1)" }, // 1st bar gradient
                  { data: [1, 6, 3], color: "url(#yellowGradient2)" }, // 2nd bar gradient
                  { data: [2, 5, 6], color: "url(#yellowGradient3)" }, // 3rd bar gradient
                ]}
                height={300}
                barLabel="value"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
