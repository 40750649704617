import axios from "axios";
import {
  clearLocalStorageData,
  getLocalStorageData,
  setLocalStorageData,
} from "./shared/utils/localStorage";
import { formatAxiosError } from "./shared/utils/errorHandler";

// create an axios instance
const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // url = base url + request url
  // timeout: 5000, // request timeout
});

// request interceptor do something before request is sent
service.interceptors.request.use(
  (config: any) => {
    if (!config.headers.hasOwnProperty("Authorization")) {
      const token = getLocalStorageData("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    if (config.data instanceof FormData) {
      config.headers["content-type"] = "multipart/form-data";
    } else {
      config.headers["content-type"] = "application/json";
    }
    config.headers["Accept"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const localStorageToken = getLocalStorageData("token");
    if (
      response.headers?.token &&
      response.headers?.token !== undefined &&
      response.headers?.token !== "undefined" &&
      response.headers?.token !== localStorageToken
    ) {
      setLocalStorageData("token", response.headers?.token);
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      // alert("Session Expired");
      clearLocalStorageData();
      window.location.href = "/login";
    }
    const formattedError = formatAxiosError(error?.response?.data);
    return Promise.reject(formattedError);
  }
);

export default service;
