export const getFacilityEditFields = (facilityData: any) => [
  {
    label: "Facility Type:",
    value: facilityData?.typeOfFacility,
    formField: false,
  },
  {
    id: "facility-name",
    name: "name",
    label: "Name:",
    type: "text",
    value: facilityData?.name,
    placeholder: "Facility Name",
    required: true,
  },

  {
    id: "facility-dailyRate",
    name: "dailyRate",
    label: "Daily Rate:",
    type: "text",
    value: facilityData?.dailyRate,
    placeholder: "Daily Rate",
    required: true,
  },
  {
    id: "facility-reducedRateFromSecondDay",
    name: "reducedRateFromSecondDay",
    label: "Discount Rate:",
    type: "text",
    value: facilityData?.reducedRateFromSecondDay,
    placeholder: "Discount Rate",
    required: false,
  },

  {
    label: "Availability Status:",
    value: facilityData?.availabilityStatus,
    formField: false,
  },
  {
    label: "Description:",
    value: facilityData?.description,
    formField: false,
  },
];
