import "./styles.css";
import { useContext } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { SidebarContext } from "../../../contexts/SidebarContext";
import ZsBreadcrumbs from "../../../shared/components/breadcrumbs";
import { getLocalStorageData } from "../../../shared/utils/localStorage";
function Header() {
  const userName = getLocalStorageData("user-name");
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <Container className="headerContainer">
      <Grid container className="subContainer">
        <Box
          component="span"
          sx={{
            display: { lg: "none", xs: "inline-block" },
            margin: 0.3,
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Grid item xs={10}>
          <ZsBreadcrumbs />
        </Grid>
        <Grid item xs={2} className="userContainer">
          <Typography variant="h3">{userName}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Header;
