import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FC, useContext, useState, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
// import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SaveIcon from "@mui/icons-material/Save";
// import NotifyIcon from "@mui/icons-material/Notifications";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { yogamMembershipRequest } from "../../../../../shared/interfaces/yogam-membership-request";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import { ErrorMessage } from "../../../../../shared/components/errorMessage";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";
import { SnackBarAlertContext } from "../../../../../contexts/SnackbarContext";

const UnionApprovalList: FC = () => {
  const snackBarAlertContext = useContext(SnackBarAlertContext);

  const { payload, setPayload } = useContext(SidebarContext);
  const [openNotifyModal, setOpenNotifyModal] = useState<boolean>(false);
  const [selectedMemberRequest, setSelectedMemberRequest] = useState<any>(null);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const { modifyData } = useMutation("/yogam-membership/union-approval");
  const [statusFilter, setStatusFilter] = useState<string | null>("Pending");
  const { loading, data, fetchData, error } = useQuery(
    "/admin/yogam-membership-request/union-approval-list",
    {
      params: {
        ...payload,
        unionStatus: statusFilter === "All" ? null : statusFilter,
      },
    }
  );

  const [formData, setFormData] = useState<any>({});

  const handleSndpMemberRegNoOnBlur = (id: number) => {
    const sndpMemberRegNo = formData[id]?.sndpMemberRegNo;

    if (!errorMessage[id]) {
      errorMessage[id] = [];
    }

    const errorIndex = errorMessage[id].indexOf("Required field");

    if (sndpMemberRegNo && errorIndex !== -1) {
      errorMessage[id].splice(errorIndex, 1);
      console.log(
        `Error removed for ID ${id}: Union Membership Number is now valid`
      );
    }
  };

  const renderCell = (row: any, col: string) => {
    if (col === "status") {
      if (approvePermission) {
        if (["Approved", "Rejected"].includes(row.unionStatus)) {
          return row.unionStatus;
        }
        return (
          <FormControl fullWidth>
            <Select
              value={formData[row.id]?.unionStatus || row.unionStatus}
              onChange={(e) => {
                const updatedStatus = e.target.value;
                setFormData((prev: any) => ({
                  ...prev,
                  [row.id]: {
                    ...prev[row.id],
                    unionStatus: updatedStatus,
                    memberID: row.memberID,
                  },
                }));

                setSelectedIds((prevSelected) => {
                  const newSelected = new Set(prevSelected); // Convert to a Set to ensure uniqueness
                  newSelected.add(row.id); // Add the new ID

                  return Array.from(newSelected); // Convert back to an array
                });
              }}
            >
              <MenuItem key={0} value="Pending">
                Pending
              </MenuItem>
              <MenuItem key={1} value="Approved">
                Approved
              </MenuItem>
              <MenuItem key={3} value="Rejected">
                Rejected
              </MenuItem>
            </Select>
          </FormControl>
        );
      } else {
        return row.unionStatus;
      }
    }
    if (
      col === "membershipNumber" &&
      formData[row.id]?.unionStatus === "Approved"
    ) {
      if (row.unionStatus === "Approved") {
        return row.sndpMemberRegNo;
      }
      return (
        <>
          <TextField
            value={formData[row.id]?.sndpMemberRegNo || ""}
            onChange={(e) => {
              const updatedRegNo = e.target.value;
              setFormData((prev: any) => ({
                ...prev,
                [row.id]: {
                  ...prev[row.id],
                  sndpMemberRegNo: updatedRegNo,
                },
              }));
              handleSndpMemberRegNoOnBlur(row.id);
            }}
            fullWidth
            error={errorMessage[row.id]?.length > 0 ? true : false}
          />
          {errorMessage !== undefined && (
            <ErrorMessage message={errorMessage[row.id] ?? null} />
          )}
        </>
      );
    }

    if (col === "remarks" && formData[row.id]?.unionStatus === "Rejected") {
      if (row.unionStatus === "Rejected") {
        return row.remarks;
      }
      return (
        <TextField
          value={formData[row.id]?.remarks || ""}
          onChange={(e) => {
            const updatedRemarks = e.target.value;
            setFormData((prev: any) => ({
              ...prev,
              [row.id]: {
                ...prev[row.id],
                remarks: updatedRemarks,
              },
            }));
          }}
          label="Remarks"
          fullWidth
        />
      );
    }
    // if (col === "actions") {
    //   return (
    //     <>
    //       <IconButton
    //         onClick={() => handleNotifyModalOpen(row)}
    //         color="secondary"
    //         aria-label="send notification"
    //       >
    //         <NotificationsActiveIcon fontSize="small" />
    //       </IconButton>
    //     </>
    //   );
    // }
    return row[col];
  };

  const handleSubmit = async () => {
    try {
      let hasErrors = false;

      const updates = selectedIds.map((item) => {
        const unionStatus = formData[item]?.unionStatus;
        const memberID = formData[item]?.memberID;

        const sndpMemberRegNo =
          unionStatus === "Approved"
            ? formData[item]?.sndpMemberRegNo ?? null
            : null;

        errorMessage[item] = [];

        // if (unionStatus === "Approved" && !sndpMemberRegNo) {
        //   const errorMsg = "Required field";
        //   // errorMessage[item.id].push(null);
        //   errorMessage[item].push(errorMsg);
        //   handleSndpMemberRegNoOnBlur(item);
        //   hasErrors = true;
        // }

        return {
          id: item,
          memberID: memberID,
          unionStatus,
          sndpMemberRegNo,
          remarks: unionStatus === "Rejected" ? formData[item]?.remarks : null,
        };
      });

      if (!hasErrors && updates.length > 0) {
        await modifyData(
          "/admin/yogam-membership-request/update-union-status",
          "put",
          updates
        );
        setFormData({});
        setSelectedIds([]);
        fetchData();
      }
    } catch (error) {}
  };

  // const notifyPermission = (data as any)?.entityPermissions
  //   ? (data as any)?.entityPermissions?.includes("Union Approval-Notify")
  //   : true;

  const approvePermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Union Approval-Approve")
    : true;

  const membershipRequestDisplayColumns = [
    "memberCode",
    "fullName",
    "houseNameEng",
    "familyHead",
    "status",
    "membershipNumber",
    "remarks",
  ].filter((item): item is string => item !== null);

  let membershipList: yogamMembershipRequest[] = [];
  if (data && (data as any)?.items) {
    membershipList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        memberID: item?.memberID,
        memberCode: item?.member?.code,
        fullName: item?.member?.fullNameEng,
        membershipNumber: item?.member?.sndpMemberRegNo,
        houseNameEng: item?.member?.addressDetails?.houseNameEng,
        familyHead: item?.member?.familyHeadDetails?.fullNameEng,
        unionStatus: item?.unionStatus,
        remarks: item?.remarks,
      };
    });
  }

  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    memberCode: `"member"."code"`,
    fullName: `"member"."fullNameEng"`,
    houseNameEng: `"member"."addressDetails"#>>'{houseNameEng}'`,
    familyHead: `"member"."familyHeadDetails"#>>'{fullNameEng}'`,
    status: "unionStatus",
    createdOn: "createdAt",
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNotifyModalOpen = (member: any) => {
    if (
      typeof member === "object" &&
      !Array.isArray(member) &&
      member !== null
    ) {
      if (member.unionStatus === "Approved") {
        setSelectedMemberRequest([member.memberID]);
        setMessage(
          ` ${member?.fullName}'s Yogam membership has been approved.`
        );
        setOpenNotifyModal(true);
      } else {
        snackBarAlertContext.set({
          status: true,
          type: "error",
          message: "Request should be approved to send notification",
        });
      }
    } else {
      if (selectedIds.length > 0) {
        let filteredMemberIDs = null;
        if (selectedIds?.length === membershipList?.length) {
          filteredMemberIDs = membershipList
            .filter((member) => member.unionStatus === "Approved")
            .map((member) => member.memberID);
        } else {
          filteredMemberIDs = membershipList
            .filter(
              (member) =>
                selectedIds.includes(member.id) &&
                member.unionStatus === "Approved"
            )
            .map((member) => member.memberID);
        }
        setSelectedMemberRequest(filteredMemberIDs);

        if (filteredMemberIDs?.length > 0) {
          setMessage(
            `${filteredMemberIDs?.length} members Yogam membership has been approved.`
          );
          setOpenNotifyModal(true);
        }
      }
    }
  };

  const handleClose = () => {
    setOpenNotifyModal(false);
  };

  const handleNotificationSubmit = () => {
    modifyData(`/notification/send`, "post", {
      memberIds: selectedMemberRequest,
      message: message,
    });
    setOpenNotifyModal(false);
  };

  useEffect(() => {
    if (statusFilter) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  const titleBarNode = (
    <>
      <TitleBarSelectBox
        fieldProps={{
          id: "yogam-membership-request-filter",
          name: "yogam-membership-request-filter",
        }}
        value={statusFilter ?? "All"}
        onChange={(value: string) => setStatusFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: "Pending", label: "Pending" },
          { value: "Approved", label: "Approved" },
          { value: "Rejected", label: "Rejected" },
        ]}
      />
      <SearchBox />
      {approvePermission ? (
        <Tooltip arrow title="Save">
          <IconButton color="secondary" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {/* {notifyPermission ? (
        <Tooltip arrow title="Notify">
          <IconButton
            color="secondary"
            onClick={() => handleNotifyModalOpen(selectedMemberRequest)}
          >
            <NotifyIcon />
          </IconButton>
        </Tooltip>
      ) : null} */}
    </>
  );

  const titleBar = {
    title: "Union Approval",
    headerTitle: "Union Approval",
    titleBarNode: titleBarNode,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Family-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <>
          <DataTable
            entityName="Union Approval"
            data={membershipList}
            displayColumns={membershipRequestDisplayColumns}
            linkColumn="fullNameEng"
            linkUrl=""
            sortableFields={sortFields}
            pagination={payload}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            // showCheckboxes={true}
            refetchFunction={fetchData}
            // onUpdateStatus={setSelectedIds}
            // newAction={handleNotifyModalOpen}
            renderCell={renderCell}
          />
          <>
            <ConfirmDialog
              title={"Notify Member"}
              openConfirmDialog={openNotifyModal}
              setOpenConfirmDialog={handleClose}
              message={message}
              showCancelButton={false}
              handleSubmit={handleNotificationSubmit}
            />
          </>
        </>
      )}
    </TableViewContainer>
  );
};

export default UnionApprovalList;
