import { FC } from "react";

interface ComponentProps {
  src?: string | null;
  alt?: string;
}

const SingleEntityImageViewer: FC<ComponentProps> = ({
  src = null,
  alt = "Image",
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        minHeight: "100px",
        borderRadius: "8px",
        backgroundColor: src ? "transparent" : "#f0f0f0", // Gray background when no image
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {src ? (
        <img
          src={src}
          alt={alt}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        />
      ) : (
        <p>No image available</p>
      )}
    </div>
  );
};

export default SingleEntityImageViewer;
