/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@mui/material";
import { FC, Fragment, useContext, useEffect } from "react";
import { useFormik } from "formik";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import InputTextfield from "../../../../../shared/components/textField";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { useParams } from "react-router-dom";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { Gender } from "../../../../../shared/enum";

const AdministratorCreate: FC = () => {
  const { id } = useParams();
  const { payload, setPayload } = useContext(SidebarContext);

  const { data, fetchData } = useLazyQuery(`/admin/administrators/${id}`, null);
  const { loading: apiLoading, modifyData } = useMutation(
    "/userManagement/user"
  );

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);
  const { data: rolesData } = useQuery("/admin/roles", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
      enabled: true,
    },
  });

  const selectedRole: any =
    data && (data as any)?.data?.roleID
      ? {
          value: (data as any)?.data?.roleID,
          label: (data as any)?.data?.role?.name,
        }
      : null;

  const roles = (rolesData as any)?.items?.map((role: any) => ({
    value: role?.id,
    label: role?.name,
  }));

  const textFields = [
    {
      id: "admin-fullName",
      name: "fullName",
      label: "Full Name",
      type: "text",
      placeholder: "Enter Full Name",
      required: true,
    },
    {
      id: "admin-phone",
      name: "phone",
      label: "Phone",
      type: "text",
      placeholder: "Enter Phone Number",
      required: true,
    },
    {
      id: "admin-email",
      name: "adminEmail",
      label: "Email Address",
      type: "text",
      placeholder: "Enter Email Address",
      required: true,
    },
    {
      id: "admin-password",
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Enter Password",
      required: true,
      disabled: id ? true : false,
    },
  ];

  const adminForm = useFormik({
    initialValues: {
      fullName: (data as any)?.data?.fullName ?? "",
      adminEmail: (data as any)?.data?.email ?? "",
      phone: (data as any)?.data?.phone ?? "",
      password: (data as any)?.data?.password ?? "",
      roleID: (data as any)?.data?.roleID ?? "",
      gender: (data as any)?.data?.gender ?? null,
    },
    enableReinitialize: true,
    validateOnBlur: true,

    validationSchema: getValidationSchema([
      "fullName",
      "adminEmail",
      id ?? "password",
      "gender",
      "phone",
      "roleID",
    ]),

    onSubmit: async (formData: any) => {
      if (id) {
        modifyData(
          `/admin/administrators/${id}`,
          "put",
          {
            ...formData,
            email: adminForm.values.adminEmail,
          },
          adminForm
        );
      } else {
        modifyData(
          `/admin/administrators`,
          "post",
          {
            ...formData,
            email: adminForm.values.adminEmail,
          },
          adminForm
        );
      }
    },
  });

  const titleBar = {
    headerTitle: "Administrators - Create",
    title: "Create Administrator",
    buttonTitle: "Save",
    onClick: () => adminForm.handleSubmit(),
    dirty: adminForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <>
      <FormContainer titleBar={titleBar}>
        <Box className="border-custom">
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
            {textFields.map((field, index: number) => (
              <Fragment key={field.id}>
                <Grid item md={6} sm={6} xs={12}>
                  <InputTextfield
                    form={adminForm}
                    fieldProps={field}
                    key={index}
                    disabled={field.disabled}
                  />
                </Grid>
              </Fragment>
            ))}
            <Grid item md={6} sm={6} xs={12}>
              <ZSAutocomplete
                options={roles}
                placeholder="Select Role"
                label="Role"
                required
                setValue={(selectedOption: any) => {
                  adminForm.setFieldTouched("roleID", true);
                  if (selectedOption?.value) {
                    adminForm.setFieldValue("roleID", selectedOption?.value);
                  } else {
                    adminForm.setFieldValue("roleID", "");
                  }
                }}
                defaultValue={selectedRole}
                setBlur={() => {
                  adminForm.setFieldTouched("roleID", true);
                }}
                errorMessage={
                  ((adminForm.touched as any)?.roleID &&
                    (adminForm.errors as any)?.roleID) ??
                  null
                }
                refetchFunction={(searchString: string) => {
                  setPayload((prevData: any) => {
                    return { ...prevData, search: searchString };
                  });
                }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  id: "admin-gender",
                  name: "gender",
                  placeholder: "Select Gender",
                  label: "Gender",
                  required: true,
                }}
                options={[
                  { label: Gender.Male, value: Gender.Male },
                  { label: Gender.Female, value: Gender.Female },
                  { label: Gender.Other, value: Gender.Other },
                ]}
                form={adminForm}
                value={adminForm.values?.gender}
                errorMessage={
                  ((adminForm.touched as any)?.gender &&
                    (adminForm.errors as any)?.gender) ??
                  null
                }
              />
            </Grid>
          </Grid>
        </Box>
      </FormContainer>
    </>
  );
};
export default AdministratorCreate;
