import moment from "moment";
import { parse, isValid, format, isAfter, isBefore } from "date-fns";

export const dateInputFromatHelper = (dateValue: string, formName: any) => {
  let value = dateValue.replace(/\D/g, "");
  let dayPart = value.slice(0, 2);
  let monthPart = value.slice(2, 4);
  let yearPartPart = value.slice(4, 8);

  if (dayPart === "00") dayPart = "01";
  if (monthPart === "00") monthPart = "01";

  if (parseInt(dayPart, 10) > 31) dayPart = "31";
  if (parseInt(monthPart, 10) > 12) monthPart = "12";

  if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
  else if (
    ["04", "06", "09", "11"].includes(monthPart) &&
    parseInt(dayPart, 10) > 30
  )
    dayPart = "30";
  value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
  value = value + (value.length >= 4 ? "/" + yearPartPart : "");
  if (
    dateValue?.length === 2 &&
    formName?.values?.dateOfResidence?.charAt(
      formName?.values?.dateOfResidence?.length - 1
    ) === "/"
  ) {
    value = dayPart;
  }
  return value;
};

export const dateToISOString = (date: string): string => {
  const dateObject = moment.utc(date, "DD/MM/YYYY");
  const isoString = dateObject.toISOString();
  return isoString;
};

export function validateDateFormat(dateString: string, formatString: string) {
  const parsedDate = parse(dateString, formatString, new Date());
  return isValid(parsedDate) && dateString === format(parsedDate, formatString);
}

export function validateNotFutureDate(
  dateString: string,
  formatString: string
) {
  const parsedDate = parse(dateString, formatString, new Date());
  const today = new Date();
  return !isAfter(parsedDate, today);
}

export function validateNotPastDate(dateString: string, formatString: string) {
  const parsedDate = parse(dateString, formatString, new Date());
  const today = new Date();
  return !isBefore(parsedDate, today);
}
