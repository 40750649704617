import "./styles.css";
import { FC, useState, useContext } from "react";
import { useFormik } from "formik";
import { Box, Grid, IconButton, Tabs, Tab } from "@mui/material";
import { useParams } from "react-router-dom";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import InputTextfield from "../../../../../shared/components/textField";
import { ZSAutocomplete } from "../../../../../shared/components/autoComplete";
import { Administrator } from "../../../../../shared/interfaces/administrator";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { FormContainer } from "../../../../../shared/components/formContainer";
import TabPanel from "../../../../../shared/components/tabpanel/tabpanel";
import Permissions from "../../../../../shared/components/permissionList";
import { ViewContainer } from "../../../../../shared/components/viewContainer";
import SingleEntityContainer from "../../../../../shared/components/singleEntityContainer";
import { TitleBarProps } from "../../../../../shared/components/titleBar";
import { getAdminEditFields } from "./adminEditFields";
import { LoadingButton } from "@mui/lab";
import { genderOptions } from "../../../../../shared/constants/selectOptions";

const AdministratorEditView: FC = () => {
  const { id } = useParams();
  const { loading, data, fetchData } = useQuery(
    `/admin/administrators/${id}`,
    null
  );
  const { payload, setPayload } = useContext(SidebarContext);
  const { modifyData } = useMutation();
  const [activeTab, setActiveTab] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editStatus, setEditStatus] = useState(false);

  const adminData: Administrator = (data as any)?.data || {};

  const { data: rolesData } = useQuery("/admin/roles", {
    params: {
      ...payload,
      take: 25,
      sortOrder: "ASC",
      enabled: true,
    },
  });

  const selectedRole: any =
    data && (data as any)?.data?.user?.roleID
      ? {
          value: (data as any)?.data?.user?.roleID,
          label: (data as any)?.data?.user?.role?.name,
        }
      : null;

  const editPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Administrators-Update")
    : true;

  const editAdminPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes("Administrators-Permission")
    : true;

  const roles = (rolesData as any)?.items?.map((role: any) => ({
    value: role?.id,
    label: role?.name,
  }));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const adminForm = useFormik({
    initialValues: {
      fullName: adminData?.fullName,
      adminEmail: adminData?.email,
      phone: adminData?.phone,
      gender: adminData.gender,
      roleID: adminData?.user?.roleID,
      rolePermissions: adminData?.user?.permissions,
    },
    enableReinitialize: true,
    validateOnBlur: true,

    validationSchema: getValidationSchema([
      "fullName",
      "adminEmail",
      "gender",
      "phone",
      "roleID",
    ]),

    onSubmit: async (formData: any) => {
      let response;
      if (id) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        response = await modifyData(
          `/admin/administrators/${id}`,
          "put",
          {
            ...formData,
          },
          adminForm
        );
      }
      if (response) {
        fetchData();
        setActiveTab(0);
        closeAdminEdit();
      }
    },
  });

  const closeAdminEdit = () => {
    fetchData();
    setEditStatus(false);
  };

  const updateAdminRoles = () => {
    if (id) {
      setButtonLoading(true);
      modifyData(`/admin/administrators/${id}/update-permissions`, "put", {
        permissions: adminForm?.values?.rolePermissions,
      });
    }
    fetchData();
    setButtonLoading(false);
  };

  const editTitlebar: TitleBarProps = {
    headerTitle: "Administrator -  Edit",
    title: "Edit Admistrator",
    buttonTitle: "Save",
    onClick: () => adminForm.handleSubmit(),
    dirty: adminForm.dirty,
    buttonLoading: loading,
  };

  const viewTitleBar: TitleBarProps = {
    title: "Administrator Details",
    headerTitle: "Administrator Details",
  };
  const adminEditFields = getAdminEditFields(adminData);

  const titleBarNode = (
    <IconButton
      className="TitleBarIcon"
      size="medium"
      id={`request-edit`}
      onClick={() => closeAdminEdit()}
      aria-label={`request-edit`}
    >
      <CloseIcon />
    </IconButton>
  );

  const adminEditTooltipNode = editPermission && (
    <IconButton
      className="TitleBarIcon"
      size="medium"
      id={`Family-edit`}
      onClick={() => setEditStatus(true)}
      aria-label={`User-edit`}
    >
      <EditIcon />
    </IconButton>
  );

  const updatePermissionButton =
    editAdminPermission && activeTab ? (
      <LoadingButton
        loading={buttonLoading}
        variant="contained"
        color="primary"
        onClick={() => {
          updateAdminRoles();
        }}
        disabled={!editAdminPermission} // Disable button if permission is not granted
        className="tabBarButton"
      >
        Save
      </LoadingButton>
    ) : (
      <></>
    );

  return (
    <>
      {editStatus && (
        <>
          <FormContainer titleBar={editTitlebar} titleBarNode={titleBarNode}>
            <Box className="form-border-custom">
              <Grid
                container
                alignItems="stretch"
                columnSpacing={4}
                rowSpacing={2}
              >
                {adminEditFields.map(
                  (field: any, index: number) =>
                    (field?.formField === undefined ||
                      field.formField === true) && (
                      <Grid item md={6} sm={6} xs={12} key={field.id}>
                        <InputTextfield
                          form={adminForm}
                          fieldProps={field}
                          key={index}
                        />
                      </Grid>
                    )
                )}
                <Grid item md={6} sm={6} xs={12} key="gender">
                  <ZSSelectBox
                    fieldProps={{
                      id: "admin-gender",
                      name: "gender",
                      placeholder: "Select gender",
                      label: "Gender",
                      required: true,
                    }}
                    options={genderOptions}
                    form={adminForm}
                    value={adminForm.values?.gender}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12} key="role">
                  <ZSAutocomplete
                    placeholder="Select role"
                    options={roles}
                    label="Role"
                    required
                    setValue={(selectedOption: any) => {
                      adminForm.setFieldTouched("roleID", true);
                      if (selectedOption?.value) {
                        adminForm.setFieldValue(
                          "roleID",
                          selectedOption?.value
                        );
                      } else {
                        adminForm.setFieldValue("roleID", "");
                      }
                    }}
                    defaultValue={selectedRole ?? null}
                    setBlur={() => {
                      adminForm.setFieldTouched("roleID", true);
                    }}
                    refetchFunction={(searchString: string) => {
                      setPayload((prevData: any) => {
                        return {
                          ...prevData,
                          search: searchString,
                        };
                      });
                    }}
                    errorMessage={
                      ((adminForm.touched as any)?.roleID &&
                        (adminForm.errors as any)?.roleID) ??
                      null
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </FormContainer>
        </>
      )}
      {!editStatus && (
        <ViewContainer
          loading={loading}
          titleBar={viewTitleBar}
          tabs={
            <div className="tabs-container">
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Details" className="tab" />
                <Tab label="Permissions" className="tab" />
              </Tabs>
              {updatePermissionButton}
            </div>
          }
        >
          <div className="tab-panel">
            <TabPanel value={activeTab} index={0}>
              <div className="entity-container">
                <SingleEntityContainer
                  entityFields={adminEditFields}
                  entityTitle="Details"
                  tooltips={adminEditTooltipNode}
                ></SingleEntityContainer>
              </div>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <div className="entity-container">
                <Permissions
                  rolePermissions={adminForm?.values?.rolePermissions}
                  rolesForm={adminForm}
                />
              </div>
            </TabPanel>
          </div>
        </ViewContainer>
      )}
    </>
  );
};

export default AdministratorEditView;
