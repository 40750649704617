import { Container } from "@mui/material";
import { FC, useContext, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { certificateRequest } from "../../../../../shared/interfaces/certificate-request";
import ArticleIcon from "@mui/icons-material/Article";
import useQuery from "../../../../../shared/components/hooks/useQuery";

const CertificateList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const { loading, data, fetchData, error } = useQuery("/admin/certificate", {
    params: {
      ...payload,
    },
  });
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayColumns = [
    "applicantName",
    "memberName",
    "certificateType",
    "houseName",
    "certificateNumber",
    "issuedBy",
    // "issueDate",
    "preview",
  ];

  const sortFields = {
    houseName: `"member"."addressDetails"#>>'{houseNameEng}'`,
    memberName: `"member"."fullNameEng"`,
    applicantName: `"certificateRequest->applicant"."fullNameEng"`,
    certificateType: `"certificateRequest->certificateType"."certificateTypeName"`,
  };

  // const sortableColumns = ["fullNameEng"];
  let certificateList: certificateRequest[] = [];
  if (data && (data as any)?.items) {
    certificateList = (data as any)?.items.map((certificate: any) => {
      return {
        id: certificate.id,
        applicantName: certificate?.certificateRequest?.applicant?.fullNameEng,
        memberName: certificate?.member?.fullNameEng,
        certificateType:
          certificate?.certificateRequest?.certificateType?.certificateTypeName,
        houseName: certificate?.member?.addressDetails?.houseNameEng,
        certificateNumber: certificate?.certificateNumber,
        issuedBy:
          certificate?.certificateRequest?.approvedByUser?.admin?.fullName,
        // issueDate: moment(certificate?.issueDate).format("DD/MM/YYYY"),
        preview: certificate?.certificateUrl,
      };
    });
  }

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Certificates Issued"
        headerTitle="Certificates Issued"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Certificate-Create")
            : false
        }
      >
        <SearchBox />
      </TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          data={certificateList}
          displayColumns={displayColumns}
          icon={<ArticleIcon fontSize="small" />}
          linkIconColumn={"preview"}
          sortableFields={sortFields}
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default CertificateList;
