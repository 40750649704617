import { useFormik } from "formik";
import { Box, Grid } from "@mui/material";
import { FC, useState } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { FormContainer } from "../../../../../shared/components/formContainer";
import ImageInputField from "../../../../../shared/components/imageInputField";
import { customizeValidationSchema } from "../customSchema";
import SingleEntityImageViewer from "../../../../../shared/components/singleEntityImageViewer";
// import { getValidationSchema } from "../../../../../shared/validations/schema";

const Banner: FC = () => {
  const { loading: apiLoading, modifyData } = useMutation(
    "/banner-management/banners"
  );
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const bannerForm = useFormik({
    initialValues: {
      name: "",
      formImage: "",
    },
    enableReinitialize: true,

    validationSchema: customizeValidationSchema(),
    validate: (values) => {
      const errors: any = {};
      if (values.formImage === "" || values.formImage === null) {
        errors.formImage =
          "Please select an image of formats: JPG, PNG, GIF. Max File Size: 2MB";
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("file", formData.formImage);
      modifyData(`/admin/banner/`, "post", formDataToSend, config);
    },
  });

  const titleBar = {
    title: "Create Banner Type",
    buttonTitle: "Save",
    headerTitle: "Banner Type",
    onClick: () => bannerForm.handleSubmit(),
    dirty: bannerForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={6} sm={6} xs={12}>
            <InputTextfield
              form={bannerForm}
              value={bannerForm?.values?.name}
              fieldProps={{
                id: "name",
                name: "name",
                label: "Name",
                type: "text",
                required: true,
                placeholder: "Enter Name",
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} key="banner-imageURL">
            <ImageInputField
              id="facility-image"
              name="formImage"
              label="Facility Image"
              setPreviewUrl={setPreviewUrl}
              form={bannerForm}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} key="banner-imagePreview">
            <SingleEntityImageViewer src={previewUrl} alt="Banner" />
          </Grid>
        </Grid>
      </Box>
    </FormContainer>
  );
};
export default Banner;
