import * as yup from "yup";
import {
  validateDateFormat,
  validateNotFutureDate,
  validateNotPastDate,
} from "../../../../../shared/utils/date-helper";
import moment from "moment";
const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
const dateFields = [
  "marriageDate",
  "fatherDeathDate",
  "motherDeathDate",
  "deathDate",
];
export const customizeValidationSchema = (fields: string[]) => {
  const dynamicSchema: any = {};

  // Add validations for heirDetails if present
  if (fields?.includes("heirDetails")) {
    dynamicSchema["heirDetails"] = yup.array().of(
      yup.object().shape({
        age: yup
          .string()
          .required("Age is required")
          .min(2, "Age must be at least 2 characters")
          .max(100, "Age cannot exceed 100 characters"),
        name: yup
          .string()
          .required("Name is required")
          .min(2, "Name must be at least 2 characters")
          .max(100, "Name cannot exceed 100 characters"),
        "Son/Daughter": yup.string().required("Son/Daughter is required"),
      })
    );
  }

  // Add validations for relationDetails if present
  if (fields?.includes("relationDetail")) {
    dynamicSchema["relationDetail"] = yup.array().of(
      yup.object().shape({
        relation: yup
          .string()
          .required("Relation is required")
          .min(2, "Relation must be at least 2 characters")
          .max(100, "Relation cannot exceed 100 characters"),
        name: yup
          .string()
          .required("Name is required")
          .min(2, "Name must be at least 2 characters")
          .max(100, "Name cannot exceed 100 characters"),
        memberId: yup
          .string()
          .required("Member ID is required")
          .min(2, "Member ID must be at least 2 characters")
          .max(100, "Member ID cannot exceed 100 characters"),
      })
    );
  }

  // Add validations for other fields
  fields?.forEach((field: string) => {
    const formattedFieldName = field
      ?.replace(/([a-z])([A-Z])/g, "$1 $2")
      ?.replace(/^./, (str: any) => str?.toUpperCase());
    if (
      field !== "heirDetails" &&
      field !== "relationDetail" &&
      !dateFields?.includes(field)
    ) {
      dynamicSchema[field] =
        field === "certificateNumber"
          ? yup
              .string()
              .notRequired()
              .min(2, `${formattedFieldName} must be at least 2 characters`)
              .max(100, `${formattedFieldName} cannot exceed 100 characters`)
              .nullable()
          : yup
              .string()
              .required(`${formattedFieldName} is required`)
              .min(2, `${formattedFieldName} must be at least 2 characters`)
              .max(100, `${formattedFieldName} cannot exceed 100 characters`)
              .nullable();
    } else if (field !== "heirDetails" && field !== "relationDetail") {
      dynamicSchema[field] = yup
        .string()
        .required(`${formattedFieldName} is required`)
        .matches(
          dateRegex,
          `${formattedFieldName} must be in the format DD/MM/YYYY`
        )
        .test(
          "is-past-or-today",
          `${formattedFieldName} must not be a future date`,
          (value) => {
            if (!value) return true;
            const inputDate = validateNotFutureDate(value, "dd/MM/yyyy");
            return inputDate;
          }
        )
        .test(
          "is-past-or-today",
          `${formattedFieldName} is not valid`,
          (value) => {
            if (!value) return true;
            const inputDate = validateDateFormat(value, "dd/MM/yyyy");
            return inputDate;
          }
        )
        .nullable();
    }
  });

  return yup.object().shape(dynamicSchema);
};

export const previewValidationSchema = (fields: string[]) => {
  const dynamicSchema: any = {};
  if (fields?.includes("editorValue")) {
    dynamicSchema["editorValue"] = yup
      .string()
      .required("Certificate Content is required")
      .test(
        "not-empty",
        "Certificate Content cannot be an empty string",
        (value) => value !== ""
      );
    dynamicSchema["issueDate"] = yup
      .string()
      .required("Issue Date is required")
      .matches(dateRegex, "Issue Date must be in the format DD/MM/YYYY")
      .test("is-future-date", `Issue Date must not be a past date`, (value) => {
        if (!value) return true;
        const inputDate = validateNotPastDate(value, "dd/MM/yyyy");
        return (
          inputDate ||
          moment(value, "DD/MM/YYYY").isSameOrAfter(moment(), "day")
        );
      })
      .test("is-future-date", `Issue Date is not valid`, (value) => {
        if (!value) return true;
        const inputDate = validateDateFormat(value, "dd/MM/yyyy");
        return inputDate;
      });
  }
  fields?.forEach((field: string) => {
    const formattedFieldName = field
      ?.replace(/([a-z])([A-Z])/g, "$1 $2")
      ?.replace(/^./, (str: any) => str?.toUpperCase());
    if (
      field !== "editorValue" &&
      field !== "issueDate" &&
      field !== "issuedBy"
    ) {
      dynamicSchema[field] = yup
        .string()
        .required(`${formattedFieldName} is required`)
        .min(2, `${formattedFieldName} must be at least 2 characters`)
        .max(100, `${formattedFieldName} cannot exceed 100 characters`)
        .nullable();
    }
  });

  return yup.object().shape(dynamicSchema);
};
