import { Navigate } from "react-router-dom";
import Welfare from "../components/welfareCreate";
import WelfareList from "../components/welfareList";
import DiamondIcon from "@mui/icons-material/Diamond";
import WelfareView from "../components/welfareEditView";
const welfareRoute = {
  icon: DiamondIcon,
  name: "Welfare Scheme",
  link: "/welfare-schemes",
  path: "welfare-schemes",
  element: "",
  index: 70,
  children: [
    {
      path: "welfare",
      element: <WelfareList />,
      name: "Welfare Schemes",
      permission: "Welfare scheme-Read", // Permission name
      index: 0,
      link: "welfare-schemes/welfare",
      icon: DiamondIcon,
    },
    {
      path: "welfare/create",
      element: <Welfare />,
    },
    {
      path: "welfare/:id",
      element: <WelfareView />,
    },
    {
      path: "",
      element: <Navigate to="welfare" replace />,
    },
  ],
};
export default welfareRoute;
