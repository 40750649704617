import moment from "moment";
const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const formatDate = (date: any) => {
  return isoRegex.test(date);
};

export const getJobFields = (JobData: any) => [
  {
    id: "jobTitle",
    name: "jobTitle",
    label: "Job Title :",
    value: JobData?.jobTitle,
  },
  {
    id: "jobType",
    name: "jobType",
    label: "Job Type :",
    value: JobData?.jobType,
    formField: false,
  },
  {
    id: "location",
    name: "location",
    label: "Location :",
    value: JobData?.location,
  },
  {
    id: "name",
    name: "name",
    label: "Contact Name :",
    value: JobData?.contactDetails?.name ?? "Not Available",
  },
  {
    id: "email",
    name: "email",
    label: "Email :",
    value: JobData?.contactDetails?.email ?? "Not Available",
  },
  {
    id: "phone",
    name: "phone",
    label: "Phone :",
    value: JobData?.contactDetails?.phone ?? "Not Available",
  },
  {
    id: "url",
    name: "url",
    label: "Url :",
    value: JobData?.contactDetails?.url ?? "Not Available",
  },
  {
    id: "salaryRange",
    name: "salaryRange",
    label: "Salary Range :",
    value: JobData?.salaryRange,
  },

  {
    id: "noOfOpenings",
    name: "noOfOpenings",
    label: "No Of Openings :",
    value: JobData?.noOfOpenings ?? "Not Available",
  },
  {
    id: "applicationEndDate",
    name: "applicationEndDate",
    label: "Application end date :",
    value: formatDate(JobData?.applicationEndDate)
      ? moment(JobData?.applicationEndDate).format("DD/MM/YYYY")
      : moment(JobData?.applicationEndDate, "DD/MM/YYYY").format("DD/MM/YYYY"),
    formField: false,
  },
  {
    id: "description",
    name: "description",
    label: "Description :",
    value: JobData?.description ?? "Not Available",
    formField: false,
  },
];
