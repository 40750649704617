export const validateImageTypes = (file: File) => {
  const validTypes = ["image/jpeg", "image/png", "image/gif"];
  const maxSize = 2 * 1024 * 1024; // 2 MB
  return validTypes.includes(file.type) && file.size <= maxSize;
};

export const getImageDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
      URL.revokeObjectURL(objectUrl);
    };

    img.onerror = reject;
    img.src = objectUrl;
  });
};
