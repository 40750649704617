import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FC, useContext, useState, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import PrintIcon from "@mui/icons-material/Print";
import ConfirmDialog from "../../../../../shared/components/confirmDialogModal";
// import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SaveIcon from "@mui/icons-material/Save";
// import NotifyIcon from "@mui/icons-material/Notifications";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { yogamMembershipRequest } from "../../../../../shared/interfaces/yogam-membership-request";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const MembershipRequestList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [openNotifyModal, setOpenNotifyModal] = useState<boolean>(false);
  const [openPrintModal, setOpenPrintModal] = useState<boolean>(false);
  const [selectedMemberRequest, setSelectedMemberRequest] = useState<any>(null);
  const [selectedMemberPrintID, setSelectedMemberPrintID] = useState<any>(null);

  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [statusFilter, setStatusFilter] = useState<string | null>("Pending");

  const { modifyData } = useMutation(
    "/yogam-membership/yogam-membership-requests"
  );
  const { loading, data, fetchData, error } = useQuery(
    "/admin/yogam-membership-request",
    {
      params: {
        ...payload,
        branchStatus: statusFilter === "All" ? null : statusFilter,
      },
    }
  );

  const [formData, setFormData] = useState<any>({});

  const renderCell = (row: any, col: string) => {
    if (col === "status") {
      if (approvePermission) {
        if (row.branchStatus === "Rejected") {
          return row.branchStatus;
        }
        if (row.branchStatus === "Submitted") {
          return row.branchStatus;
        }

        return (
          <FormControl fullWidth>
            <Select
              value={formData[row.id]?.branchStatus || row.branchStatus}
              onChange={(e) => {
                const updatedStatus = e.target.value;

                if (
                  row.branchStatus === "Pending" &&
                  updatedStatus === "Submitted"
                ) {
                  alert("Status cannot be changed from Pending to Submitted.");
                  return;
                }

                if (
                  row.branchStatus === "Rejected" &&
                  updatedStatus === "Submitted"
                ) {
                  alert("Status cannot be changed from Rejected to Submitted.");
                  return;
                }

                if (
                  (row.branchStatus === "Pending" &&
                    (updatedStatus === "Approved" ||
                      updatedStatus === "Rejected")) ||
                  (row.branchStatus === "Approved" &&
                    updatedStatus === "Submitted")
                ) {
                  setSelectedIds((prevSelected) => {
                    if (!prevSelected.some((item) => item.id === row.id)) {
                      return [...prevSelected, row];
                    }
                    return prevSelected;
                  });
                }

                setFormData((prev: any) => ({
                  ...prev,
                  [row.id]: {
                    ...prev[row.id],
                    branchStatus: updatedStatus,
                  },
                }));
              }}
            >
              <MenuItem
                key={0}
                value="Pending"
                disabled={row.branchStatus === "Approved"}
              >
                Pending
              </MenuItem>
              <MenuItem key={1} value="Approved">
                Approved
              </MenuItem>
              <MenuItem
                key={2}
                value="Submitted"
                disabled={row.branchStatus !== "Approved"}
              >
                Submitted
              </MenuItem>
              <MenuItem
                key={3}
                value="Rejected"
                disabled={row.branchStatus === "Approved"}
              >
                Rejected
              </MenuItem>
            </Select>
          </FormControl>
        );
      } else {
        return row.branchStatus;
      }
    }

    if (col === "remarks" && formData[row.id]?.branchStatus === "Rejected") {
      if (row.branchStatus === "Rejected") {
        return row.remarks;
      }
      return (
        <TextField
          value={formData[row.id]?.remarks || ""}
          onChange={(e) => {
            const updatedRemarks = e.target.value;
            setFormData((prev: any) => ({
              ...prev,
              [row.id]: {
                ...prev[row.id],
                remarks: updatedRemarks,
              },
            }));
          }}
          fullWidth
        />
      );
    }

    if (col === "action") {
      return (
        <>
          {printPermission ? (
            <IconButton
              onClick={() => handlePrintModalOpen(row)}
              color="secondary"
              aria-label="print member details"
            >
              <PrintIcon />
            </IconButton>
          ) : null}
        </>
      );
    }

    return row[col];
  };

  interface UpdateData {
    id: string;
    branchStatus: string | undefined;
    remarks?: string | null;
  }
  const handleSubmit = async () => {
    try {
      // console.log("selectedIds  >>>>>>>>>  ", selectedIds);

      const updates: UpdateData[] = selectedIds
        .map((item) => {
          const branchStatus = formData[item.id]?.branchStatus;
          if (branchStatus) {
            const update: UpdateData = {
              id: item.id,
              branchStatus,
            };
            if (branchStatus === "Rejected") {
              update.remarks = formData[item.id]?.remarks || null;
            }
            return update;
          }
          return null;
        })
        .filter((item): item is UpdateData => item !== null);

      if (updates.length > 0) {
        await modifyData(
          "/admin/yogam-membership-request/update-branch-status",
          "put",
          updates
        );
      }
      setFormData({});
      fetchData();
    } catch (error) {
      console.error("Failed to update membership applications:", error);
    }
  };

  // const notifyPermission = (data as any)?.entityPermissions
  //   ? (data as any)?.entityPermissions?.includes(
  //       "Yogam Membership Request-Notify"
  //     )
  //   : true;

  const approvePermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes(
        "Yogam Membership Request-Approve"
      )
    : true;

  const printPermission = (data as any)?.entityPermissions
    ? (data as any)?.entityPermissions?.includes(
        "Yogam Membership Request-Print"
      )
    : true;

  const membershipRequestDisplayColumns = [
    "memberCode",
    "fullName",
    "houseNameEng",
    "familyHead",
    "status",
    "remarks",
    "action",
    // notifyPermission || printPermission ? "actions" : null,
  ].filter((item): item is string => item !== null);

  let membershipList: yogamMembershipRequest[] = [];
  if (data && (data as any)?.items) {
    membershipList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        memberID: item?.memberID,
        memberCode: item?.member?.code,
        fullName: item?.member?.fullNameEng,
        houseNameEng: item?.member?.addressDetails?.houseNameEng,
        familyHead: item?.member?.familyHeadDetails?.fullNameEng,
        branchStatus: item?.branchStatus,
        remarks: item?.remarks,
      };
    });
  }

  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    memberCode: `"member"."code"`,
    fullName: `"member"."fullNameEng"`,
    houseNameEng: `"member"."addressDetails"#>>'{houseNameEng}'`,
    familyHead: `"member"."familyHeadDetails"#>>'{fullNameEng}'`,
    status: "unionStatus",
    createdOn: "createdAt",
  };

  const handleNotifyModalOpen = (member: any) => {
    if (member) {
      if (typeof member === "object" && !Array.isArray(member)) {
        setSelectedMemberRequest([member.memberID]);
        setMessage(
          `Application of ${member?.fullName} is ready for signature. Send notifications for office visit. Please confirm to proceed.`
        );
      } else if (Array.isArray(selectedIds)) {
        const filteredMemberIDs = membershipList
          .filter(
            (member) =>
              selectedIds.includes(member.id) &&
              member.branchStatus === "Approved"
          ) // Filter by selected ids
          .map((member) => member.memberID);
        setSelectedMemberRequest(filteredMemberIDs);
        setMessage(
          `${selectedIds?.length} members applications are ready for signature. Send notifications for office visits. Please confirm to proceed.`
        );
      }
    } else {
      const filteredIds = membershipList.map((member) => member.id);

      const filteredMemberIDs = membershipList
        .filter(
          (member) =>
            filteredIds.includes(member.id) &&
            member.branchStatus === "Approved"
        ) // Filter by selected ids
        .map((member) => member.memberID);
      setSelectedMemberRequest(filteredMemberIDs);
      setMessage(
        `${filteredMemberIDs?.length} members applications are ready for signature. Send notifications for office visits. Please confirm to proceed.`
      );
    }

    setOpenNotifyModal(true);
  };

  const handlePrintModalOpen = (member: any) => {
    if (typeof member === "object" && !Array.isArray(member)) {
      setSelectedMemberRequest([member]);
      setSelectedMemberPrintID(member.id);
      setMessage(
        ` The processed application of ${member?.fullName} has been selected for printing.Please confirm to proceed.`
      );
    } else if (Array.isArray(member)) {
      setSelectedMemberRequest(member);
      setMessage(
        `${member?.length} members processed applications have been selected for printing.Please confirm to proceed.`
      );
    }

    setOpenPrintModal(true);
  };

  const handleClose = () => {
    setOpenNotifyModal(false);
  };

  const handlePrintClose = () => {
    setOpenPrintModal(false);
  };

  const handlePrintApplication = async () => {
    const response = await modifyData(
      `/admin/yogam-membership-request/generate/${selectedMemberPrintID}`,
      "put"
      // {
      // memberIds: selectedMemberRequest,
      // message: message,
      // }
    );
    if (response) {
      const byteArray = new Uint8Array(response?.data.data);
      const arrayBuffer = byteArray.buffer;
      const blob = new Blob([arrayBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Union Membership Request Form.pdf");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }

    handlePrintClose();
  };

  const handleNotificationSubmit = () => {
    modifyData(`/notification/send`, "post", {
      memberIds: selectedMemberRequest,
      message: message,
    });
    setOpenNotifyModal(false);
  };

  useEffect(() => {
    if (statusFilter) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  const titleBarNode = (
    <>
      <TitleBarSelectBox
        fieldProps={{
          id: "yogam-membership-request-filter",
          name: "yogam-membership-request-filter",
        }}
        value={statusFilter ?? "All"}
        onChange={(value: string) => setStatusFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: "Pending", label: "Pending" },
          { value: "Approved", label: "Approved" },
          { value: "Submitted", label: "Submitted" },
          { value: "Rejected", label: "Rejected" },
        ]}
      />
      <SearchBox />

      {approvePermission ? (
        <Tooltip arrow title="Save">
          <IconButton color="secondary" onClick={handleSubmit}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      {/* <Tooltip arrow title="Send Notification">
              <IconButton
                color="secondary"
                onClick={() => handleNotifyModalOpen(selectedMemberRequest)}
              >
                <NotifyIcon />
              </IconButton>
            </Tooltip> */}
      {/* {printPermission ? (
        <Tooltip arrow title="Print">
          <IconButton
            onClick={() => handlePrintModalOpen(selectedMemberRequest)}
            color="secondary"
            aria-label="print member details"
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      ) : null} */}
    </>
  );

  const titleBar = {
    title: "Yogam Membership Requests",
    headerTitle: "Yogam Membership Requests",
    titleBarNode: titleBarNode,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Family-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <>
          <DataTable
            entityName="Yogam Membership Requests"
            data={membershipList}
            displayColumns={membershipRequestDisplayColumns}
            linkColumn="fullNameEng"
            linkUrl=""
            sortableFields={sortFields}
            pagination={payload}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            // showCheckboxes={true}
            refetchFunction={fetchData}
            // onUpdateStatus={setSelectedIds}
            newAction={handleNotifyModalOpen}
            renderCell={renderCell}
          />
          <>
            <ConfirmDialog
              title={"Notify Member"}
              openConfirmDialog={openNotifyModal}
              setOpenConfirmDialog={handleClose}
              message={message}
              showCancelButton={false}
              handleSubmit={handleNotificationSubmit}
            />
          </>
          <>
            <ConfirmDialog
              title={"Print Application"}
              openConfirmDialog={openPrintModal}
              setOpenConfirmDialog={handlePrintClose}
              message={message}
              handleSubmit={() => handlePrintApplication()}
              showCancelButton={false}
              buttonText={"Print"}
            />
          </>
        </>
      )}
    </TableViewContainer>
  );
};

export default MembershipRequestList;
