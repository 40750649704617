import moment from "moment";

export const getWelfareRequestFields = (welfareRequestData: any) => {
  const financialFields = [
    {
      id: "allotedAmount",
      name: "allotedAmount",
      label: "Alloted amount",
      value: welfareRequestData?.welfare?.allotedAmount,
      type: "number",
      placeholder: "Enter Alloted amount",
      formField: false,
    },
    {
      id: "payoutType",
      name: "payoutType",
      label: "Payout Type",
      placeholder: "Select an option",
      required: true,
      value: welfareRequestData?.welfare?.payoutType,
      formField: false,
    },
  ];
  const nonFinancialFields = [
    {
      id: "nameOfMaterial",
      name: "nameOfMaterial",
      label: "Name of Material",
      value: welfareRequestData?.welfare?.nameOfMaterial,
      type: "text",
      placeholder: "Enter Name of Material",
      formField: false,
    },
    {
      id: "count",
      name: "count",
      label: "Count",
      value: welfareRequestData?.welfare?.count,
      type: "text",
      placeholder: "Enter Count",
      formField: false,
    },
  ];
  const commonFields: any = [
    {
      id: "fullNameEng",
      name: "fullNameEng",
      label: "Applicant Name:",
      value: welfareRequestData?.applicant?.fullNameEng,
      type: "text",
      placeholder: "Enter your fullname in English",
      formField: false,
    },
    {
      id: "houseNameEng",
      name: "houseNameEng",
      label: "House Name:",
      value: welfareRequestData?.applicant?.addressDetails?.houseNameEng,
      type: "text",
      placeholder: "Enter your house name in English",
      formField: false,
    },
    {
      id: "welfareCategory",
      name: "welfareCategory",
      label: "Category:",
      placeholder: "Select an option",
      required: true,
      value: welfareRequestData?.welfare?.welfareCategory,
      formField: false,
    },
    {
      id: "schemeName",
      name: "schemeName",
      label: "Scheme Name:",
      type: "text",
      value: welfareRequestData?.welfare?.schemeName,
      placeholder: "Enter Scheme Name",
      formField: false,
    },
    {
      id: "type",
      name: "type",
      label: "Welfare Type:",
      placeholder: "Select an option",
      required: true,
      value: welfareRequestData?.welfare?.type,
      formField: false,
    },
    {
      id: "description",
      name: "description",
      label: "Description:",
      type: "text",
      value: welfareRequestData?.welfare?.description,
      placeholder: "Enter description",
      formField: false,
    },
  ];
  if (welfareRequestData?.welfareStartDate) {
    commonFields.push({
      id: "startDate",
      label: "Start Date:",
      value: moment(welfareRequestData.welfareStartDate, "YYYY/MM/DD").format(
        "DD/MM/YYYY"
      ),
      formField: false,
    });
  }
  if (welfareRequestData?.welfareEndDate) {
    commonFields.push({
      id: "endDate",
      label: "End Date:",
      value: moment(welfareRequestData.welfareEndDate, "YYYY/MM/DD").format(
        "DD/MM/YYYY"
      ),
      formField: false,
    });
  }
  if (welfareRequestData?.type === "Financial") {
    return [...commonFields, ...financialFields];
  } else if (welfareRequestData?.type === "Nonfinancial") {
    return [...commonFields, ...nonFinancialFields];
  }

  return commonFields;
};
