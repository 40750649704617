import { FC } from "react";
import { InputLabel, TextField } from "@mui/material";

import { ErrorMessage } from "../errorMessage";
import { validateImageTypes } from "../../utils/image-helper";

type FieldProps = {
  id: string;
  name: string;
  label?: string;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPreviewUrl?: (url: string | null) => void;
  form: any;
};
const ImageInputField: FC<FieldProps> = ({
  id,
  name,
  label,
  required,
  onChange,
  setPreviewUrl,
  form,
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] ?? null;
    form.setFieldTouched(name, true);
    if (file && !validateImageTypes(file)) {
      setPreviewUrl?.(null);
      form.setFieldError(
        name,
        "Invalid file type. Please select a valid image."
      );
      form.setFieldValue(name, null);
      return;
    }
    const previewUrl = file ? URL.createObjectURL(file) : null;
    setPreviewUrl?.(previewUrl);
    form.setFieldValue(name, file);
    form.setFieldError(name, undefined); // Clear the error
    // Call the onChange handler if it exists
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="inputBoxContainer">
      <InputLabel>
        {label}
        {required && <span style={{ color: "red" }}> * </span>}
      </InputLabel>
      <TextField
        id={id}
        name={name}
        size="small"
        fullWidth
        type={"file"}
        sx={{
          marginBottom: ".4vh",
          "& .MuiInputBase-root": {
            height: "40px",
            fontSize: "1rem",
          },
        }}
        error={Boolean(form.errors[name] && form.touched[name])}
        helperText={
          form.touched[name] && form.errors[name]
            ? ""
            : "Accepted Image Formats: JPG, PNG, GIF. Max File Size: 2MB"
        }
        onChange={handleFileChange}
      />
      <ErrorMessage message={(form.touched[name] && form.errors[name]) || ""} />
    </div>
  );
};

export default ImageInputField;
