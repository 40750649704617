/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Box, Drawer, Hidden } from "@mui/material";

import { styled } from "@mui/material/styles";
import SidebarMenu from "./SidebarMenu";
import { SidebarContext } from "../../../contexts/SidebarContext";
import Logo from "../../../shared/components/logo";
import useLazyQuery from "../../../shared/components/hooks/useLazyQuery";
import useMutation from "../../../shared/components/hooks/useMutation";
import { clearLocalStorageData } from "../../../shared/utils/localStorage";
import SidebarFooter from "../../../shared/components/sidebarfooter";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        padding-bottom: 11%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
           
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 13%;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: solid 1px var(--zs-border) ;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { data: permissions, fetchData } = useLazyQuery(
    "admin/administrators/permissions"
  );
  const { modifyData } = useMutation();
  useEffect(() => {
    fetchData();
  }, []);

  const handleLogout = () => {
    modifyData(`/admin/logout`, "post");
    window.location.href = "/login";
    clearLocalStorageData();
  };

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <TopSection>
            <Logo />
          </TopSection>
          <Scrollbars autoHide>
            <SidebarMenu userData={(permissions as any)?.data} />
          </Scrollbars>
          <SidebarFooter onLogout={handleLogout} />
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>{/* <Logo /> */}</TopSection>
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
