export const getWelfareFields = (welfareData: any) => {
  // Define fields for financial welfare type
  const financialFields = [
    {
      id: "allotedAmount",
      name: "allotedAmount",
      label: "Alloted Amount:",
      value: welfareData?.allotedAmount,
    },
    {
      id: "payoutType",
      name: "payoutType",
      label: "Payout Type:",
      value: welfareData?.payoutType,
      formField: false,
    },
    {
      label: "Description:",
      value: welfareData?.description ?? "Not Available",
      formField: false,
    },
  ];

  // Define fields for non-financial welfare type
  const nonFinancialFields = [
    {
      id: "nameOfMaterial",
      name: "nameOfMaterial",
      label: "Name of Material:",
      value: welfareData?.nameOfMaterial,
      type: "text",
    },
    {
      id: "count",
      name: "count",
      label: "Count:",
      value: welfareData?.count,
      type: "text",
    },
    {
      label: "Description:",
      value: welfareData?.description ?? "Not Available",
      formField: false,
    },
  ];

  const commonFields = [
    {
      id: "welfareCategory",
      name: "welfareCategory",
      label: "Category:",
      placeholder: "Select an option",
      required: true,
      value: welfareData?.welfareCategory,
      formField: false,
    },
    {
      id: "schemeName",
      name: "schemeName",
      label: "Scheme Name:",
      type: "text",
      value: welfareData?.schemeName,
      placeholder: "Enter Scheme Name",
      required: true,
    },
    {
      id: "type",
      name: "type",
      label: "Welfare Type:",
      placeholder: "Select an option",
      required: true,
      value: welfareData?.type,
      formField: false,
    },
    {
      id: "status",
      name: "status",
      label: "Status:",
      placeholder: "Select an option",
      required: true,
      value: welfareData?.status,
      formField: false,
    },
  ];

  if (welfareData?.type === "Financial") {
    return [...commonFields, ...financialFields];
  } else if (welfareData?.type === "Nonfinancial") {
    return [...commonFields, ...nonFinancialFields];
  }

  return commonFields;
};
