import { FC, useContext, useState, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import moment from "moment";
import { welfareRequest } from "../../../../../shared/interfaces/welfare-requests";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const WelfareRequestList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [requestStatus, setStatusFilter] = useState("All");
  const { loading, data, fetchData, error } = useQuery(
    "/admin/welfare-request",
    {
      params: {
        ...payload,
        requestStatus: requestStatus,
      },
    }
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus]);

  const displayColumns = [
    "category",
    "schemeName",
    "fullNameEng",
    "houseNameEng",
    "createdOn",
    "status",
  ];
  const sortableColumns = ["fullNameEng"];
  let welfareRequestList: welfareRequest[] = [];
  if (data && (data as any)?.items) {
    welfareRequestList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        category: item?.welfare?.welfareCategory,
        schemeName: item?.welfare?.schemeName,
        fullNameEng: item?.applicant?.fullNameEng,
        houseNameEng: item?.applicant?.addressDetails?.houseNameEng,
        createdOn: moment(item?.createdAt).format("DD/MM/YYYY"),
        status: item?.requestStatus,
      };
    });
  }

  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    category: `"welfare"."welfareCategory"`,
    schemeName: `"welfare"."schemeName"`,
    fullNameEng: `"applicant"."fullNameEng"`,
    status: "requestStatus",
    createdOn: "createdAt",
  };

  return (
    <TableViewContainer>
      <TitleBar
        title="Welfare Requests"
        headerTitle="Welfare Requests"
        children={
          <>
            <TitleBarSelectBox
              fieldProps={{
                id: "status-log-filter",
                name: "status-log-filter",
              }}
              value={requestStatus ?? "All"}
              onChange={(value: string) => setStatusFilter(value)}
              options={[
                { value: "All", label: "All" },
                { value: "Pending", label: "Pending" },
                { value: "Approved", label: "Approved" },
                { value: "Rejected", label: "Rejected" },
              ]}
            />
            <SearchBox />
          </>
        }
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes(
                "Welfare request-Create"
              )
            : false
        }
      ></TitleBar>
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Welfare Requests"
          data={welfareRequestList}
          displayColumns={displayColumns}
          sortableColumns={sortableColumns}
          sortableFields={sortFields}
          linkColumn="fullNameEng"
          linkUrl="/welfare-schemes/welfare-requests"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default WelfareRequestList;
