/* eslint-disable @typescript-eslint/no-unused-vars */
import "./styles.css";
import { Box, Container } from "@mui/material";
import { FC, useEffect } from "react";
import TitleBar from "../titleBar";
import Loader from "../loader/linearProgress";

export type FormContainerProps = {
  children: React.ReactNode;
  titleBar?: any;
  loading?: boolean;
  titleBarNode?: React.ReactNode;
  tabs?: React.ReactNode;
  direction?: "ltr" | "rtl";
};
export const FormContainer: FC<FormContainerProps> = ({
  children,
  titleBar,
  loading = false,
  titleBarNode,
  tabs,
  direction = "ltr",
}) => {
  const disabled = titleBar?.dirty
    ? titleBar?.disabled && titleBar?.dirty
    : !titleBar?.dirty;

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (titleBar?.dirty) {
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage; // For some older browsers
      } else {
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [titleBar?.dirty]);

  return (
    <Container className="formContainer">
      <>
        {titleBar && (
          <TitleBar
            title={titleBar?.title}
            buttonTitle={titleBar?.buttonTitle}
            onClick={titleBar?.onClick}
            disabled={disabled}
            headerTitle={titleBar?.headerTitle}
            buttonLoading={titleBar?.buttonLoading}
          >
            {titleBarNode}
          </TitleBar>
        )}
        {tabs && <Box mb={2}>{tabs}</Box>}
        {loading ? (
          <Loader />
        ) : (
          <div className="formScrollbar">
            <form
              autoComplete="off"
              className={`formInnerContainer ${
                direction === "rtl" ? "rtl" : ""
              }`}
            >
              {children}
            </form>
          </div>
        )}
      </>
    </Container>
  );
};
