import { useFormik } from "formik";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FC } from "react";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { ZSSelectBox } from "../../../../../shared/components/selectBox";
import { getJobFields } from "./createFields";
import { EmploymentType } from "../../../../../shared/enum";

import internalSchema from "./internalSchema";
import externalSchema from "./externalSchema";
import { CustomDatePicker } from "../../../../../shared/components/datePicker";
import moment from "moment";

const CreateJob: FC = () => {
  const { loading: apiLoading, modifyData } = useMutation(
    "/job-management/jobs"
  );
  const jobFields = getJobFields();
  const handleapplicationDateChange = (newValue: any) => {
    jobForm.setFieldTouched("applicationEndDate", true);
    jobForm.setFieldValue(
      "applicationEndDate",
      moment(newValue).format("yyyy-MM-DD")
    );
  };
  const today = moment().startOf("day");
  const jobForm = useFormik({
    initialValues: {
      jobTitle: "",
      location: "",
      jobType: "",
      name: "",
      email: null,
      phone: null,
      url: null,
      employmentType: null,
      salaryRange: "",
      noOfOpenings: null,
      description: "",
      applicationEndDate: null,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: () => {
      return jobForm.values.jobType === "Internal"
        ? internalSchema
        : externalSchema;
    },
    validate: (values) => {
      const errors: any = {};
      if (
        values.applicationEndDate &&
        moment(values.applicationEndDate).isBefore(today)
      ) {
        errors.applicationEndDate =
          "Application end date cannot be in the past";
      }

      return errors;
    },
    onSubmit: async (formData: any) => {
      modifyData(
        `admin/job`,
        "post",
        {
          ...formData,
        },
        jobForm
      );
    },
  });

  const titleBar = {
    headerTitle: "Job - Create",
    title: "Create Job",
    buttonTitle: "Save",
    onClick: () => jobForm.handleSubmit(),
    dirty: jobForm.dirty,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar}>
      <Box className="border-custom">
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="job"
                name="jobType"
                value={jobForm.values.jobType}
                onChange={(event) => {
                  jobForm.setFieldValue("jobType", event.target.value);
                }}
                onBlur={jobForm.handleBlur}
                row
              >
                <FormControlLabel
                  value="Internal"
                  control={<Radio />}
                  label="Internal"
                />
                <FormControlLabel
                  value="External"
                  control={<Radio />}
                  label="External"
                />
              </RadioGroup>
              <FormHelperText>
                {" "}
                {((jobForm.touched as any)?.jobType &&
                  (jobForm.errors as any)?.jobType) ??
                  null}{" "}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {jobForm.values.jobType ? (
        <Box className="border-custom">
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={1}>
            {jobFields.map((field, index: number) => (
              <Grid item md={6} sm={6} xs={12} key={field.id}>
                {(["jobTitle", "location", "salaryRange"]?.includes(
                  field.name
                ) &&
                  jobForm.values.jobType === "External") ||
                ([
                  "jobTitle",
                  "location",
                  "salaryRange",
                  "name",
                  "phone",
                  "noOfOpenings",
                ]?.includes(field.name) &&
                  jobForm.values.jobType === "Internal") ? (
                  <InputTextfield
                    form={jobForm}
                    fieldProps={{
                      ...field,
                      required: true,
                    }}
                    key={index}
                  />
                ) : (
                  <InputTextfield
                    form={jobForm}
                    fieldProps={{
                      ...field,
                      required: false,
                    }}
                    key={index}
                  />
                )}
              </Grid>
            ))}
            <Grid item md={6} sm={6} xs={12}>
              <ZSSelectBox
                fieldProps={{
                  label: "Employment Type",
                  id: "employmentType",
                  name: "employmentType",
                  placeholder: "Select Option",
                  required: true,
                }}
                options={[
                  {
                    label: EmploymentType.FullTime,
                    value: EmploymentType.FullTime,
                  },
                  {
                    label: EmploymentType.PartTime,
                    value: EmploymentType.PartTime,
                  },
                  {
                    label: EmploymentType.Contract,
                    value: EmploymentType.Contract,
                  },
                ]}
                form={jobForm}
                value={jobForm.values?.employmentType}
                errorMessage={
                  ((jobForm.touched as any)?.employmentType &&
                    (jobForm.errors as any)?.employmentType) ??
                  null
                }
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <CustomDatePicker
                label="Application End Date"
                required
                onChange={(date: any) => handleapplicationDateChange(date)}
                placeholder="Application End Date"
                value={
                  jobForm.values.applicationEndDate
                    ? new Date(jobForm.values.applicationEndDate)
                    : null
                }
                errorMessage={
                  ((jobForm?.touched as any)?.applicationEndDate &&
                    (jobForm?.errors as any)?.applicationEndDate) ??
                  ""
                }
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} key="job-description">
              <InputTextfield
                form={jobForm}
                multiline
                fieldProps={{
                  id: "description",
                  name: "description",
                  label: "Job Description",
                  maxLength: 255,
                  type: "text",
                  placeholder: "Job Description",
                  required: true,
                  capitalizedFirst: true,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </FormContainer>
  );
};
export default CreateJob;
