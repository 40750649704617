import { FC, useContext, useEffect, useState } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import moment from "moment";
import { Events } from "../../../../../shared/interfaces/event";
import { EventState } from "../../../../../shared/enum";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const EventList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);
  const [stateFilter, setStateFilter] = useState<string | null>("All");
  const { loading, data, fetchData, error } = useQuery("/admin/event", {
    params: {
      ...payload,
      eventState: stateFilter === "All" ? null : stateFilter,
    },
  });

  useEffect(() => {
    if (!loading) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilter]);

  const eventData = data as any;
  const eventList = eventData?.items.map((item: Events) => {
    return {
      id: item?.id,
      name: item?.name,
      status: item?.eventState,
      startDate: moment(item?.startDate).format("DD-MM-YYYY"),
      endDate: moment(item?.endDate).format("DD-MM-YYYY"),
      deleteButtonStatus: item?.eventState === "Published" ? true : false,
    };
  });
  const eventDisplayColumns = ["name", "startDate", "endDate", "status"];

  const sortFields = {
    name: `name`,
    startDate: `startDate`,
    endDate: `endDate`,
  };

  const titleBarNode = (
    <>
      <TitleBarSelectBox
        fieldProps={{
          id: "state-filter",
          name: "state-filter",
        }}
        value={stateFilter ?? "All"}
        onChange={(value: string) => setStateFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: EventState.Draft, label: "Draft" },
          { value: EventState.Published, label: "Published" },
          { value: EventState.Cancelled, label: "Cancelled" },
        ]}
      />
      <SearchBox />
    </>
  );

  const titleBar = {
    title: "Events",
    headerTitle: "Events",
    buttonTitle: "Create",
    onClick: () => navigate("/event-management/events/create"),
    titleBarNode: titleBarNode,
    disabled: (data as any)?.entityPermissions
      ? !(data as any)?.entityPermissions?.includes("Event-Create")
      : false,
  };

  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Event"
          data={eventList}
          sortableFields={sortFields}
          displayColumns={eventDisplayColumns}
          linkColumn="name"
          linkUrl="/event-management/events"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          disabled={
            (data as any)?.entityPermissions
              ? !(data as any)?.entityPermissions?.includes("Event-Delete")
              : false
          }
          deleteApiUrl="/admin/event"
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default EventList;
