import { FC, useContext, useState, useEffect } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import SearchBox from "../../../../../layouts/SidebarLayout/Header/SearchBox";
import { JobRequest } from "../../../../../shared/interfaces/job-requests";
import { TableViewContainer } from "../../../../../shared/components/tableViewContainer";
import { JobApplicationStatus, JobType } from "../../../../../shared/enum";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { TitleBarSelectBox } from "../../../../../shared/components/selectBoxTtileBar";

const JobRequestList: FC = () => {
  const { payload, setPayload } = useContext(SidebarContext);
  const [applicationStatus, setStatusFilter] = useState("All");
  const [jobType, setTypeFilter] = useState("All");
  const { loading, data, fetchData, error } = useQuery("/admin/job-requests", {
    params: {
      ...payload,
      applicationStatus: applicationStatus,
      jobType: jobType,
    },
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationStatus, jobType]);

  const displayColumns = [
    "jobTitle",
    "jobType",
    "applicantName",
    "houseNameEng",
    "status",
  ];
  const sortableColumns = ["applicantName"];
  let jobRequestList: JobRequest[] = [];
  if (data && (data as any)?.items) {
    jobRequestList = (data as any)?.items.map((item: any) => {
      return {
        id: item.id,
        jobTitle: item?.job?.jobTitle,
        jobType: item?.job?.jobType,
        applicantName: item?.member?.fullNameEng,
        houseNameEng: item?.member?.addressDetails?.houseNameEng,
        status: item?.applicationStatus,
        deleteButtonStatus:
          item?.applicationStatus === "Shortlisted" ||
          item?.applicationStatus === "Pending"
            ? false
            : true,
      };
    });
  }

  const sortFields = {
    // applicantName: `"member"."fullNameEng"`,
    jobTitle: '"job"."jobTitle"',
    jobType: `"job"."jobType"`,
    applicantName: '"member"."fullNameEng"',

    status: "applicationStatus",
    createdOn: "createdAt",
  };

  const titleBarNode = (
    <>
      <TitleBarSelectBox
        fieldProps={{
          id: "status-log-filter",
          name: "status-log-filter",
        }}
        value={applicationStatus ?? "All"}
        onChange={(value: string) => setStatusFilter(value)}
        options={[
          { value: "All", label: "All" },
          {
            value: JobApplicationStatus.Pending,
            label: JobApplicationStatus.Pending,
          },
          {
            value: JobApplicationStatus.Shortlisted,
            label: JobApplicationStatus.Shortlisted,
          },
          {
            value: JobApplicationStatus.Rejected,
            label: JobApplicationStatus.Rejected,
          },
        ]}
      />
      <TitleBarSelectBox
        fieldProps={{
          id: "status-filter",
          name: "status-filter",
        }}
        value={jobType ?? "All"}
        onChange={(value: string) => setTypeFilter(value)}
        options={[
          { value: "All", label: "All" },
          { value: JobType.Internal, label: JobType.Internal },
          { value: JobType.External, label: JobType.External },
        ]}
      />
      <SearchBox />
    </>
  );
  const titleBar = {
    title: "Job Requests",
    headerTitle: "Job Requests",
    titleBarNode: titleBarNode,
  };
  return (
    <TableViewContainer titleBar={titleBar}>
      {loading && <Loader />}{" "}
      {(error as any)?.statusCode === 403 && <NoPermissionContent />}
      {!loading && !error && (
        <DataTable
          entityName="Job Requests"
          data={jobRequestList}
          sortableFields={sortFields}
          displayColumns={displayColumns}
          sortableColumns={sortableColumns}
          linkColumn="applicantName"
          linkUrl="/job-management/job-requests"
          pagination={payload}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          refetchFunction={fetchData}
        />
      )}
    </TableViewContainer>
  );
};

export default JobRequestList;
