/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import moment from "moment";
import { FC, Fragment } from "react";

interface FamilyDetails {
  addressId: string | undefined;
  addressHistory: any[];
}
const AddressHistoryList: FC<FamilyDetails> = ({
  addressHistory,
  addressId,
}) => {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  const formatDate = (date: any) => {
    return isoRegex.test(date);
  };
  return (
    <Container className="tableListContainer">
      <TableContainer component={Paper}>
        <Table size="small" sx={{ "& td": { border: 0 } }}>
          <TableHead>
            <TableRow>
              <TableCell>House No</TableCell>
              <TableCell>House Name</TableCell>
              <TableCell>Street Name</TableCell>
              <TableCell>Date Of Residence</TableCell>
              <TableCell>Ward No</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addressHistory?.map((address: any, index: any) => (
              <Fragment key={index}>
                {address.id !== addressId ? (
                  <TableRow key={index}>
                    <TableCell> {address.houseNo} </TableCell>
                    <TableCell> {address.houseNameEng} </TableCell>
                    <TableCell> {address?.streetNameEng} </TableCell>
                    <TableCell>
                      {formatDate(address?.dateOfResidence)
                        ? moment(address?.dateOfResidence).format("DD/MM/YYYY")
                        : moment(address?.dateOfResidence, "DD/MM/YYYY").format(
                            "DD/MM/YYYY"
                          )}
                    </TableCell>
                    <TableCell>
                      {`${address?.lsgds?.districtEng}, ${address?.lsgds?.talukEng}, ${address?.lsgds?.panchayathEng}, Ward No - ${address?.lsgds?.wardNo}`}
                    </TableCell>
                  </TableRow>
                ) : null}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AddressHistoryList;
