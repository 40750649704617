import "./styles.css";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useEffect, useState } from "react";
import InputTextfield from "../textField";
import "./styles.css";

interface ConfirmDialogProps {
  openConfirmDialog: boolean;
  setOpenConfirmDialog: Function;
  handleSubmit: Function;
  title?: string;
  message?: any;
  loading?: any;
  setTextContent?: Function;
  textContent?: any;
  label?: string;
  required?: boolean;
  fileTypes?: string[];
  buttonText?: string;
  showCancelButton?: boolean;
}
const ConfirmDialog: FC<ConfirmDialogProps> = ({
  openConfirmDialog,
  setOpenConfirmDialog,
  handleSubmit,
  message,
  title,
  setTextContent,
  textContent,
  label,
  fileTypes,
  buttonText,
  required,
  showCancelButton = true,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!openConfirmDialog) {
      setLoading(false); // Reset loading when dialog closes
    }
  }, [openConfirmDialog]);
  return (
    <div>
      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography color="black" fontWeight="bold">
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenConfirmDialog(false);
            }}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
            {setTextContent ? (
              <div className="dialogContentInput">
                {textContent.type === "file" ? (
                  <>
                    <InputLabel>
                      {label}
                      {required ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : null}
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      inputProps={{
                        accept: fileTypes,
                      }}
                      type={textContent.type}
                      className="fileInput"
                      onChange={(event: any) => {
                        setTextContent((prev: any) => ({
                          ...prev,
                          value: event.target.files[0],
                        }));
                      }}
                    />
                  </>
                ) : (
                  <InputTextfield
                    error={false}
                    multiline
                    fieldProps={{
                      id: textContent.type,
                      label: label,
                      name: label ?? "",
                      type: textContent.type,
                      required: required,
                      placeholder: `Enter ${label}`,
                      maxLength: 255,
                    }}
                    onChange={(newValue: any) => {
                      setTextContent((prev: any) => ({
                        ...prev,
                        value: newValue,
                      }));
                    }}
                  />
                )}
              </div>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            id="confirmation"
            variant="contained"
            disabled={
              ((textContent?.type === "text" &&
                textContent?.value?.trim()?.length === 0) ||
                textContent?.value === undefined ||
                textContent?.value === "") &&
              setTextContent
                ? true
                : false
            }
            onClick={() => {
              handleSubmit(true);
              setLoading(true);
            }}
            loading={loading}
            autoFocus
            className="DialogBarButton"
          >
            {buttonText ? buttonText : "OK"}
          </LoadingButton>
          {showCancelButton ? (
            <LoadingButton
              variant="outlined"
              onClick={() => handleSubmit(false)}
            >
              Cancel
            </LoadingButton>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmDialog;
